import { _min, _numberEnumKeys, _objectEntries, localDate } from '@naturalcycles/js-lib'
import {
  BirthComplication,
  BirthControlMethodBreakpointOption,
  BirthDeliveryMethod,
  EARLY_PREG_END_KEYS,
  FeedingMethod,
  FULL_TERM_PREG_END_KEYS,
  Goal,
  MAX_PREGNANCY_DAYS,
  PREGNANCY_STATE_MIN_LENGTH,
  PregnancyEndCareType,
  PregnancyEndReason,
  PregnancyEndSymptom,
  PregnancyState,
  ReceivedMedicalCare,
} from '@naturalcycles/shared'
import { MIXPANEL_CHAR_LIMIT } from '@src/app/analytics/analytics.cnst'
import { RadioGroupValue } from '@src/app/cmp/radio-group/radio-group.component'
import { ICON_BY_BC } from '@src/app/cnst/icons.cnst'
import { TRACKERS_BY_GOAL } from '@src/app/srv/appSettings.cnst'
import { CalendarTemplate } from '../_templates/calendar/flow-calendar.page'
import { CheckboxGroupTemplate } from '../_templates/checkbox-group/flow-checkbox-group.page'
import { InfoTemplate } from '../_templates/info/flow-info.page'
import { NotesTemplate } from '../_templates/notes/flow-notes.page'
import { NotificationsTemplate } from '../_templates/notifications/flow-notifications.page'
import { PickerTemplate } from '../_templates/picker/flow-picker.page'
import { RadioGroupTemplate } from '../_templates/radio-group/flow-radio-group.page'
import { RemindersTemplate } from '../_templates/reminders/flow-reminders.page'
import { SingleSelectTemplate } from '../_templates/single-select/flow-single-select.page'
import { FlowId, FlowInputData, FlowTemplate } from '../flow.cnst'
import { PregnancyEndConfirmation, PregnancyEndTrigger } from './pregnancy-end.cnst'
import { PregnancyEndFlowDataInput } from './pregnancy-end.service'

type InputType = FlowInputData<PregnancyEndFlowDataInput>

type GetFlowConfigFn<T> = (input: InputType) => T
type GetInfoScreenFn = (input: InputType) => InfoTemplate | undefined

const confirmationItem: (
  value: PregnancyEndConfirmation,
) => RadioGroupValue<PregnancyEndConfirmation> = value => {
  return {
    title: 'txt-pregnancy-end-confirmation--' + PregnancyEndConfirmation[value],
    value,
    uid: 'pregnancy__end__confirmation--' + PregnancyEndConfirmation[value],
  }
}

const confirmationTitleByTrigger: Partial<Record<PregnancyEndTrigger, string>> = {
  [PregnancyEndTrigger.NEGATIVE_TEST]: 'txt-pregnancy-end-negative-test',
  [PregnancyEndTrigger.PREVENT_SITUATION_CHANGE]: 'txt-pregnancy-change-title',
}
const confirmationBodyByTrigger: Partial<Record<PregnancyEndTrigger, string>> = {
  [PregnancyEndTrigger.PREVENT_SITUATION_CHANGE]: 'txt-pregnancy-change-body',
}

const confirmation: GetFlowConfigFn<RadioGroupTemplate<PregnancyEndConfirmation>> = input => {
  const { trigger, state } = input

  const items: RadioGroupValue<PregnancyEndConfirmation>[] = []

  if (trigger === PregnancyEndTrigger.PREVENT_SITUATION_CHANGE) {
    items.push(
      confirmationItem(PregnancyEndConfirmation.PREVENT_NO_LONGER_PREGNANT),
      confirmationItem(PregnancyEndConfirmation.CHANGE_TO_FOLLOW),
    )
  } else {
    items.push(
      confirmationItem(PregnancyEndConfirmation.LOST_PREGNANCY),
      confirmationItem(PregnancyEndConfirmation.STILL_PREGNANT),
      confirmationItem(PregnancyEndConfirmation.WAS_NOT_PREGNANT),
    )
  }

  if (state === PregnancyState.FULL_TERM) {
    items.unshift(confirmationItem(PregnancyEndConfirmation.GAVE_BIRTH))
  }

  return {
    analyticsPageName: 'PregnancyEndPage',
    template: FlowTemplate.RADIO,
    title: confirmationTitleByTrigger[trigger!] || 'txt-pregnancy-end-no-longer-pregnant',
    body: confirmationBodyByTrigger[trigger!] || 'txt-pregnancy-end-details-request-confirm',
    saveButton: {
      label: 'txt-next',
      uid: 'pregnancy__end__confirmation__nextBtn',
    },
    radioGroups: [
      {
        key: 'confirmation',
        items,
      },
    ],
    sideEffects: (input: InputType) => {
      const { confirmation, pregnantOnPrevent, state } = input

      if (pregnantOnPrevent && confirmation !== PregnancyEndConfirmation.GAVE_BIRTH) {
        return Object.assign(
          { reason: null },
          ...earlyPregEndKeys.map(k => ({ [k]: null })),
          ...fullTermPregEndKeys.map(k => ({ [k]: null })),
        )
      }

      const sideEffects = { reason: null }
      if (confirmation === PregnancyEndConfirmation.GAVE_BIRTH) {
        Object.assign(sideEffects, { reason: PregnancyEndReason.BIRTH })
      } else if (state === PregnancyState.EARLY) {
        Object.assign(sideEffects, { reason: undefined })
      }

      return Object.assign(
        sideEffects,
        ...earlyPregEndKeys.map(k => ({
          [k]: confirmation === undefined || state === PregnancyState.EARLY ? undefined : null,
        })),
        ...fullTermPregEndKeys.map(k => ({
          [k]:
            confirmation === undefined || confirmation === PregnancyEndConfirmation.GAVE_BIRTH
              ? undefined
              : null,
        })),
      )
    },
  }
}

const reason: GetFlowConfigFn<RadioGroupTemplate<PregnancyEndReason | null>> = _input => ({
  analyticsPageName: 'PregnancyEndReasonPage',
  template: FlowTemplate.RADIO,
  title: 'txt-pregnancy-end-reason-title',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__reason__nextBtn',
  },
  radioGroups: [
    {
      key: 'reason',
      items: [
        ..._numberEnumKeys(PregnancyEndReason)
          .filter(k => k !== 'BIRTH')
          .map(key => {
            return {
              title: 'txt-pregnancy-end-reason--' + key,
              value: PregnancyEndReason[key] as number,
              uid: 'pregnancy__end__reason--' + key,
            }
          }),
        {
          title: 'txt-dont-know',
          value: null,
          uid: 'pregnancy__end__reason--SKIP',
        },
      ],
    },
  ],
})

const pregnancyLength: GetFlowConfigFn<PickerTemplate> = _input => {
  return {
    template: FlowTemplate.PICKER,
    analyticsPageName: 'PregnancyEndWeekCalendarPage',
    title: 'txt-pregnancy-end-pregnancy-week-title',
    body: 'txt-pregnancy-end-pregnancy-week-body',
    valuePickerUid: 'pregnancyEnd__endWeek',
    valuePickerLabel: 'txt-pregnancy-week',
    minValue: 196,
    maxValue: 300,
    scrollToValue: 280,
    saveButton: {
      uid: 'pregnancy__end__endWeek__okBtn',
      label: 'txt-next',
    },
    sideEffects: (input: InputType) => {
      const { pregnancyEndDate, pregnancyLength } = input

      if (pregnancyLength === undefined) {
        return {
          pregnancyStartDate: undefined,
        }
      }

      if (pregnancyLength && pregnancyEndDate) {
        return {
          pregnancyStartDate: localDate(pregnancyEndDate)
            .minus(pregnancyLength - 14, 'day')
            .toISODate(),
        }
      }
    },
  }
}

const pregnancyEndDate: GetFlowConfigFn<CalendarTemplate> = input => {
  const { minPossiblePregnancyEndedDate, conceptionDate, reason, trigger } = input
  const now = localDate.todayString()

  const maxDate = _min([
    now,
    localDate.orUndefined(conceptionDate)?.plus(MAX_PREGNANCY_DAYS, 'day').toISODate(),
  ])
  let minDate = minPossiblePregnancyEndedDate || now

  if (conceptionDate && reason === PregnancyEndReason.BIRTH) {
    minDate = localDate(conceptionDate)
      .plus(PREGNANCY_STATE_MIN_LENGTH[PregnancyState.FULL_TERM], 'day')
      .toISODate()
  }

  if (trigger === PregnancyEndTrigger.ONBOARDING) {
    minDate = localDate.today().minus(1, 'year').toISODate()
  }

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'PregnancyEndDateCalendarPage',
    title:
      reason === PregnancyEndReason.BIRTH
        ? 'txt-pregnancy-end-birthdate-title'
        : 'txt-pregnancy-end-select-date-title',
    minDate,
    maxDate,
    skipButton:
      conceptionDate && reason !== PregnancyEndReason.BIRTH
        ? {
            uid: 'pregnancy__end__endDate__skipBtn',
            label: 'txt-dont-know',
          }
        : undefined,
    sideEffects: (input: InputType) => {
      const { conceptionDate, pregnancyEndDate, reason, pregnantOnPrevent } = input

      if (!conceptionDate || pregnancyEndDate === undefined) return

      const endDate = localDate.orToday(pregnancyEndDate)

      const pregLength = endDate.diff(conceptionDate, 'day')

      const state = Number(
        _objectEntries(PREGNANCY_STATE_MIN_LENGTH)
          .reverse()
          .find(([k, v]) => {
            return pregLength >= v ? k : undefined
          })?.[0],
      )

      if (pregnantOnPrevent) {
        return {
          state,
          moreQuestions: state === PregnancyState.EARLY ? undefined : null,
        }
      }

      const sideEffects = Object.assign(
        {
          state,
          reason: state === PregnancyState.EARLY ? undefined : reason || null,
        },
        ...earlyPregEndKeys.map(k => ({ [k]: state === PregnancyState.EARLY ? undefined : null })),
        ...fullTermPregEndKeys.map(k => ({
          [k]: reason === PregnancyEndReason.BIRTH ? undefined : null,
        })),
      )

      return sideEffects
    },
  }
}

const symptoms: GetFlowConfigFn<CheckboxGroupTemplate> = _input => ({
  template: FlowTemplate.CHECKBOX,
  analyticsPageName: 'PregnancyEndSymptomsPage',
  title: 'txt-pregnancy-end-symptoms-title',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__symptoms__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__symptoms__skipBtn',
  },
  checkboxes: _numberEnumKeys(PregnancyEndSymptom).map(key => {
    return {
      title:
        PregnancyEndSymptom[key] === PregnancyEndSymptom.OTHER
          ? 'txt-other'
          : 'txt-pregnancy-end-symptom--' + key,
      name: key,
      uid: 'pregnancy__end__symptom--' + key,
      control: PregnancyEndSymptom[key] === PregnancyEndSymptom.NO_SYMPTOMS,
    }
  }),
})

const receivedMedicalCare: GetFlowConfigFn<RadioGroupTemplate<ReceivedMedicalCare>> = _input => ({
  analyticsPageName: 'PregnancyEndTreatmentPage',
  template: FlowTemplate.RADIO,
  title: 'txt-pregnancy-end-medical-treatment-title',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__treatments__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__treatments__skipBtn',
  },
  radioGroups: [
    {
      key: 'receivedMedicalCare',
      items: [
        {
          title: 'txt-yes-i-did',
          value: ReceivedMedicalCare.YES,
          uid: 'pregnancy__end__treatment--' + ReceivedMedicalCare[ReceivedMedicalCare.YES],
        },
        {
          title: 'txt-no-i-did-not',
          value: ReceivedMedicalCare.NO,
          uid: 'pregnancy__end__treatment--' + ReceivedMedicalCare[ReceivedMedicalCare.NO],
        },
      ],
    },
  ],
  sideEffects: (input: InputType) => {
    const { receivedMedicalCare } = input

    switch (receivedMedicalCare) {
      case undefined:
      case ReceivedMedicalCare.YES:
        return {
          receivedCareType: undefined,
          medicationReceivedDate: undefined,
          medicalDeliveryDate: undefined,
          surgeryDate: undefined,
        }

      case null:
      case ReceivedMedicalCare.NO:
        return {
          receivedCareType: null,
          medicationReceivedDate: null,
          medicalDeliveryDate: null,
          surgeryDate: null,
        }
    }
  },
})

const receivedCareType: GetFlowConfigFn<CheckboxGroupTemplate> = _input => ({
  analyticsPageName: 'PregnancyEndCarePage',
  template: FlowTemplate.CHECKBOX,
  title: 'txt-pregnancy-end-care-type-title',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__care__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__care__skipBtn',
  },
  checkboxes: _numberEnumKeys(PregnancyEndCareType).map(key => {
    return {
      title:
        PregnancyEndCareType[key] === PregnancyEndCareType.OTHER
          ? 'txt-other'
          : 'txt-pregnancy-end-care-type--' + key,
      name: key,
      uid: 'pregnancy__end__care--' + key,
    }
  }),
  sideEffects: (input: InputType) => {
    const { receivedCareType } = input

    if (!receivedCareType?.length) {
      return {
        medicationReceivedDate: null,
        medicalDeliveryDate: null,
        surgeryDate: null,
      }
    }

    return {
      medicationReceivedDate: receivedCareType.includes(PregnancyEndCareType.MEDICATION)
        ? undefined
        : null,
      medicalDeliveryDate: receivedCareType.includes(PregnancyEndCareType.MEDICAL_DELIVERY)
        ? undefined
        : null,
      surgeryDate: receivedCareType.includes(PregnancyEndCareType.SURGERY) ? undefined : null,
    }
  },
})

const goal: GetFlowConfigFn<SingleSelectTemplate<Goal>> = input => {
  const { confirmation, state } = input

  const analyticsPageName =
    confirmation === PregnancyEndConfirmation.WAS_NOT_PREGNANT ? 'WrongModePage' : 'SelectModePage'
  const body =
    confirmation === PregnancyEndConfirmation.WAS_NOT_PREGNANT
      ? 'txt-pregnancy-end-wrong-mode'
      : undefined

  const buttons = [
    {
      uid: 'goalPrevent__button',
      label: `btn-goal--${Goal[Goal.PREVENT]}`,
      value: Goal.PREVENT,
    },
    {
      uid: 'goalPlan__button',
      label: `btn-goal--${Goal[Goal.PLAN]}`,
      value: Goal.PLAN,
    },
  ]

  const commonGoalsPage: SingleSelectTemplate<Goal> = {
    analyticsPageName,
    template: FlowTemplate.SINGLE_SELECT,
    title: 'txt-pregnancy-end-goal-title',
    body,
    buttons,
  }

  if (confirmation === PregnancyEndConfirmation.LOST_PREGNANCY && state === PregnancyState.EARLY) {
    return {
      ...commonGoalsPage,
      body: 'txt-pregnancy-end-goal-body-recovery',
      buttons: [
        {
          uid: 'goalRecovery__button',
          label: `btn-goal--${Goal[Goal.RECOVERY]}`,
          value: Goal.RECOVERY,
        },
      ],
      moreButtons: buttons,
      moreButtonsTitle: 'txt-choose-another-mode',
    }
  }
  if (confirmation === PregnancyEndConfirmation.GAVE_BIRTH) {
    return {
      ...commonGoalsPage,
      title: 'txt-pregnancy-end-goal-title-postpartum',
      body: 'txt-pregnancy-end-goal-body-postpartum',
      buttons: [
        {
          uid: 'goalPostpartum__button',
          label: `btn-goal--${Goal[Goal.POSTPARTUM]}`,
          value: Goal.POSTPARTUM,
        },
      ],
      moreButtons: buttons,
      moreButtonsTitle: 'txt-choose-another-mode',
    }
  }

  return commonGoalsPage
}

const notes: GetFlowConfigFn<NotesTemplate> = _input => ({
  analyticsPageName: 'PregnancyEndNotesPage',
  template: FlowTemplate.NOTES,
  title: 'txt-is-there-anything-to-share',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__notes__submitBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__notes__skipBtn',
  },
  placeholder: 'txt-enter-text',
  maxLength: 4000,
})

const induction: GetFlowConfigFn<SingleSelectTemplate<boolean>> = _input => ({
  analyticsPageName: 'PregnancyEndInductionPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-pregnancy-end-induction-title',
  body: 'txt-pregnancy-end-induction-body',
  buttons: [
    {
      uid: 'pregnancy__end__induction__yesBtn',
      label: 'txt-yes',
      value: true,
    },
    {
      uid: 'pregnancy__end__induction__noBtn',
      label: 'txt-no',
      value: false,
    },
  ],
})

const numberOfBabies: GetFlowConfigFn<SingleSelectTemplate<number>> = _input => ({
  analyticsPageName: 'PregnancyEndNumberOfBabiesPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-pregnancy-end-number-babies-title',
  body: 'txt-pregnancy-end-number-babies-body',
  buttons: [
    {
      uid: 'pregnancy__end__babies__oneBtn',
      label: 'txt-pregnancy-end-number-babies-one',
      value: 1,
    },
    {
      uid: 'pregnancy__end__babies__twoBtn',
      label: 'txt-pregnancy-end-number-babies-two',
      value: 2,
    },
    {
      uid: 'pregnancy__end__babies__threeBtn',
      label: 'txt-pregnancy-end-number-babies-three',
      value: 3,
    },
  ],
})

const deliveryMethod: GetFlowConfigFn<
  SingleSelectTemplate<BirthDeliveryMethod | null>
> = _input => ({
  analyticsPageName: 'PregnancyEndDeliveryMethodPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-pregnancy-end-delivery-method-title',
  body: 'txt-pregnancy-end-delivery-method-body',
  buttons: [
    ...[
      BirthDeliveryMethod.VAGINAL,
      BirthDeliveryMethod.PLANNED_C_SECTION,
      BirthDeliveryMethod.EMERGENCY_C_SECTION,
      BirthDeliveryMethod.FORCEPS_ASSISTED,
      BirthDeliveryMethod.VACUUM_ASSISTED,
      BirthDeliveryMethod.VBAC,
      BirthDeliveryMethod.OTHER,
    ].map(value => {
      return {
        uid: 'pregnancy__end__deliveryMethod--' + BirthDeliveryMethod[value],
        label: 'txt-pregnancy-end-delivery-method--' + BirthDeliveryMethod[value],
        value,
      }
    }),
    {
      uid: 'pregnancy__end__deliveryMethod--SKIP',
      label: 'txt-skip',
      value: null,
      outline: true,
    },
  ],
  sideEffects: (input: InputType) => {
    const { deliveryMethod } = input

    switch (deliveryMethod) {
      case undefined:
      case BirthDeliveryMethod.OTHER:
        return {
          deliveryMethodOther: undefined,
        }

      default:
        return {
          deliveryMethodOther: null,
        }
    }
  },
})

const deliveryMethodOther: GetFlowConfigFn<NotesTemplate> = _input => ({
  analyticsPageName: 'PregnancyEndDeliveryMethodOtherPage',
  template: FlowTemplate.NOTES,
  title: 'txt-pregnancy-end-delivery-method-other-title',
  body: 'txt-pregnancy-end-delivery-method-other-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__deliveryMethodOther__submitBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__deliveryMethodOther__skipBtn',
  },
  placeholder: 'txt-enter-text',
  maxLength: MIXPANEL_CHAR_LIMIT,
})

const birthComplications: GetFlowConfigFn<CheckboxGroupTemplate> = _input => ({
  analyticsPageName: 'PregnancyEndComplicationsPage',
  template: FlowTemplate.CHECKBOX,
  title: 'txt-pregnancy-end-complications-title',
  body: 'txt-pregnancy-end-complications-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__complications__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__complications__skipBtn',
  },
  checkboxes: [BirthComplication.YES_ME, BirthComplication.YES_BABY, BirthComplication.NONE].map(
    i => {
      const key = BirthComplication[i]
      return {
        title: 'txt-pregnancy-end-complication--' + key,
        name: key,
        uid: 'pregnancy__end__complication--' + key,
        control: i === BirthComplication.NONE,
      }
    },
  ),
})

const physicalScore: GetFlowConfigFn<RadioGroupTemplate<number>> = _input => ({
  analyticsPageName: 'PregnancyEndPhysicalScorePage',
  template: FlowTemplate.RADIO,
  title: 'txt-pregnancy-end-physicalScore-title',
  body: 'txt-pregnancy-end-physicalScore-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__physicalScore__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__physicalScore__skipBtn',
  },
  radioGroups: [
    {
      key: 'physicalScore',
      items: [1, 2, 3, 4, 5].map(i => {
        return {
          title: 'txt-pregnancy-end-physicalScore--' + i,
          value: i,
          uid: 'pregnancy__end__physicalScore--' + i,
        }
      }),
    },
  ],
})

const mentalScore: GetFlowConfigFn<RadioGroupTemplate<number>> = _input => ({
  analyticsPageName: 'PregnancyEndMentalScorePage',
  template: FlowTemplate.RADIO,
  title: 'txt-pregnancy-end-mentalScore-title',
  body: 'txt-pregnancy-end-mentalScore-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__mentalScore__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__mentalScore__skipBtn',
  },
  radioGroups: [
    {
      key: 'mentalScore',
      items: [1, 2, 3, 4, 5].map(i => {
        return {
          title: 'txt-pregnancy-end-mentalScore--' + i,
          value: i,
          uid: 'pregnancy__end__mentalScore--' + i,
        }
      }),
    },
  ],
})

const feedingMethods: GetFlowConfigFn<CheckboxGroupTemplate> = _input => ({
  analyticsPageName: 'PregnancyEndFeedingMethodsPage',
  template: FlowTemplate.CHECKBOX,
  title: 'txt-pregnancy-end-feeding-methods-title',
  body: 'txt-pregnancy-end-feeding-methods-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__feedingMethods__nextBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__feedingMethods__skipBtn',
  },
  checkboxes: [
    FeedingMethod.BREASTFEEDING_ON_DEMAND,
    FeedingMethod.BREASTFEEDING_ON_SCHEDULE,
    FeedingMethod.PUMPING,
    FeedingMethod.FORMULA,
    FeedingMethod.SOLIDS,
  ].map(i => {
    const key = FeedingMethod[i]

    return {
      title: 'txt-pregnancy-end-feeding-method--' + key,
      name: key,
      uid: 'pregnancy__end__feedingMethod--' + key,
    }
  }),
})

const bcAfterBirth: GetFlowConfigFn<
  SingleSelectTemplate<BirthControlMethodBreakpointOption>
> = _input => ({
  analyticsPageName: 'PregnancyEndBCAfterBirthPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-pregnancy-end-bc-after-birth-title',
  body: 'txt-pregnancy-end-bc-after-birth-body',
  style: 'images',
  buttons: [
    BirthControlMethodBreakpointOption.pill,
    BirthControlMethodBreakpointOption.hormonalIUD,
    BirthControlMethodBreakpointOption.shot,
    BirthControlMethodBreakpointOption.nuvaRing,
    BirthControlMethodBreakpointOption.implant,
    BirthControlMethodBreakpointOption.patch,
    BirthControlMethodBreakpointOption.other,
    BirthControlMethodBreakpointOption.none,
  ].map(value => {
    return {
      uid: 'pregnancy__end__bcAfterBirth--' + BirthControlMethodBreakpointOption[value],
      label:
        'txt-quiz-BirthControlMethodBreakpointOption--' + BirthControlMethodBreakpointOption[value],
      value,
      icon: ICON_BY_BC[value],
    }
  }),
  sideEffects: (input: InputType) => {
    const { bcAfterBirth } = input

    switch (bcAfterBirth) {
      case undefined:
      case BirthControlMethodBreakpointOption.other:
        return {
          bcAfterBirthOther: undefined,
        }

      default:
        return {
          bcAfterBirthOther: null,
        }
    }
  },
})

const bcAfterBirthOther: GetFlowConfigFn<NotesTemplate> = _input => ({
  analyticsPageName: 'PregnancyEndBCAfterBirthOtherPage',
  template: FlowTemplate.NOTES,
  title: 'txt-pregnancy-end-bc-after-birth-other-title',
  body: 'txt-pregnancy-end-bc-after-birth-other-body',
  saveButton: {
    label: 'txt-next',
    uid: 'pregnancy__end__bcAfterBirthOther__submitBtn',
  },
  skipButton: {
    label: 'txt-skip',
    uid: 'pregnancy__end__bcAfterBirthOther__skipBtn',
  },
  placeholder: 'txt-enter-text',
  maxLength: MIXPANEL_CHAR_LIMIT,
})

const revisitSettings: GetFlowConfigFn<SingleSelectTemplate<boolean>> = _input => ({
  analyticsPageName: 'RevisitSettingsPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-pregnancy-end-update-settings-title',
  body: 'txt-pregnancy-end-update-settings-body',
  buttons: [
    {
      uid: 'pregnancy__end__settings__nextBtn',
      label: 'txt-next',
      value: true,
    },
    {
      uid: 'pregnancy__end__settings__skipBtn',
      label: 'txt-skip',
      value: false,
      outline: true,
    },
  ],
})

const trackers: GetFlowConfigFn<CheckboxGroupTemplate> = input => {
  const goal = input.goal || Goal.PREVENT
  const body =
    input.reason === PregnancyEndReason.BIRTH
      ? 'txt-pregnancy-end-trackers-body-birth'
      : 'onboarding-trackers-body'

  return {
    analyticsPageName: 'RevisitTrackersPage',
    template: FlowTemplate.CHECKBOX,
    title: 'onboarding-trackers-title',
    body,
    saveButton: {
      uid: 'onboarding__trackers__buttonSave',
      label: 'txt-next',
    },
    checkboxes: TRACKERS_BY_GOAL[goal],
  }
}

const measuringReminders: GetFlowConfigFn<RemindersTemplate> = input => ({
  analyticsPageName: 'RevisitMeasuringRemindersPage',
  template: FlowTemplate.REMINDERS,
  title: 'settings-title-notifications',
  body:
    input.reason === PregnancyEndReason.BIRTH
      ? 'txt-pregnancy-end-birth-measuringReminders-body'
      : undefined,
})

const notifications: GetFlowConfigFn<NotificationsTemplate> = input => {
  return {
    analyticsPageName: 'RevisitCycleRemindersPage',
    template: FlowTemplate.NOTIFICATIONS,
    title: 'txt-pregnancy-end-revisit-cycle-reminders-title',
    body:
      input.reason === PregnancyEndReason.BIRTH
        ? undefined
        : 'txt-pregnancy-end-revisit-cycle-reminders-body',
    goal: input.goal || Goal.PREVENT,
  }
}

const moreQuestions: GetFlowConfigFn<SingleSelectTemplate<boolean>> = _input => ({
  analyticsPageName: 'PregnancyEndMoreQuestionsPage',
  template: FlowTemplate.SINGLE_SELECT,
  title: 'txt-pregnancy-end-more-questions-title',
  body: 'txt-pregnancy-end-more-questions-body',
  buttons: [
    {
      uid: 'pregnancy__end__more__questions__nextBtn',
      label: 'btn-yes-continue',
      value: true,
    },
    {
      uid: 'pregnancy__end__more__questions__rejectBtn',
      label: 'btn-no-thank-you',
      value: false,
      outline: true,
    },
  ],
  sideEffects: (input: InputType) => {
    if (input.moreQuestions === true) {
      return Object.assign(
        {},
        ...earlyPregEndKeys.map(k => ({ [k]: undefined })),
        ...fullTermPregEndKeys.map(k => ({ [k]: null })),
        { symptoms: null },
      )
    }
  },
})

const medicationReceivedDate: GetFlowConfigFn<CalendarTemplate> = input => {
  const { minPossiblePregnancyEndedDate } = input

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'PregnancyEndCareCalendarPage',
    title:
      'txt-pregnancy-end-care-date-title--' + PregnancyEndCareType[PregnancyEndCareType.MEDICATION],
    minDate: minPossiblePregnancyEndedDate || localDate.todayString(),
    maxDate: localDate.todayString(),
    skipButton: {
      uid: 'pregnancy__end__medicationDate__skipBtn',
      label: 'txt-dont-know',
    },
  }
}

const medicalDeliveryDate: GetFlowConfigFn<CalendarTemplate> = input => {
  const { minPossiblePregnancyEndedDate } = input

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'PregnancyEndCareCalendarPage',
    title:
      'txt-pregnancy-end-care-date-title--' +
      PregnancyEndCareType[PregnancyEndCareType.MEDICAL_DELIVERY],
    minDate: minPossiblePregnancyEndedDate || localDate.todayString(),
    maxDate: localDate.todayString(),
    skipButton: {
      uid: 'pregnancy__end__medicalDeliveryDate__skipBtn',
      label: 'txt-dont-know',
    },
  }
}

const surgeryDate: GetFlowConfigFn<CalendarTemplate> = input => {
  const { minPossiblePregnancyEndedDate } = input

  return {
    template: FlowTemplate.CALENDAR,
    analyticsPageName: 'PregnancyEndCareCalendarPage',
    title:
      'txt-pregnancy-end-care-date-title--' + PregnancyEndCareType[PregnancyEndCareType.SURGERY],
    minDate: minPossiblePregnancyEndedDate || localDate.todayString(),
    maxDate: localDate.todayString(),
    skipButton: {
      uid: 'pregnancy__end__surgeryDate__skipBtn',
      label: 'txt-dont-know',
    },
  }
}

const endDateInfo: GetInfoScreenFn = input => {
  const { confirmation, trigger } = input

  if (
    confirmation === PregnancyEndConfirmation.GAVE_BIRTH ||
    confirmation === PregnancyEndConfirmation.WAS_NOT_PREGNANT ||
    trigger === PregnancyEndTrigger.NEGATIVE_TEST
  ) {
    return
  }

  return {
    analyticsPageName: 'PregnancyEndDatePage',
    template: FlowTemplate.INFO,
    title: 'txt-pregnancy-end-provide-date-title',
    body: 'txt-pregnancy-end-provide-date-body',
    buttons: [
      {
        uid: 'pregnancy__end__endDate__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

const recoveryInfo: GetInfoScreenFn = input => {
  const { goal } = input

  if (goal !== Goal.RECOVERY) return

  return {
    analyticsPageName: 'EnteringRecoveryPage',
    template: FlowTemplate.INFO,
    title: 'txt-recovery-mode-entering-title',
    body: 'txt-recovery-mode-entering-body',
    tutorialBoxes: [
      {
        title: 'txt-recovery-mode-entering-fertility-status-title',
        body: 'txt-recovery-mode-entering-fertility-status-body',
      },
      {
        title: 'txt-recovery-mode-entering-measuring-title',
        body: 'txt-recovery-mode-entering-measuring-body',
      },
      {
        title: 'txt-recovery-mode-entering-exit-title',
        body: 'txt-recovery-mode-entering-exit-body',
      },
    ],
    buttons: [
      {
        uid: 'pregnancy__end__recoveryInfo__nextBtn',
        label: 'txt-understand',
      },
      {
        uid: 'pregnancy__end__recoveryInfo__changeGoalBtn',
        label: 'txt-choose-another-mode',
        goBack: true,
        outline: true,
      },
    ],
  }
}

const inductionInfo: GetInfoScreenFn = input => {
  if (!input.induction) return

  return {
    analyticsPageName: 'PregnancyEndInductionInfoPage',
    template: FlowTemplate.INFO,
    background: true,
    title: 'txt-pregnancy-end-induction-info-title',
    body: 'txt-pregnancy-end-induction-info-body',
    buttons: [
      {
        uid: 'pregnancy__end__inductionInfo__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

const deliveryMethodInfo: GetInfoScreenFn = input => {
  const { deliveryMethod } = input
  if (!deliveryMethod || deliveryMethod === BirthDeliveryMethod.OTHER) return

  return {
    analyticsPageName: 'PregnancyEndDeliveryMethodInfoPage',
    template: FlowTemplate.INFO,
    background: true,
    title: 'txt-pregnancy-end-delivery-method-info-title--' + BirthDeliveryMethod[deliveryMethod],
    body: 'txt-pregnancy-end-delivery-method-info-body--' + BirthDeliveryMethod[deliveryMethod],
    buttons: [
      {
        uid: 'pregnancy__end__deliveryMethodInfo__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

const birthComplicationsInfo: GetInfoScreenFn = input => {
  const { birthComplications } = input

  if (!birthComplications?.length || birthComplications.includes(BirthComplication.NONE)) return

  return {
    analyticsPageName: 'PregnancyEndComplicationsInfoPage',
    template: FlowTemplate.INFO,
    background: true,
    title: 'txt-pregnancy-end-complications-info-title',
    body: 'txt-pregnancy-end-complications-info-body',
    buttons: [
      {
        uid: 'pregnancy__end__complicationsInfo__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

const healthScoreInfo: GetInfoScreenFn = input => {
  const { mentalScore, physicalScore } = input

  if (!mentalScore && !physicalScore) return

  const highMentalScore = mentalScore && mentalScore >= 4

  const title = highMentalScore
    ? 'txt-pregnancy-end-healthscore-info-title-2'
    : 'txt-pregnancy-end-healthscore-info-title'
  const body = highMentalScore
    ? 'txt-pregnancy-end-healthscore-info-body-2'
    : 'txt-pregnancy-end-healthscore-info-body'

  return {
    analyticsPageName: 'PregnancyEndHealthScoreInfoPage',
    template: FlowTemplate.INFO,
    background: true,
    title,
    body,
    buttons: [
      {
        uid: 'pregnancy__end__healthscoreInfo__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

const bcAfterBirthInfo: GetInfoScreenFn = input => {
  const { bcAfterBirth, bcAfterBirthOther } = input

  if (
    bcAfterBirth === BirthControlMethodBreakpointOption.other &&
    bcAfterBirthOther === undefined
  ) {
    return
  }

  return {
    analyticsPageName: 'PregnancyEndBirthControlInfoPage',
    template: FlowTemplate.INFO,
    background: true,
    title: 'txt-pregnancy-end-bc-info-title',
    body: 'txt-pregnancy-end-bc-info-body',
    buttons: [
      {
        uid: 'pregnancy__end__birthControlInfo__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

const feedingMethodsInfo: GetInfoScreenFn = input => {
  const { feedingMethods } = input

  if (!feedingMethods?.length) return

  return {
    analyticsPageName: 'PregnancyEndFeedingMethodsInfoPage',
    template: FlowTemplate.INFO,
    background: true,
    title: 'txt-pregnancy-end-feeding-methods-info-title',
    body: 'txt-pregnancy-end-feeding-methods-info-body',
    buttons: [
      {
        uid: 'pregnancy__end__feedingMethodsInfo__nextBtn',
        label: 'txt-next',
      },
    ],
  }
}

/**
 * Keys that are used only for early pregnancy ends
 */
const earlyPregEndKeys: (keyof PregnancyEndFlowDataInput)[] = [...EARLY_PREG_END_KEYS]

/**
 * Keys that are used only for full term pregnancy ends
 */
const fullTermPregEndKeys: (keyof PregnancyEndFlowDataInput)[] = [...FULL_TERM_PREG_END_KEYS]

// Order of this map is important, it decides which page comes next
export const PREGNANCY_END_CONFIG = new Map<keyof PregnancyEndFlowDataInput, GetFlowConfigFn<any>>([
  ['confirmation', confirmation],
  ['pregnancyEndDate', pregnancyEndDate],
  ['pregnancyLength', pregnancyLength],
  ['reason', reason],
  ['moreQuestions', moreQuestions],
  ['symptoms', symptoms],

  ['receivedMedicalCare', receivedMedicalCare],
  ['receivedCareType', receivedCareType],
  ['medicationReceivedDate', medicationReceivedDate],
  ['medicalDeliveryDate', medicalDeliveryDate],
  ['surgeryDate', surgeryDate],

  ['notes', notes],

  ['induction', induction],
  ['numberOfBabies', numberOfBabies],
  ['deliveryMethod', deliveryMethod],
  ['deliveryMethodOther', deliveryMethodOther],
  ['birthComplications', birthComplications],
  ['physicalScore', physicalScore],
  ['mentalScore', mentalScore],
  ['feedingMethods', feedingMethods],
  ['bcAfterBirth', bcAfterBirth],
  ['bcAfterBirthOther', bcAfterBirthOther],

  ['goal', goal],
  ['revisitSettings', revisitSettings],
  ['trackers', trackers],
  ['notifications', notifications],
  ['measureReminders', measuringReminders],
])

/**
 * Map of functions that can return an info screen based on the previous page and data.
 */
export const PREGNANCY_END_INFO_SCREEN = new Map<keyof PregnancyEndFlowDataInput, GetInfoScreenFn>([
  ['confirmation', endDateInfo],
  ['goal', recoveryInfo],
  ['induction', inductionInfo],
  ['deliveryMethod', deliveryMethodInfo],
  ['birthComplications', birthComplicationsInfo],
  ['mentalScore', healthScoreInfo],
  ['bcAfterBirth', bcAfterBirthInfo],
  ['bcAfterBirthOther', bcAfterBirthInfo],
  ['feedingMethods', feedingMethodsInfo],
])

export const PREGNANCY_END_PAGES_BY_ROUTE = [...PREGNANCY_END_CONFIG.entries()].map(
  ([key, value]) => {
    const config = value({}) || {}
    return {
      key: `/flow/${FlowId.PREGNANCY_END}/${config?.template}/${key}`,
      value: config.analyticsPageName,
    }
  },
)
