import {
  BirthControlMethodBreakpointOption,
  ConceiveTimingBreakpointOption,
  CycleChangesBreakpointOption,
  EligibleAppleWatchBreakpointOption,
  Experiment,
  GoalBreakpointOption,
  HormonalBirthControlHowLongBreakpointOption,
  HormonalBirthControlUsageBreakpointOption,
  MedicalConditionsBreakpointOption,
  QuizDataInput,
  QuizEngine,
  QuizEngineConfig,
  QuizFlow,
  SideEffectsBreakpointOption,
  SleepWithTrackerOption,
  WhichWearableBreakpointOption,
} from '@naturalcycles/shared'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { QuizBreakpoint, QuizSections } from './quiz.enum'

export class AppQuizEngine extends QuizEngine<
  QuizBreakpoint,
  BreakpointOption,
  QuizPage,
  QuizDataInput
> {}

export type BreakpointOption =
  | GoalBreakpointOption
  | BirthControlMethodBreakpointOption
  | SideEffectsBreakpointOption
  | ConceiveTimingBreakpointOption
  | HormonalBirthControlUsageBreakpointOption
  | HormonalBirthControlHowLongBreakpointOption
  | MedicalConditionsBreakpointOption
  | CycleChangesBreakpointOption
  | WhichWearableBreakpointOption
  | EligibleAppleWatchBreakpointOption
  | SleepWithTrackerOption

/**
 * Quiz pages and flow
 */

export type AppQuizFlow = QuizFlow<QuizPage>

/**
 * Quiz UI interfaces and constants
 */
export interface QuizSelectOption {
  key: number
  textKey: string
  img?: string
  imgPosition?: 'top' | 'right'
}
export interface InfoPageContent {
  imageUrl?: string
  titleKey?: string
  subtitleKey?: string
  buttonKey?: string
}

export interface TranslatedInfoPageContent {
  title?: string
  subtitle?: string
  markdown?: string
}

export interface QuizState {
  flow: AppQuizFlow
  data: QuizDataInput | null
  sections: QuizSections | null
  /**
   * The quiz signature is an object that identifies an instance of a quiz engine.
   * It contains all variables (and values) that are taken into consideration when initializing the quiz engine.
   */
  signature: QuizSignatureObject | null
  /**
   * We redirect to entry when user clicks back from the first page of the quiz.
   */
  entry: string | null
  /**
   * We redirect to exit when user navigates forward from the last page of the quiz.
   */
  exit: string | null
}

export interface AppQuizEngineConfig
  extends QuizEngineConfig<QuizBreakpoint, BreakpointOption, QuizPage, QuizDataInput> {}

export interface QuizColumnContent {
  img: string
  title: string
  comparisonPoints: string[]
}

export interface CompareBirthControlContent {
  columnNC: QuizColumnContent
  columnAlt: QuizColumnContent
  bottomText: string
}

/**
 * GUIDE ON HOW TO ADD A NEW QUIZ FLOW:
 * (Works wether it's a new experiment or new variant clearblue-like flow)
 *
 * 1) Add new experiment/variant to the QuizSignatureObject
 *
 * After that, typescript will help you to find all the places where you need to update the code.
 *
 * 2) Add a new QuizModifier
 *
 * Each one of the QuizModifier methods should never replace entirely the input object, but rather mutate it.
 * This concept is key in order to keep flow/experiments non-mutually exclusive.
 *
 * 3) add new QuizModifier to the modifiers array in quiz service
 */
export interface QuizSignatureObject {
  experiment: {
    [Experiment.LEAD_CAPTURE_APP]: string | undefined
  }
}
