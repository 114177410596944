import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { convertBlobToBase64 } from '@app/util/convertBlobToBase64'
import { Directory, Encoding, Filesystem, ReadFileResult } from '@capacitor/filesystem'
import { objectToFormData } from '@naturalcycles/js-lib'
import { LHTestImage, LHTestImageInput } from '@naturalcycles/shared'

const CACHE_FOLDER = 'lh_cache'

@Injectable({ providedIn: 'root' })
export class LhService {
  public async createFolders(): Promise<void> {
    await Filesystem.mkdir({
      directory: Directory.Cache,
      path: CACHE_FOLDER,
    }).catch(() => {}) // if folder already created

    await Filesystem.mkdir({
      directory: Directory.Data,
      path: CACHE_FOLDER,
    }).catch(() => {}) // if folder already created
  }

  public async uploadImage(
    base64StringOriginal: string,
    base64StringUser: string,
    input: LHTestImageInput,
  ): Promise<string> {
    const { mlOutput, testResult } = input

    const originalResponse = await fetch(`data:image/jpeg;base64,${base64StringOriginal}`)
    const userResponse = await fetch(`data:image/jpeg;base64,${base64StringUser}`)

    const original = await originalResponse.blob()
    const user = await userResponse.blob()

    const { id } = await api.put<{ id: string }>('lh/image', {
      body: objectToFormData({
        original,
        user,
        mlOutput: mlOutput.toFixed(10).toString(),
        testResult: testResult.toString(),
      }),
    })

    return id
  }

  public async reportImage(base64StringOriginal: string, input: LHTestImageInput): Promise<void> {
    const { mlOutput, testResult } = input

    const originalResponse = await fetch(`data:image/jpeg;base64,${base64StringOriginal}`)

    const original = await originalResponse.blob()

    await api.put('lh/image/report', {
      body: objectToFormData({
        original,
        mlOutput: mlOutput.toFixed(10).toString(),
        testResult: testResult.toString(),
      }),
    })
  }

  public async getImageSrc(id: string): Promise<string> {
    const file = await Filesystem.readFile({
      directory: Directory.Cache,
      path: `${CACHE_FOLDER}/${id}`,
    }).catch(async () => await this.fetchImageAndSaveToCache(id))

    return (file.data as string).startsWith('data:image')
      ? (file.data as string)
      : `data:image/jpg;base64,${file.data as string}`
  }

  public async getImageData(id: string): Promise<LHTestImage> {
    const file = await Filesystem.readFile({
      directory: Directory.Data,
      path: `${CACHE_FOLDER}/${id}_data`,
      encoding: Encoding.UTF8,
    }).catch(() => this.fetchImageDataAndSaveToCache(id))

    return JSON.parse(file.data as string) as LHTestImage
  }

  private async fetchImageAndSaveToCache(id: string): Promise<ReadFileResult> {
    const blob = await api.get<Blob>(`lh/image/${id}`, {
      responseType: 'blob',
    })

    const base64Data = await convertBlobToBase64(blob)

    await this.createFolders()

    await Filesystem.writeFile({
      path: `${CACHE_FOLDER}/${id}`,
      data: base64Data,
      directory: Directory.Cache,
    })

    return { data: base64Data }
  }

  private async fetchImageDataAndSaveToCache(id: string): Promise<ReadFileResult> {
    const data = await api.get<LHTestImage>(`lh/image/${id}/data`)

    const stringData = JSON.stringify(data)

    await this.createFolders()

    await Filesystem.writeFile({
      path: `${CACHE_FOLDER}/${id}_data`,
      data: stringData,
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    })

    return { data: stringData }
  }
}
