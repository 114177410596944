import {
  BreakpointAction,
  ChoiceFunction,
  QuizDataInput,
  WhichWearableBreakpointOption,
} from '@naturalcycles/shared'
import {
  addPageToFlowAfterCurrent,
  removePageFromFlow,
  removePagesFromFlow,
} from '@src/app/util/quiz.util'
import { DefaultQuizSection } from '@src/typings/quiz.enum'
import { QuizPage } from '../quiz-pages.cnst'

const commonTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  const updatedFlow = removePagesFromFlow(flow, DefaultQuizSection.routine, [
    QuizPage.eligibleAppleWatch,
    QuizPage.purchaseNewWatch,
  ])
  return addPageToFlowAfterCurrent(
    updatedFlow,
    DefaultQuizSection.routine,
    QuizPage.whichWearable,
    QuizPage.consideringPurchase,
  )
}

const appleWatchTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  const updatedFlow = removePageFromFlow(
    flow,
    DefaultQuizSection.routine,
    QuizPage.consideringPurchase,
  )
  return addPageToFlowAfterCurrent(
    updatedFlow,
    DefaultQuizSection.routine,
    QuizPage.whichWearable,
    QuizPage.eligibleAppleWatch,
  )
}

const ouraRingTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return removePagesFromFlow(flow, DefaultQuizSection.routine, [
    QuizPage.eligibleAppleWatch,
    QuizPage.purchaseNewWatch,
    QuizPage.consideringPurchase,
  ])
}

export const whichWearable: BreakpointAction<
  WhichWearableBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [WhichWearableBreakpointOption.appleWatch]: appleWatchTransformFunction,
  [WhichWearableBreakpointOption.ouraRing]: ouraRingTransformFunction,
  [WhichWearableBreakpointOption.skip]: commonTransformFunction,
}
