class URLUtil {
  getLocationQueryString(): { [k: string]: string } {
    const queryString = location.hash.split('?')[1] || location.search.slice(1)
    return this.getQueryString(queryString)
  }

  getQueryString(search: string): { [k: string]: string } {
    return (
      search
        .replace(/^\?/, '')
        .split('&')
        // eslint-disable-next-line unicorn/no-array-reduce
        .reduce((prev, cur) => {
          const p = cur.split('=')
          const k = decodeURIComponent(p[0]!)
          if (k) prev[k] = p[1] ? decodeURIComponent(p[1]) : ''
          return prev
        }, {} as any)
    )
  }

  /**
   * Returns 'a=1&b=2&c=' for {
   *  a: 1,
   *  b: 2,
   * }
   * undefined values are skipped
   */
  buildQueryString(o: any = {}, encodeUriComponent = false): string {
    return (
      Object.keys(o)
        // eslint-disable-next-line unicorn/no-array-reduce
        .reduce((r, k) => {
          if (o[k] !== undefined) {
            let value = o[k] || ''
            if (encodeUriComponent) value = encodeURIComponent(value)
            r.push(k + '=' + value)
          }
          return r
        }, [] as string[])
        .join('&')
    )
  }

  buildUrl(host: string, qs = {}, hash?: string | true): string {
    let url = host

    if (hash === true) {
      // using hash from current 'location', removing params that might be duplicated
      url += location.hash.split('?')[0]
    } else if (hash) {
      url += hash
    }

    const qsString = this.buildQueryString(qs)
    if (qsString) url += '?' + qsString

    return url
  }

  urlToString(url: URL): string {
    return [url.protocol, '//', url.host, url.pathname, url.search, url.hash].join('')
  }

  // shortcut
  parseLocationUrl(): URL {
    return this.parseUrl(location.href)
  }

  parseUrl(_url: string): URL {
    const a = document.createElement('a')
    a.href = _url
    return {
      protocol: a.protocol,
      hostname: a.hostname,
      host: a.host,
      pathname: a.pathname,
      search: a.search,
      hash: a.hash,
      href: a.href,
      port: a.port,
      searchParams: new URLSearchParams(a.search),
      // origin: undefined,
      // password: undefined,
      // username: undefined,
    } as URL
  }
}

export const urlUtil = new URLUtil()
