export type QuizSections = Record<DefaultQuizSection, number>

export enum DefaultQuizSection {
  intro = 'intro',
  goal = 'goal',
  cycle = 'cycle',
  cycleChanges = 'cycleChanges',
  routine = 'routine',
}

export enum QuizBreakpoint {
  goal = 'goal',
  birthControlMethod = 'birthControlMethod',
  sideEffects = 'sideEffects',
  conceiveTiming = 'conceiveTiming',
  hormonalBirthControlUsage = 'hormonalBirthControlUsage',
  hormonalBirthControlHowLong = 'hormonalBirthControlHowLong',
  medicalConditions = 'medicalConditions',
  cycleChanges = 'cycleChanges',
  whichWearable = 'whichWearable',
  sleepWearableAW = 'sleepWearableAW',
  sleepWearable = 'sleepWearable',
  eligibleAppleWatch = 'eligibleAppleWatch',
}
