import { QuizDataInput } from '@naturalcycles/shared'
import { AppQuizFlow, QuizState } from '@src/typings/quiz.types'
import { Action } from 'redux'

const initialState: QuizState = {
  flow: [],
  data: null,
  sections: null,
  signature: null,
  entry: null,
  exit: null,
}

const actions: any = {
  replaceQuizFlow(state: QuizState, action: { type: string; payload: AppQuizFlow }): QuizState {
    return {
      ...state,
      data: state.data,
      flow: [...action.payload],
    }
  },

  updateQuizData(state: QuizState, action: { type: string; payload: QuizDataInput }): QuizState {
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
      flow: state.flow,
    }
  },

  replaceQuizState(_state: QuizState, action: { type: string; payload: QuizState }): QuizState {
    return action.payload
  },

  clearQuizState(): QuizState {
    return initialState
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export function quizReducer(state = initialState, action: Action): QuizState {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
