import { inject, Injectable } from '@angular/core'
import { ErrorService } from '@app/srv/error.service'
import { select } from '@app/srv/store.service'
import {
  _findLast,
  _first,
  _groupBy,
  _mapValues,
  _Memo,
  _randomInt,
  _round,
  _sortDescBy,
  _stringMapEntries,
  _stringMapValues,
  _uniqBy,
  IsoDate,
  localDate,
  localTime,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  DailyEntryBM,
  DailyEntrySaveInput,
  dailyEntrySaveInputLimit,
  HardwareId,
  HKDevice,
  HKQuantitySample,
  IOS_WATCH_MODEL,
  WatchModel,
  WristTempMetadata,
} from '@naturalcycles/shared'
import { NCHealthKit } from '@src/typings/capacitor'
import {
  combineLatestWith,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
} from 'rxjs'
import { EVENT } from '../analytics/analytics.cnst'
import { AnalyticsService } from '../analytics/analytics.service'
import { RadioGroupValue } from '../cmp/radio-group/radio-group.component'
import {
  AWSetupState,
  AWWristTempsDevice,
  AWWristTempSupportState,
  MIN_OS_VER_FOR_AW,
  MultipleAWData,
} from '../cnst/appleWatch.cnst'
import { isAndroidApp, isIOSApp, isWebApp } from '../cnst/userDevice.cnst'
import { loader, LoaderType } from '../decorators/decorators'
import { MultipleWatchesDetectedModal } from '../modals/multiple-watches-detected/multiple-watches-detected.modal'
import {
  AW_TEMPS_AND_HR_IDENTIFIERS,
  HKDataIdentifier,
  HKIdentifierDataType,
  HKUnit,
} from '../model/healthKit.model'
import { prf } from '../util/perf.util'
import { DailyEntryService } from './dailyentry.service'
import { HealthKitService } from './healthkit/healthkit.service'
import { PopupController, Priority } from './popup.controller'
import { dispatch, getState } from './store.service'
import { UnitsService } from './units.service'

@Injectable({ providedIn: 'root' })
export class AppleWatchService {
  public mockWristTemps?: HKQuantitySample[]

  private healthKitService = inject(HealthKitService)
  private popupController = inject(PopupController)
  private unitsService = inject(UnitsService)
  private dailyEntryService = inject(DailyEntryService)
  private analyticsService = inject(AnalyticsService)
  private errorService = inject(ErrorService)

  @select(['account', 'hwId'])
  private hwId$!: Observable<HardwareId>

  @select(['userFertility', 'startDate'])
  private startDate$!: Observable<IsoDate>

  @select(['hwChanges'])
  private hwChanges$!: Observable<StringMap<HardwareId>>

  @select(['userDevice', 'version'])
  private osVersion$!: Observable<string | undefined>

  @select(['userSettings', 'hasCompatibleAppleWatch'])
  private hasCompatibleAppleWatch$!: Observable<boolean | undefined>

  @select(['userSettings', 'hasRevokedAppleWatchPermissions'])
  private hasRevokedAppleWatchPermissions$!: Observable<boolean | undefined>

  @select(['userSettings', 'lastAppleWatchSyncDate'])
  private lastAppleWatchSyncDate$!: Observable<IsoDate | undefined>

  private awStartDate$ = this.startDate$.pipe(
    combineLatestWith(this.hwChanges$),
    map(([ufStartDate, hwChanges]) => {
      return this._getStartDate(ufStartDate, hwChanges)
    }),
    tap(startDate => (this.awStartDate = startDate as IsoDate)),
  )

  private canRequestPermissions$: Observable<boolean> =
    this.healthKitService.permissionsRequested$.pipe(
      startWith(false),
      switchMap(
        async () =>
          await this.healthKitService.canRequestPermissionForIdentifier(
            AW_TEMPS_AND_HR_IDENTIFIERS,
            [],
          ),
      ),
    )

  public awSetupState$ = this.hwId$.pipe(
    combineLatestWith(
      this.hasCompatibleAppleWatch$,
      this.hasRevokedAppleWatchPermissions$,
      this.lastAppleWatchSyncDate$,
      this.awStartDate$,
      this.osVersion$,
      this.canRequestPermissions$,
    ),
    distinctUntilChanged(),
    switchMap(
      async ([
        hwId,
        hasCompatibleAppleWatch,
        hasRevokedAppleWatchPermissions,
        lastAppleWatchSyncDate,
        awStartDate,
        osVersion,
        canRequestPermissions,
      ]) => {
        if (hwId !== HardwareId.APPLE_WATCH) return AWSetupState.INCORRECT_HW_ID

        if (isWebApp) return AWSetupState.SETUP_COMPLETE
        if (isAndroidApp) return AWSetupState.ANDROID

        if (isIOSApp && !!osVersion && osVersion < MIN_OS_VER_FOR_AW) {
          return AWSetupState.TOO_LOW_IOS
        }
        if (isIOSApp && hasCompatibleAppleWatch === false) return AWSetupState.INCOMPATIBLE_WATCH

        if (canRequestPermissions) return AWSetupState.SETUP_INCOMPLETE

        if (hasRevokedAppleWatchPermissions) return AWSetupState.REVOKED_PERMISSIONS

        if (!lastAppleWatchSyncDate) return AWSetupState.NO_SYNCED_TEMPERATURES
        if (lastAppleWatchSyncDate < awStartDate) return AWSetupState.NO_SYNCED_TEMPERATURES

        return AWSetupState.SETUP_COMPLETE
      },
    ),
  )

  private awStartDate?: IsoDate

  @_Memo()
  public init(): void {
    if (!isIOSApp) return

    this.hwId$.pipe(combineLatestWith(this.awStartDate$)).subscribe(([hwId]) => {
      if (hwId !== HardwareId.APPLE_WATCH) return

      void this.saveNewWristTemperatures()
    })
  }

  // true means user has wrist temperatures
  // false means user certainly doesn’t have a supported watch
  // null means we don’t have wrist temperatures nor HR, or we don't have wrist temps and get HR from a supported watch
  public async isDeviceWithWristTempSupport(
    wristTemps: HKQuantitySample[],
  ): Promise<AWWristTempSupportState> {
    if (wristTemps.length) return AWWristTempSupportState.SUPPORTED

    const compatible = await this.hasCompatibleAppleWatch(IOS_WATCH_MODEL)

    // we found a compatible watch, but with no wrist temps yet
    if (compatible) return AWWristTempSupportState.REPORTED_HR

    // see if we can read any HR data
    const heartRateSamples = await this.getLatestHeartRateSample()

    // no HR data either, we probably don't have permissions
    if (!heartRateSamples.length) return AWWristTempSupportState.UNKNOWN

    // if we find HR data from other device, we can be pretty sure they don't have a supported watch
    return AWWristTempSupportState.NOT_SUPPORTED
  }

  private async hasCompatibleAppleWatch(models: StringMap<WatchModel>): Promise<boolean> {
    const { result } = await NCHealthKit.hasCompatibleAppleWatch({
      models,
    })

    return result
  }

  public async getWristTemperatures(
    startDate?: IsoDate,
    limit?: number,
  ): Promise<HKQuantitySample[]> {
    const data = await this.getWristTemps(startDate, limit)

    if (!data.length) return []

    const { entryMap } = getState().userFertility
    let tempsByDate = this.groupSyncedWristTemps(data)
    const multipleData = this._getMultipleData(tempsByDate, entryMap, startDate)

    let { hkDevice } = getState()

    if (multipleData) {
      const { daysWithSingleWatch, daysWithMultipleWatches } = multipleData

      // for multiple watches and multiple temps for one day
      if (daysWithMultipleWatches.length) {
        if (!hkDevice?.isPreferred) {
          const availableDevicesWithWristTemps =
            this._getAvailableDevicesWithWristTemps(tempsByDate)
          const deviceOptions = this._getAvailableDevicesOptions(availableDevicesWithWristTemps)

          await this.showMultipleWatchesDetectedAlert(availableDevicesWithWristTemps, deviceOptions)
          hkDevice = getState().hkDevice
        }

        tempsByDate = this._getWristTempsWithMultipleWatches(tempsByDate, hkDevice!)
      }

      if (daysWithSingleWatch.length) {
        tempsByDate = this._getDataWithSleepLength(multipleData, tempsByDate)
      }
    }

    return _stringMapValues(tempsByDate).flat()
  }

  /**
   * Save new wrist temperatures that are not yet saved in the DB
   * Only read data starting from `awStartDate`
   */
  public async saveNewWristTemperatures(): Promise<DailyEntrySaveInput[]> {
    const { userFertility, account } = getState()

    // save no temps while in demo mode
    if (account.demoMode) return []

    const wristTempSamples = await this.getWristTemperatures(
      this.awStartDate,
      dailyEntrySaveInputLimit,
    )

    if (!wristTempSamples.length) return []

    const newWristTempSamples = wristTempSamples
      .filter(sample => {
        const date = localTime(sample.endTimestamp).toISODate()

        return !userFertility.entryMap?.[date]?.temperature
      })
      .reverse()

    return await this.saveWristTemperatures(newWristTempSamples, 'AppleWristTemperature')
  }

  public async saveWristTemperatures(
    samples: HKQuantitySample[],
    origin: string,
  ): Promise<DailyEntrySaveInput[]> {
    if (!samples.length) return []

    const wristTempSamples = samples.slice(0, dailyEntrySaveInputLimit)

    const { account, hkDevice } = getState()

    return await this.saveWristSamplesAsTemperatures(
      wristTempSamples,
      account.fahrenheit,
      hkDevice?.uuid,
      origin,
    )
  }

  public async enableWristTempBackgroundDelivery(): Promise<boolean> {
    const { result } = await NCHealthKit.enableBackgroundDelivery()
    return result
  }

  public mockWristTemperatureSample(i: number, endDate: IsoDate, temp?: number): HKQuantitySample {
    const date = localTime(endDate).minus(i, 'day')

    return {
      identifier: HKDataIdentifier.WRIST_TEMPERATURE,
      quantity: temp || _round(_randomInt(3500, 4200) / 100, 0.01),
      count: 1,
      UUID: '',
      startTimestamp: date.setHour(0).setMinute(0).unix,
      endTimestamp: date.setHour(7).setMinute(0).unix,
      source: {
        name: 'Natural Cycles',
        bundleId: 'com.naturalcycles.cordova',
      },
      metadata: {
        HKAlgorithmVersion: '1',
      },
      device: {
        uuid: 'test-uuid',
        softwareVersion: '9.0.0',
      },
    } satisfies HKQuantitySample
  }

  private async getWristTemps(startDate?: IsoDate, limit?: number): Promise<HKQuantitySample[]> {
    // QA only functionality!
    if (this.mockWristTemps?.length) {
      const { endTimestamp } = _first(this.mockWristTemps)
      const lastAppleWatchSyncDate = localTime(endTimestamp).toISODate()
      dispatch('extendUserSettings', {
        lastAppleWatchSyncDate,
        hasRevokedAppleWatchPermissions: false,
        revokedPermissionsAlertDisplayed: false,
        hasCompatibleAppleWatch: true,
      })

      return this.mockWristTemps
    }

    const getWristTempsStarted = Date.now()

    const { data } =
      (await this.healthKitService.readDataForIdentifier<HKQuantitySample>(
        {
          identifier: HKDataIdentifier.WRIST_TEMPERATURE,
          dataType: HKIdentifierDataType.QUANTITY,
          unit: HKUnit.CELSIUS,
        },
        localDate.orUndefined(startDate)?.unix,
        undefined,
        limit,
      )) || {}

    // there was an error reading wrist temps
    if (!data) return []

    if (data.length) {
      const { endTimestamp } = data[0]! // samples are sorted in descending order
      const lastAppleWatchSyncDate = localTime(endTimestamp).toISODate()
      dispatch('extendUserSettings', {
        lastAppleWatchSyncDate,
        hasRevokedAppleWatchPermissions: false,
        revokedPermissionsAlertDisplayed: false,
        hasCompatibleAppleWatch: true,
      })
    } else if (getState().userSettings.lastAppleWatchSyncDate) {
      // if query range was limited by startDate, also check if there are any temperatures in the past
      if (!startDate) {
        dispatch('extendUserSettings', { hasRevokedAppleWatchPermissions: true })
      } else {
        await this.getWristTemps()
      }
    }

    prf('Healthkit.GetWristTemps', getWristTempsStarted, undefined, true)
    return data
  }

  public async getLatestHeartRateSample(): Promise<HKQuantitySample[]> {
    const { data: heartRateSamples } =
      (await this.healthKitService.readDataForIdentifier<HKQuantitySample>(
        {
          identifier: HKDataIdentifier.HEART_RATE,
          dataType: HKIdentifierDataType.QUANTITY,
          unit: HKUnit.COUNT_PER_SECOND,
        },
        undefined,
        undefined,
        1,
      )) || { data: [] }

    return heartRateSamples
  }

  private groupSyncedWristTemps(
    syncedWristTemps: HKQuantitySample[],
  ): StringMap<HKQuantitySample[]> {
    return _groupBy(syncedWristTemps, t => localTime(t.endTimestamp).toISODate())
  }

  public _getMultipleData(
    tempsByDate: StringMap<HKQuantitySample[]>,
    entryMap: StringMap<DailyEntryBM>,
    startDate?: IsoDate,
  ): MultipleAWData | undefined {
    // Consider only days with multiple temperatures for which we don't have a saved temperature yet
    const dataWithMultipleTemps = _stringMapEntries(tempsByDate)
      .filter(
        ([date, temps]) =>
          (!startDate || date >= startDate) && temps.length > 1 && !entryMap[date]?.temperature,
      )
      .map(([_, temps]) => temps)

    if (!dataWithMultipleTemps.length) return
    const daysWithMultipleWatches: HKQuantitySample[] = []
    const daysWithSingleWatch: HKQuantitySample[] = []

    dataWithMultipleTemps.forEach(innerArray => {
      const uniqueDevice = _uniqBy(innerArray, d => d.device?.uuid)

      if (uniqueDevice.length === 1) {
        daysWithSingleWatch.push(...innerArray)
      } else {
        daysWithMultipleWatches.push(...innerArray)
      }
    })

    if (daysWithSingleWatch.length) {
      void this.analyticsService.trackEvent(EVENT.MULTIPLE_TEMPS_FOR_ONE_NIGHT, {
        device: 'single',
        data: daysWithSingleWatch,
      })
    }

    if (daysWithMultipleWatches.length) {
      void this.analyticsService.trackEvent(EVENT.MULTIPLE_TEMPS_FOR_ONE_NIGHT, {
        device: 'multiple',
        data: daysWithMultipleWatches,
      })
    }

    return {
      daysWithSingleWatch,
      daysWithMultipleWatches,
    }
  }

  public _getDataWithSleepLength(
    multipleData: MultipleAWData,
    syncedWristTempsByDate: StringMap<HKQuantitySample[]>,
  ): StringMap<HKQuantitySample[]> {
    // for single watch but multiple temps for one day
    const dataWithSleepLength = multipleData.daysWithSingleWatch.map(d => ({
      ...d,
      sleepLength: d.endTimestamp - d.startTimestamp,
    }))

    return _mapValues(syncedWristTempsByDate, (_date, temps) => {
      if (temps.length > 1) {
        const longestSleepData = multipleData.daysWithSingleWatch.find(
          item => item.UUID === _sortDescBy(dataWithSleepLength, d => d.sleepLength, true)[0]?.UUID,
        )
        return [longestSleepData]
      }
      return temps
    })
  }

  public _getAvailableDevicesWithWristTemps(
    syncedWristTempsByDate: StringMap<HKQuantitySample[]>,
  ): AWWristTempsDevice[] {
    const multipleTempsForOneDay = _stringMapValues(syncedWristTempsByDate)
      .filter(t => t.length > 1)
      .flat()

    const availableDevices = multipleTempsForOneDay.map(({ endTimestamp, source, device }) => ({
      lastUsedTimestamp: endTimestamp,
      sourceName: source.name,
      device,
    }))

    return availableDevices
  }

  public _getAvailableDevicesOptions(devices: AWWristTempsDevice[]): RadioGroupValue<string>[] {
    const availableDevicesOptions = _sortDescBy(devices, d => d.lastUsedTimestamp)
      .slice(0, 2)
      .map(availableDevice => {
        const hardwareVersion = availableDevice.device?.hardwareVersion
        return {
          title: availableDevice.sourceName || '',
          subtitle: IOS_WATCH_MODEL[hardwareVersion!]?.name,
          value: availableDevice.device?.uuid || '',
          uid:
            [IOS_WATCH_MODEL[hardwareVersion!]?.name, availableDevice.device?.uuid]
              .filter(Boolean)
              .join(' ') || '',
        }
      })

    return availableDevicesOptions
  }

  public _getWristTempsWithMultipleWatches(
    syncedWristTempsByDate: StringMap<HKQuantitySample[]>,
    preferredHKDevice: HKDevice,
  ): StringMap<HKQuantitySample[]> {
    return _mapValues(
      syncedWristTempsByDate,
      (_, temps) => temps.find(t => t.device?.uuid === preferredHKDevice?.uuid) || temps,
    )
  }

  private getWristTemperaturesMetadata(dataForIdentifier: HKQuantitySample[]): WristTempMetadata[] {
    const wristTempMeta = dataForIdentifier.map(sample => {
      return {
        date: localTime(sample.endTimestamp).toISODate(),
        startTimestamp: sample.startTimestamp,
        endTimestamp: sample.endTimestamp,
        temperatureC: _round(sample.quantity, 0.01), // We get data in Celsius from HKPlugin
        // Next 3 properties are always there for wrist temperatures
        hkAlgorithmVersion: sample.metadata?.['HKAlgorithmVersion']!,
        hkDeviceUUID: sample.device?.uuid!,
        hkDeviceSWVersion: sample.device?.softwareVersion!,
      }
    })

    return wristTempMeta
  }

  @loader(LoaderType.GHOST)
  private async saveWristSamplesAsTemperatures(
    samples: HKQuantitySample[],
    fahrenheit: boolean | undefined,
    currentHKDeviceUUID: string | undefined,
    origin: string,
  ): Promise<DailyEntrySaveInput[]> {
    const entries = this._mapHealthKitWristTemperaturesToDailyEntrySaveInput(samples, fahrenheit)
    const wristTempMeta = this.getWristTemperaturesMetadata(samples)
    let hkDevice: HKDevice | undefined

    // Save the latest HKDevice if it's different from before
    const { device } = samples[0]!

    if (device?.uuid !== currentHKDeviceUUID) {
      hkDevice = device
      dispatch('setHKDevice', hkDevice)
    }

    try {
      await this.dailyEntryService.saveBatch({
        entries,
        merge: true,
        origin,
        wristTempMeta,
        hkDevice,
      })
    } catch (err) {
      void this.errorService.showErrorDialog(err)
      return []
    }

    return entries
  }

  public _mapHealthKitWristTemperaturesToDailyEntrySaveInput(
    dataForIdentifier: HKQuantitySample[],
    fahrenheit?: boolean,
  ): DailyEntrySaveInput[] {
    const entries: DailyEntrySaveInput[] = dataForIdentifier.map(data => {
      const date = localTime(data.endTimestamp).toISODate()
      return {
        date,
        temperature: _round(
          fahrenheit ? this.unitsService.convertTempToFahrenheit(data.quantity) : data.quantity,
          0.01,
        ),
        temperatureMeasuredTimestamp: data.endTimestamp,
      }
    })

    return entries
  }

  public _getStartDate(ufStartDate: string, hwChanges: StringMap<HardwareId>): string {
    const [lastSwitchDate] =
      _findLast(_stringMapEntries(hwChanges), ([_k, value]) => value === HardwareId.APPLE_WATCH) ||
      []

    return lastSwitchDate || ufStartDate
  }

  private async showMultipleWatchesDetectedAlert(
    availableDevicesWithWristTemps: AWWristTempsDevice[],
    deviceOptions: RadioGroupValue<string>[],
  ): Promise<void> {
    const modal = await this.popupController.presentModal(
      {
        component: MultipleWatchesDetectedModal,
        componentProps: {
          deviceOptions,
        },
        cssClass: 'modal--transparent',
      },
      'aw-multiple-watches-detected-modal',
      Priority.HIGH,
      0,
    )

    const { data } = await modal.onWillDismiss()

    const preferredHKDevice = availableDevicesWithWristTemps?.find(
      ({ device }) => data.preferredHKDeviceId === device?.uuid,
    )?.device

    dispatch('setHKDevice', { ...preferredHKDevice, isPreferred: true })
  }
}
