import { _omit } from '@naturalcycles/js-lib'
import { AppSettings, AppTracker, DailyCheckinSettings, Goal } from '@naturalcycles/shared'
import { CheckboxGroupValue } from '../cmp/checkbox-group/checkbox-group.component'
import { defaultDailyCheckinTrackers } from '../cnst/daily-checkin-trackers.cnst'
import { ICON } from '../cnst/icons.cnst'
import { PAIN_FLAGS_DEFAULT_FOR_TRACKERS } from '../cnst/trackers.cnst'

export interface AppSettingsFM
  extends Omit<AppSettings, 'enabledTrackers' | 'enabledPartnerTrackers'> {
  trackers: TrackerSettings
  partnerTrackers: PartnerTrackerSettings
  dailyCheckinTrackers?: DailyCheckinSettings
}

const periodFlow: CheckboxGroupValue = {
  title: 'txt-period-flow',
  name: `${AppTracker.MENS_QUANTITY}`,
  uid: 'onboarding__trackers__periodFlow',
  icon: ICON.MENSTRUATION_HEAVY,
}

const cervicalMucus = {
  title: 'txt-cervical-mucus',
  name: `${AppTracker.CM_QUANTITY}`,
  uid: 'onboarding__trackers__cervicalMucus',
  icon: ICON.CM_QUANTITY_LIGHT,
}

const discharge = {
  title: 'txt-cervical-mucus-follow',
  name: `${AppTracker.CM_QUANTITY}`,
  uid: 'onboarding__trackers__cervicalMucus',
  icon: ICON.CM_QUANTITY_LIGHT,
}

const libido = {
  title: 'txt-sex-drive',
  name: `${AppTracker.LIBIDO}`,
  uid: 'onboarding__trackers__libido',
  icon: ICON.LIBIDO_HIGH,
}

const sexInfo = {
  title: 'txt-additional-sex-info',
  name: `${AppTracker.MORE_SEX}`,
  uid: 'onboarding__trackers__moreSex',
  icon: ICON.SEX_TOYS,
}

const skin = {
  title: 'txt-skin',
  name: `${AppTracker.SKIN}`,
  uid: 'onboarding__trackers__skin',
  icon: ICON.SKIN_OTHER,
}

const sleep = {
  title: 'txt-sleep',
  name: `${AppTracker.SLEEP}`,
  uid: 'onboarding__trackers__sleep',
  icon: ICON.SLEEP_OKAY,
}

const pain = {
  title: 'txt-pain-symptoms',
  name: `${AppTracker.PAIN}`,
  uid: 'onboarding__trackers__pain',
  icon: ICON.PAIN_CRAMPS,
}

const moods = {
  title: 'txt-emotions',
  name: `${AppTracker.MOOD}`,
  uid: 'onboarding__trackers__mood',
  icon: ICON.MOOD_CALM,
}

const breastFeeding = {
  title: 'txt-breastfeeding',
  name: `${AppTracker.BREASTFEEDING}`,
  uid: 'onboarding__trackers__breastFeeding',
  icon: ICON.BREASTFEEDING,
}

const bottleFeeding = {
  title: 'txt-bottle-feeding',
  name: `${AppTracker.BOTTLE_FEEDING}`,
  uid: 'onboarding__trackers__bottleFeeding',
  icon: ICON.BOTTLE_FEEDING,
}

const breastPumping = {
  title: 'txt-breast-pumping',
  name: `${AppTracker.PUMPING}`,
  uid: 'onboarding__trackers__breastPumping',
  icon: ICON.PUMPING,
}

export const TRACKERS_BY_GOAL: Record<Goal, CheckboxGroupValue[]> = {
  [Goal.PREVENT]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
  [Goal.PLAN]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
  [Goal.PREGNANT]: [pain, moods, skin, sleep, libido, sexInfo, discharge],
  [Goal.RECOVERY]: [periodFlow, pain, moods, skin, sleep, libido, sexInfo, cervicalMucus],
  [Goal.POSTPARTUM]: [
    periodFlow,
    pain,
    moods,
    breastFeeding,
    bottleFeeding,
    breastPumping,
    skin,
    sleep,
    libido,
    sexInfo,
    cervicalMucus,
  ],
}

export type TrackerSettings = Record<
  Exclude<
    AppTracker,
    AppTracker.NOTES | AppTracker.SEX | AppTracker.LH_TEST | AppTracker.PREG_TEST
  >,
  boolean
>
export type PartnerTrackerSettings = Record<
  Exclude<
    AppTracker,
    | AppTracker.MENS_QUANTITY
    | AppTracker.CM_CONSISTENCY
    | AppTracker.CM_QUANTITY
    | AppTracker.MORE_SEX
  >,
  boolean
>

export enum AddDataSettingsToAppSettings {
  MENS_FLOW_ENABLED = AppTracker.MENS_QUANTITY,
  CM_CONSISTENCY_ENABLED = AppTracker.CM_CONSISTENCY,
  CM_QUANTITY_ENABLED = AppTracker.CM_QUANTITY,
  LIBIDO_ENABLED = AppTracker.LIBIDO,
  SLEEP = AppTracker.SLEEP,
  PAIN_ENABLED = AppTracker.PAIN,
  SKIN_ENABLED = AppTracker.SKIN,
  MOODS_ENABLED = AppTracker.MOOD,
  MORE_SEX_ENABLED = AppTracker.MORE_SEX,
}

const excludedTrackersForPartnerSettings = [
  AppTracker.MENS_QUANTITY,
  AppTracker.CM_CONSISTENCY,
  AppTracker.CM_QUANTITY,
  AppTracker.MORE_SEX,
]
const trackersForPartnerSettingsOnly = [
  AppTracker.NOTES,
  AppTracker.SEX,
  AppTracker.LH_TEST,
  AppTracker.PREG_TEST,
]

export const allTrackersOn = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !trackersForPartnerSettingsOnly.some(key => Number(key) === Number(k)))
    .map(k => [k, true]),
) as TrackerSettings

export const allTrackersOff = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !trackersForPartnerSettingsOnly.some(key => Number(key) === Number(k)))
    .map(k => [k, false]),
) as TrackerSettings

export const allPartnerTrackersOn = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !excludedTrackersForPartnerSettings.some(key => Number(key) === Number(k)))
    .map(k => [k, true]),
) as PartnerTrackerSettings

export const allPartnerTrackersOff = Object.fromEntries(
  Object.keys(AppTracker)
    .filter(Number)
    .filter(k => !excludedTrackersForPartnerSettings.some(key => Number(key) === Number(k)))
    .map(k => [k, false]),
) as PartnerTrackerSettings

export function appSettingsTMtoFM(settings?: AppSettings | null, goal?: Goal): AppSettingsFM {
  settings ||= {
    enabledTrackers: [],
    enabledPartnerTrackers: [],
  }

  const { enabledTrackers, dailyCheckinTrackers } = settings
  const enabledPartnerTrackers = settings.enabledPartnerTrackers || []

  const verifiedDailyCheckinTrackers =
    goal === Goal.POSTPARTUM
      ? dailyCheckinTrackers || {
          ...defaultDailyCheckinTrackers,
          [AppTracker.SLEEP]: enabledTrackers.includes(AppTracker.SLEEP),
          [AppTracker.PAIN]: enabledTrackers.includes(AppTracker.PAIN)
            ? PAIN_FLAGS_DEFAULT_FOR_TRACKERS
            : [],
        }
      : undefined

  const trackers = Object.fromEntries(enabledTrackers.map(v => [v, v]))
  const partnerTrackers = Object.fromEntries(enabledPartnerTrackers.map(v => [v, v]))

  return {
    ..._omit(settings, ['enabledTrackers', 'enabledPartnerTrackers']),
    trackers: {
      [AppTracker.MENS_QUANTITY]: !!trackers[AppTracker.MENS_QUANTITY],
      [AppTracker.CM_CONSISTENCY]: !!trackers[AppTracker.CM_CONSISTENCY],
      [AppTracker.CM_QUANTITY]: !!trackers[AppTracker.CM_QUANTITY],
      [AppTracker.LIBIDO]: !!trackers[AppTracker.LIBIDO],
      [AppTracker.PAIN]: !!trackers[AppTracker.PAIN],
      [AppTracker.SKIN]: !!trackers[AppTracker.SKIN],
      [AppTracker.MOOD]: !!trackers[AppTracker.MOOD],
      [AppTracker.MORE_SEX]: !!trackers[AppTracker.MORE_SEX],
      [AppTracker.SLEEP]: !!trackers[AppTracker.SLEEP],
      [AppTracker.BOTTLE_FEEDING]: !!trackers[AppTracker.BOTTLE_FEEDING],
      [AppTracker.BREASTFEEDING]: !!trackers[AppTracker.BREASTFEEDING],
      [AppTracker.PUMPING]: !!trackers[AppTracker.PUMPING],
    },
    partnerTrackers: {
      [AppTracker.LIBIDO]: !!partnerTrackers[AppTracker.LIBIDO],
      [AppTracker.PAIN]: !!partnerTrackers[AppTracker.PAIN],
      [AppTracker.SKIN]: !!partnerTrackers[AppTracker.SKIN],
      [AppTracker.MOOD]: !!partnerTrackers[AppTracker.MOOD],
      [AppTracker.NOTES]: !!partnerTrackers[AppTracker.NOTES],
      [AppTracker.SEX]: !!partnerTrackers[AppTracker.SEX],
      [AppTracker.LH_TEST]: !!partnerTrackers[AppTracker.LH_TEST],
      [AppTracker.PREG_TEST]: !!partnerTrackers[AppTracker.PREG_TEST],
      [AppTracker.SLEEP]: !!partnerTrackers[AppTracker.SLEEP],
      [AppTracker.BREASTFEEDING]: !!partnerTrackers[AppTracker.BREASTFEEDING],
      [AppTracker.BOTTLE_FEEDING]: !!partnerTrackers[AppTracker.BOTTLE_FEEDING],
      [AppTracker.PUMPING]: !!partnerTrackers[AppTracker.PUMPING],
    },
    dailyCheckinTrackers: verifiedDailyCheckinTrackers,
  }
}
