import { inject, Injectable } from '@angular/core'
import { AWSetupState } from '@app/cnst/appleWatch.cnst'
import { COLOR } from '@app/cnst/color.cnst'
import { usesConnectedThermometer } from '@app/cnst/hardware.cnst'
import {
  ICON,
  ICON_BY_MENS,
  ICON_BY_MENS_QUANTITY,
  ICON_BY_MISCARRIAGE_QUANTITY,
  ICON_BY_OVULATION_STATUS,
  ICON_BY_POSTPARTUM_QUANTITY,
} from '@app/cnst/icons.cnst'
import {
  COLOR_GHOST,
  COLOR_OFFLINE,
  COLOR_OFFLINE_PARTNER,
  COLOR_PREGNANT,
  COLOR_RECOVERY,
  COLOR_WARNING,
  STATUS_BY_GOAL,
  STATUS_BY_REDSCALE,
} from '@app/cnst/uf.cnst'
import {
  BabyFeedingFM,
  FertilityCircleEntry,
  FertilityIcon,
  FertilityStatus,
  NoStatusState,
  PregnancyInfo,
  PregnancyNumbers,
  TrimesterNumber,
  TrimesterProgress,
  UFDay,
  UFEntry,
} from '@app/model/uf.model'
import { AddDataService } from '@app/pages/add-data/add-data.service'
import { select2 } from '@app/srv/store.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { DomController } from '@ionic/angular/standalone'
import {
  _filterNullishValues,
  _omit,
  _stringMapValues,
  _undefinedIfEmpty,
  DateInterval,
  IsoDate,
  localDate,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  AccountDataFM,
  AccountTM,
  AppTracker,
  BabyFeeding,
  BabyFeedingType,
  BaseColorCode,
  CalibrationResult,
  CervicalMucusConsistency,
  DailyEntryBM,
  dailyEntrySharedUtil,
  DataFlag,
  DataQuantity,
  FWVersion,
  FWVersionSeverity,
  Goal,
  HardwareId,
  Mens,
  OvulationStatus,
  TestResult,
  UFColor,
  UserFertility,
} from '@naturalcycles/shared'
import { BehaviorSubject } from 'rxjs'
import {
  combineLatestWith,
  map,
  shareReplay,
  skipWhile,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators'
import { AppleWatchService } from './appleWatch.service'
import { EventService } from './event.service'
import { HardwareDeviceService } from './hardwareDevice.service'
import { getState } from './store.service'
import { TourService } from './tour.service'

@Injectable({ providedIn: 'root' })
export class UFService {
  private appleWatchService = inject(AppleWatchService)
  private addDataService = inject(AddDataService)
  private eventService = inject(EventService)
  private hardwareDeviceService = inject(HardwareDeviceService)
  private tourService = inject(TourService)
  private dom = inject(DomController)

  private account$ = select2(s => s.account)
  private accountData$ = select2(s => s.accountData)
  private modifiedDailyEntries$ = select2(s => s.addData.modifiedDailyEntries)
  private moodsEnabled$ = select2(s => s.appSettings?.trackers[AppTracker.MOOD])
  private hwChanges$ = select2(s => s.hwChanges)
  private fwVersion$ = select2(s => s.hwDevice?.fwVersion)
  private latestFWVersion$ = select2(s => s.latestHWDeviceFWVersion)
  private partnerAccount$ = select2(s => s.partnerAccount)
  private ghost$ = select2(s => s.ui.ghostLoader)
  private _uf$ = select2(s => s.userFertility)
  private entryMap$ = select2(s => s.userFertility.entryMap)
  private showPredictions$ = select2(s => s.userSettings.showPredictions)

  private activeTour$ = this.tourService.activeTour$

  public uf$ = this._uf$.pipe(
    distinctUntilDeeplyChanged(),
    skipWhile(({ startDate }) => !startDate),
  )

  public currentPhase$ = this.uf$.pipe(map(uf => uf.currentPhase))

  private resumeOnNewDay$ = this.uf$.pipe(
    combineLatestWith(this.eventService.onResume$),
    skipWhile(([uf]) => {
      return uf.todayDate === localDate.todayString()
    }),
    map(([_uf, resume]) => resume),
  )

  public ufDays$ = this.uf$.pipe(
    combineLatestWith(
      this.modifiedDailyEntries$,
      this.account$,
      this.accountData$,
      this.hwChanges$,
      this.latestFWVersion$,
      this.fwVersion$,
      this.partnerAccount$,
      this.showPredictions$.pipe(combineLatestWith(this.ghost$, this.activeTour$)),
      this.resumeOnNewDay$.pipe(startWith(1)), // make sure to update data if date changed and uf.todayDate doesnt match current date
    ),
    map(
      ([
        uf,
        modifiedDailyEntries,
        { goal, nextPaymentDate },
        accountData,
        hwChanges,
        latestFWVersion,
        fwVersion,
        partnerAccount,
        [showPredictions, ghost, activeTour],
      ]) =>
        this._ufToUFDays(
          uf,
          modifiedDailyEntries,
          hwChanges,
          accountData,
          showPredictions,
          ghost,
          nextPaymentDate,
          !!activeTour,
          goal,
          !!partnerAccount,
          latestFWVersion,
          fwVersion,
        ),
    ),
    // Juraj: commenting out as this doesn't seem to have the intended effect
    // shareReplay(),
  )

  private _ufEntries: UFEntry[] = []
  public ufEntries$ = this.ufDays$.pipe(
    distinctUntilDeeplyChanged(),
    combineLatestWith(
      this.entryMap$.pipe(
        distinctUntilDeeplyChanged(),
        skipWhile(entryMap => !entryMap),
      ),
      this.modifiedDailyEntries$.pipe(skipWhile(modified => !modified)),
    ),
    map(([days, entries, modifiedDailyEntries]) =>
      this._ufDaysToUfEntries(days, entries, modifiedDailyEntries),
    ),
    tap(entries => (this._ufEntries = entries)),
    // Juraj: commenting out as this doesn't seem to have the intended effect
    // shareReplay(),
  )

  public fertilityCircleEntries$ = this.ufDays$
    .pipe(
      distinctUntilDeeplyChanged(),
      combineLatestWith(
        this.entryMap$.pipe(
          distinctUntilDeeplyChanged(),
          skipWhile(entryMap => !entryMap),
        ),
        this.modifiedDailyEntries$.pipe(skipWhile(modified => !modified)),
        this.moodsEnabled$,
        this.partnerAccount$,
      ),
    )
    .pipe(
      map(([days, entries, modifiedDailyEntries, moodsEnabled, partnerAccount]) =>
        this._ufDaysToFertilityCircleEntries(
          days,
          entries,
          modifiedDailyEntries,
          moodsEnabled || !!partnerAccount,
        ),
      ),
      shareReplay(),
    )

  public showFertilityStatus$ = this.ufEntries$.pipe(
    combineLatestWith(
      this.account$,
      this.partnerAccount$,
      this.activeTour$,
      this.hardwareDeviceService.hardwareDevice$,
      this.appleWatchService.awSetupState$,
    ),
    switchMap(async ([entries, account, partnerAccount, activeTour, hardware, awSetupState]) => {
      const { goal, lastCalibrationResult, hwId, hasTherm } = account

      if (partnerAccount) return true
      if (goal === Goal.PREGNANT || goal === Goal.POSTPARTUM || !!activeTour) return true
      if (hwId === HardwareId.OURA) return true

      if (hwId === HardwareId.APPLE_WATCH) {
        const isSetupIncomplete = awSetupState === AWSetupState.SETUP_INCOMPLETE
        this.noFertilityStatusState$.next(isSetupIncomplete ? NoStatusState.HAS_THERM : undefined)
        return !isSetupIncomplete
      }

      const hasAddedTemperature = entries.some(entry => entry.temperature)

      const state = this._getNoFertStatusState(
        hasAddedTemperature,
        !!hasTherm,
        lastCalibrationResult,
        hwId,
        hardware?.mac,
      )

      this.noFertilityStatusState$.next(state)

      if (hasAddedTemperature) return true

      const entriesAddedCount = entries.filter(
        ({ updated, skipped }) => !!updated && !skipped,
      ).length

      return entriesAddedCount >= 5
    }),
    shareReplay(),
  )

  public noFertilityStatusState$ = new BehaviorSubject<NoStatusState | undefined>(undefined)

  public pregnancyWeekString$ = this.ufEntries$.pipe(
    map(entries => {
      const { pregnantNow } = getState().account
      if (!pregnantNow) return

      const today = entries.find(e => e.today)
      if (!today) return

      return this._getPregnancyWeekString(today.cd)
    }),
  )

  public pregnancyInfo$ = this.uf$.pipe(
    map(uf => {
      if (!uf.pregnantNow) return

      const { dueDate, conceptionDate, todayDate, colorMap } = uf
      const cd = colorMap[todayDate]?.cd

      if (!(cd && dueDate && conceptionDate)) return

      return {
        conceptionDate,
        dueDate,
        weekString: this._getPregnancyWeekString(cd),
      } satisfies PregnancyInfo
    }),
  )

  public todayEntry$ = this.ufEntries$.pipe(
    map(entries => entries.find(e => e.today)),
    shareReplay(),
  )

  public allowOnboardingRestart$ = this.account$.pipe(
    combineLatestWith(this.uf$.pipe(map(uf => uf.entryMap))),
    map(([account, entryMap]) => this.canRedoOnboarding(entryMap, account)),
  )

  public init(): void {
    this.todayEntry$.subscribe(today => {
      if (!today) return

      const { colorClass } = today

      // Ghost colors are handled by ghost css
      if (colorClass === COLOR_GHOST) return

      this.dom.write(() => {
        document.documentElement.style.setProperty(
          '--today-fertility-color',
          `var(--fert-color-${colorClass})`,
        )
      })
    })
  }

  public getUfEntry(date: string): UFEntry | undefined {
    return this._ufEntries.find(d => d.date === date)
  }

  public getUfEntriesBetweenDates(start: IsoDate, end: IsoDate): UFEntry[] {
    return this._ufEntries.filter(entry => entry.date >= start && entry.date <= end)
  }

  public getCervicalMucusLabel(entry?: UFEntry): string | undefined {
    const {
      cervicalMucusConsistency: consistency,
      cervicalMucusQuantity: quantity,
      goal,
    } = entry || {}

    if (!consistency && !quantity) return

    if (goal === Goal.PREGNANT) {
      return quantity ? `txt-cervical-mucus-label-follow--${DataQuantity[quantity]}` : undefined
    }

    let key = 'txt-cervical-mucus-label'

    if (quantity) {
      key += `--${DataQuantity[quantity]}`
    }

    if (consistency) {
      key += `--${CervicalMucusConsistency[consistency]}`
    }

    return key
  }

  public hasExcludedTemperature(entry: DailyEntryBM): boolean {
    return !!this._ufEntries.find(d => d.date === entry.date)?.deviationReasons
  }

  // should match fertility colors set up in _theme.scss
  public hexFromColor(color?: string, showFertilityStatus = true): string {
    if (color && !showFertilityStatus) {
      return COLOR.HIDDEN_FERTILITY_STATUS
    }

    switch (color) {
      // Prevent
      case 'REDPREVENT':
      case 'YELLOWPREVENT':
        return COLOR.TEMPERATURE

      case 'GREENPREVENT':
        return COLOR.OLIVER

      // Plan
      case 'RED1PLAN':
        return COLOR.TABASCO

      case 'RED2PLAN':
        return COLOR.CHOLULA

      case 'RED3PLAN':
        return COLOR.HABANERO

      case 'RED4PLAN':
        return COLOR.TEMPERATURE

      case 'RED5PLAN':
        return COLOR.MONA_LISA

      case 'RED6PLAN':
      case 'REDPLAN':
        return COLOR.CHAMPAGNE

      case 'GREEN6PLAN':
      case 'GREENPLAN':
        return COLOR.OLLE

      case 'YELLOWPLAN':
        return COLOR.MILK_CHOCOLATE

      case COLOR_PREGNANT:
        return COLOR.PREGNANT

      case COLOR_OFFLINE:
        return COLOR.SATSUMA

      case COLOR_WARNING:
        return COLOR.SATSUMA

      case COLOR_RECOVERY:
        return COLOR.RECOVERY

      default:
        return COLOR.MEDIUM_GRAY
    }
  }

  public _ufToUFDays(
    uf: UserFertility,
    modifiedDailyEntries: StringMap<DailyEntryBM>,
    hwChanges: StringMap<HardwareId>,
    accountData: AccountDataFM,
    showPredictions?: boolean,
    ghost?: boolean,
    nextPaymentDate?: string,
    isTourActive?: boolean,
    currentGoal?: Goal,
    isPartner?: boolean,
    latestFWVersion?: FWVersion | null,
    fwVersion?: string,
  ): UFDay[] {
    const { colorMap, predictionMap, todayDate: ufToday, conceptionDate, dueDate } = uf
    const { pregnancyEndCare, postpartumBleedDates } = accountData

    if (!colorMap) return []

    const today = localDate.todayString()
    const postpartumBleedIntervals = postpartumBleedDates?.map(interval =>
      DateInterval.parse(interval),
    )

    const birthDates = postpartumBleedIntervals?.map(interval => interval.start.toISODate()) // example: ['01-01-2023', '02-02-2024']
    const nCycleOfBirths = birthDates?.map(date => colorMap[date]?.nCycle ?? 0) // example for existing users: [5, 25], example for new users without period: [0]

    const past = (Object.keys(colorMap) as IsoDate[]).sort().map(date => {
      const ufColor = colorMap[date]!

      const day: UFDay = {
        ...ufColor,
        colorClass: this.getColorClass(ufColor, ghost),
        fertilityStatus: this.getFertilityStatus(
          ufColor,
          ghost,
          conceptionDate,
          dueDate,
          currentGoal,
        ),
        today: date === today || undefined,
        mensQuantity: predictionMap[date]?.mensQuantity,
        dataFlags: predictionMap[date]?.dataFlags,
        hwIdChange: hwChanges[date],
        pregnancyEndCare: pregnancyEndCare?.[date],
        pregnancyData: ufColor.code.defPreg ? this._getPregnancyData(ufColor.cd) : undefined,
        gaveBirth: postpartumBleedIntervals?.some(i => i.start.isSame(date)) ? true : undefined,
        isCycleAfterBirth: nCycleOfBirths?.some(nCycleOfBirth => nCycleOfBirth === ufColor.nCycle),
      }

      if (predictionMap[date]) {
        // combine colorMap with predictionMap to get predicted UFCodes (checkLH, mens etc)
        // dont use _merge as it will mutate state!!

        // filter out undefined values from color code & prediction code, then merge them
        const filteredColorCode = _filterNullishValues({ ...day.code })
        const filteredPredictionCode = _filterNullishValues({ ...predictionMap[date].code })
        const code = { ...filteredColorCode, ...filteredPredictionCode }

        return _filterNullishValues({ ...predictionMap[date], ...day, code })
      }

      return _filterNullishValues(day)
    })

    const future = Object.keys(predictionMap)
      .sort()
      .filter(date => date !== ufToday) // filter out ufToday
      .map(date => {
        const ufColor = predictionMap[date]!

        const day: UFDay = {
          ...ufColor,
          colorClass:
            showPredictions || isTourActive ? this.getColorClass(ufColor, ghost) : undefined,
          fertilityStatus: showPredictions
            ? this.getFertilityStatus(ufColor, ghost, conceptionDate, dueDate, currentGoal)
            : undefined,
          today: date === today || undefined,
          prediction: true,
          mensQuantity: predictionMap[date]?.mensQuantity,
          dataFlags: predictionMap[date]?.dataFlags,
          pregnancyData:
            showPredictions && ufColor.code.defPreg
              ? this._getPregnancyData(ufColor.cd)
              : undefined,
          isCycleAfterBirth: nCycleOfBirths?.some(
            nCycleOfBirth => nCycleOfBirth === ufColor.nCycle,
          ),
        }

        return _filterNullishValues(day)
      })

    let ufDays = [...past, ...future]

    // if real today doesn't match ufToday, mark all days after ufToday as offline
    if (ufToday !== today && !ghost) {
      ufDays = this.markAsOfflineAfterDate(ufDays, ufToday, 'txt-offline', true, isPartner)

      // also mark past days as non-prediction
      ufDays = ufDays.map(day => {
        return {
          ...day,
          prediction: day.date > today,
        }
      })
    }

    // if there is modified entries, mark all days after the first modified entry as offline
    if (Object.keys(modifiedDailyEntries).length && !ghost) {
      const firstDate = Object.keys(modifiedDailyEntries).sort()[0]!

      ufDays = this.markAsOfflineAfterDate(ufDays, firstDate, 'txt-offline')
    }

    // If there is a critical update, mark all dates as "Use protection"
    if (
      latestFWVersion?.severity === FWVersionSeverity.CRITICAL &&
      fwVersion &&
      fwVersion !== latestFWVersion.version
    ) {
      ufDays = ufDays.map(day => {
        if (day.date < latestFWVersion.releaseDate) return day
        return {
          ...day,
          fertilityStatus: { key: 'fertility-fertilePrevent' },
          colorClass: COLOR_WARNING,
        }
      })
    }

    // if today is past NextPaymentDate, mark all days after nextPaymentDate as offline
    if (nextPaymentDate && today > nextPaymentDate && !this.addDataService.canAddData()) {
      ufDays = this.markAsOfflineAfterDate(ufDays, nextPaymentDate, 'txt-unknown')
    }

    return ufDays
  }

  public _ufDaysToUfEntries(
    ufDays: UFDay[] = [],
    entries: StringMap<DailyEntryBM> = {},
    modifiedDailyEntries: StringMap<DailyEntryBM> = {},
  ): UFEntry[] {
    return ufDays.map(day => {
      const entry = modifiedDailyEntries[day.date] || entries[day.date]

      if (!entry) return day

      // take everything except dataflags straight from DailyEntryBM
      const entryProps = _omit(entry, ['dataFlags'])
      const { dataFlags } = entry

      // Filter out predicted dataFlags if same flags added by the user
      if (day.today) {
        day.dataFlags = day.dataFlags?.filter(flag => !dataFlags.includes(flag))
      }

      const ufEntry: UFEntry = {
        ...day,
        ...entryProps,
        moods: this.getDataFlags('MOOD_', dataFlags),
        pains: this.getDataFlags('PAIN_', dataFlags),
        skinFlags: this.getDataFlags('SKIN_', dataFlags),
        deviationReasons: this.getDataFlags('DEVIATION_REASON_', dataFlags, [
          DataFlag.OURA_IRREGULAR_SLEEP,
          DataFlag.OURA_INCOMPLETE_DATA,
          DataFlag.OURA_DEVIATION_ALGO,
        ]),
        emergencyFlags: this.getDataFlags('MORE_EMERGENCY_', dataFlags),
        ouraFlags: this.getDataFlags('OURA_ADJUSTED_TEMPERATURE', dataFlags),
        covidFlags: this.getDataFlags('COVID_', dataFlags),
        sexFlags: this.getDataFlags('SEX_', dataFlags),
        mensQuantity: entry.mens ? entryProps.mensQuantity : day.mensQuantity, // use entry mensQ if user added mens
        babyFeeding: this.getBabyFeeding(entry.babyFeeding),
      }

      return _filterNullishValues(ufEntry)
    })
  }

  public _ufDaysToFertilityCircleEntries(
    ufDays: UFDay[],
    entries: StringMap<DailyEntryBM>,
    modifiedDailyEntries: StringMap<DailyEntryBM>,
    moodsEnabled?: boolean,
  ): FertilityCircleEntry[] {
    return ufDays.map(day => {
      const entry = modifiedDailyEntries[day.date] || entries[day.date]

      // Don't show status icon if there is no entry or if there is short sleep data flag
      const statusIcon =
        entry && !entry.dataFlags.includes(DataFlag.OURA_SHORT_SLEEP)
          ? entry.skipped
            ? ICON.SKIP
            : ICON.CHECK
          : undefined

      const ufEntry: FertilityCircleEntry = {
        ...day,
        statusIcon,
        icon: this.getIconForFertilityCircle(day, entry, !moodsEnabled),
        topLabel: this.getFertilityCircleLabelForDay(day),
      }

      return _filterNullishValues(ufEntry)
    })
  }

  private getColorClass(colorCode: BaseColorCode, ghost?: boolean): string {
    if (ghost) return COLOR_GHOST

    const { color, goal, redscale, code } = colorCode

    if (goal === Goal.RECOVERY || color === UFColor.PURPLE) return COLOR_RECOVERY

    if (code.defPreg) return COLOR_PREGNANT

    let colorClass = UFColor[color]

    if (redscale && color === UFColor.RED && goal === Goal.PLAN) colorClass += `${redscale}`
    if (goal) colorClass += goal === Goal.POSTPARTUM ? 'PREVENT' : Goal[goal] // PP goal uses Prevent colors

    return colorClass
  }

  public getFertilityStatus(
    ufColor: BaseColorCode,
    ghost?: boolean,
    conceptionDate?: IsoDate,
    dueDate?: IsoDate,
    currentGoal?: Goal,
  ): FertilityStatus {
    const {
      date,
      goal,
      color,
      redscale,
      code: { defPreg },
      cd,
    } = ufColor

    if (ghost) {
      if (goal === Goal.RECOVERY) {
        return {
          key: STATUS_BY_GOAL[goal][1],
        }
      }

      let random = Math.floor(Math.random() * 25) + 1

      // prevent "Checking your fertility" and "Your fertility will be" ghost messages for preggo users
      if (defPreg && (random === 1 || random === 6)) {
        random = 4
      }

      return {
        key: `ghost--${random}`,
      }
    }

    const map = STATUS_BY_GOAL[goal]

    let key = map[color]

    if (color === UFColor.RED && goal === Goal.PLAN) {
      key = STATUS_BY_REDSCALE[redscale || 6]!
    }

    if (color === UFColor.PURPLE && goal === Goal.POSTPARTUM) {
      if (cd === 1) return { key: 'txt-postpartum-day' }

      const ppWeeks = Math.floor(cd / 7)
      const key = ppWeeks > 1 ? 'txt-postpartum-weeks' : `txt-postpartum-days`
      const num = ppWeeks > 1 ? ppWeeks : cd

      return {
        key,
        num,
      }
    }

    if (!defPreg) return { key }

    if (currentGoal !== Goal.PREGNANT || (conceptionDate && date < conceptionDate)) {
      return { key: 'fertility-pregnant' }
    }

    const pregDaysToGo =
      conceptionDate && dueDate ? this.getPregDaysToGo(cd, conceptionDate, dueDate) : 0

    if (pregDaysToGo > 1) {
      key = 'fertility-pregdaystogo'
    } else if (pregDaysToGo === 1) {
      key = 'fertility-pregdaytogo'
    } else {
      const randomPregDaysOverdue = Math.abs(pregDaysToGo) % 5

      key = `fertility-pregdaysoverdue--${randomPregDaysOverdue}`
    }

    return { key, num: Math.abs(pregDaysToGo) }
  }

  private getPregDaysToGo(pregnantDay: number, conceptionDate: IsoDate, dueDate: IsoDate): number {
    const pregLength = localDate(dueDate).diff(conceptionDate, 'day')

    return pregLength - pregnantDay
  }

  private getDataFlags(
    prefix: string,
    flags: DataFlag[] = [],
    includeList?: DataFlag[],
  ): DataFlag[] | undefined {
    const items: DataFlag[] = []

    items.push(...flags.filter(f => includeList?.includes(f) || DataFlag[f]?.startsWith(prefix)))

    return _undefinedIfEmpty(items)
  }

  private getBabyFeeding(babyFeeding?: BabyFeeding[]): BabyFeedingFM | undefined {
    if (!babyFeeding?.length) return

    return {
      breastFeeding: this.getFeedingTypeCount(BabyFeedingType.BREASTFEEDING, babyFeeding),
      bottleFeeding: this.getFeedingTypeCount(BabyFeedingType.BOTTLE_FEEDING, babyFeeding),
      pumping: this.getFeedingTypeCount(BabyFeedingType.PUMPING, babyFeeding),
    }
  }

  private getFeedingTypeCount(
    type: BabyFeedingType,
    babyFeeding?: BabyFeeding[],
  ): number | undefined {
    return babyFeeding?.filter(bf => bf.type === type).length
  }

  private markAsOfflineAfterDate(
    days: UFDay[],
    date: string,
    key: string,
    includeDate?: boolean,
    isPartner?: boolean,
  ): UFDay[] {
    return days.map(day => {
      if (day.date < date) return day
      if (includeDate && day.date === date) return day

      return {
        ...day,
        offline: true,
        colorClass: isPartner ? COLOR_OFFLINE_PARTNER : COLOR_OFFLINE,
        fertilityStatus: {
          key: day.goal === Goal.PREVENT ? 'fertility-fertilePrevent' : key,
        },
      }
    })
  }

  // eslint-disable-next-line complexity
  public getIconForFertilityCircle(
    day: UFDay,
    entry?: DailyEntryBM,
    hidePMSPredictions?: boolean,
  ): FertilityIcon | undefined {
    // icons in priority order

    if (day.gaveBirth) {
      return {
        icon: ICON.PREGNANT_BABY,
        prediction: false,
      }
    }

    // added mens & algo added mens
    if (entry?.mens === Mens.MENSTRUATION || (day?.code.mens && !day.prediction && !day.today)) {
      let icon = ICON.MENSTRUATION

      if (entry?.mensQuantity) {
        icon = ICON_BY_MENS_QUANTITY[entry.mensQuantity]!
      }

      return { icon, prediction: false }
    }

    // added miscarriage bleeding
    if (entry?.mens === Mens.MISCARRIAGE_BLEEDING) {
      let icon = ICON.MISCARRIAGE_MEDIUM

      if (entry?.mensQuantity) {
        icon = ICON_BY_MISCARRIAGE_QUANTITY[entry.mensQuantity]!
      }

      return { icon, prediction: false }
    }

    // added postpartum bleeding
    if (entry?.mens === Mens.POSTPARTUM_BLEEDING) {
      let icon = ICON.POSTPARTUM_MEDIUM

      if (entry?.mensQuantity) {
        icon = ICON_BY_POSTPARTUM_QUANTITY[entry.mensQuantity]!
      }

      return { icon, prediction: false }
    }

    // predicted pregTest
    if (day?.code.checkPreg) return { icon: ICON.PREG_TEST, prediction: true }

    // predicted mens
    if (day?.code.mens) {
      const icon = day.mensQuantity
        ? ICON_BY_MENS_QUANTITY[day.mensQuantity]!
        : ICON_BY_MENS[Mens.MENSTRUATION]

      return { icon, prediction: true }
    }

    // ovulation
    const ovulation = day.ovulationStatus

    if (ovulation && ICON_BY_OVULATION_STATUS[ovulation]) {
      const icon = ICON_BY_OVULATION_STATUS[ovulation]

      return {
        icon,
        prediction:
          ovulation === OvulationStatus.OVU_PREDICTION || ovulation === OvulationStatus.OVU_DAY,
      }
    }

    // added data
    if (entry?.pregTest === TestResult.YES) return { icon: ICON.PREG_TEST_POS, prediction: false }
    if (entry?.pregTest === TestResult.NO) return { icon: ICON.PREG_TEST_NEG, prediction: false }
    if (entry?.lhTest === TestResult.YES) {
      return { icon: ICON.LH_POS, prediction: false }
    }
    if (entry?.lhTest === TestResult.NO) {
      return { icon: ICON.LH_NEG, prediction: false }
    }
    if (entry?.dataFlags?.includes(DataFlag.MOOD_PMS)) {
      return { icon: ICON.MOOD_PMS, prediction: false }
    }

    // predicted lh
    if (day.code.checkLh) {
      return { icon: ICON.LH_TEST, prediction: true }
    }

    // predicted PMS
    if (day?.dataFlags?.includes(DataFlag.MOOD_PMS)) {
      if (day?.prediction && hidePMSPredictions) return

      return { icon: ICON.MOOD_PMS, prediction: true }
    }
  }

  public _getNoFertStatusState(
    hasAddedTemp: boolean,
    hasTherm: boolean,
    calibrationResult: CalibrationResult | undefined,
    hwId: HardwareId,
    hardwareId?: string,
  ): NoStatusState | undefined {
    // dont change state if we once entered HAS_THERM
    if (this.noFertilityStatusState$.value === NoStatusState.HAS_THERM) {
      return NoStatusState.HAS_THERM
    }

    // uebe & T3
    if (usesConnectedThermometer(hwId)) {
      if (hardwareId) return NoStatusState.HAS_THERM
      if (hasTherm) return NoStatusState.CONNECTED_THERM_UNPAIRED
      return hwId === HardwareId.UEBE_THERMOMETER
        ? NoStatusState.UEBE_NO_THERM
        : NoStatusState.T3_NO_THERM
    }

    if (calibrationResult === CalibrationResult.SUCCESS || hasAddedTemp) {
      return NoStatusState.HAS_THERM
    }

    if (hasTherm) return NoStatusState.WAITING

    return NoStatusState.NO_THERM
  }

  /** *
   * Calculates all sorts of pregnancy data based on the cycle day
   */
  public _getPregnancyData(cycleDay: number): PregnancyNumbers {
    const week = Math.floor(cycleDay / 7) + 2

    return {
      weekInclusive: week + 1,
      week,
      day: cycleDay % 7,
      trimester: this.getTrimester(week),
    }
  }

  private getTrimester(week: number): TrimesterNumber {
    if (week <= 12) return 1
    if (week < 27) return 2
    return 3
  }

  /**
   * @returns string with full number of pregnancy weeks + days since this week start
   * @example
   * this._getPregnancyWeekString(26) // "5+5"
   */
  public _getPregnancyWeekString(cycleDay: number): string {
    const { day, week } = this._getPregnancyData(cycleDay)

    return `${week}+${day}`
  }

  public getTrimesterProgress(
    cd: number,
    dueDate: IsoDate,
    conceptionDate: IsoDate,
  ): TrimesterProgress[] {
    const expectedPregnancyLength = localDate(dueDate).diff(conceptionDate, 'day')

    const trimesterLengths = [
      76, // pregnancy week 2+0 -> 12+6
      98, // pregnancy week 13+0 -> 26+6
      expectedPregnancyLength - 98 - 76, // the rest
    ]

    const trimesters: number[] = []

    // push days into trimesters
    for (let index = 0; index < trimesterLengths.length; index++) {
      const length = trimesterLengths[index]!

      const pastTrimesters = trimesterLengths.slice(0, index)

      const trimesterDay = cd - (pastTrimesters.length ? pastTrimesters.reduce((a, b) => a + b) : 0)

      // ongoing trimester
      if (trimesterDay < length) {
        trimesters.push(trimesterDay)
        break
      }

      // completed trimester
      trimesters.push(length)
    }

    // calculate progress & offset
    const result = trimesterLengths.map((_length, index) => {
      const length = (_length / expectedPregnancyLength) * 0.9 // * 0.9 to mind the gaps
      const trimesterDays = trimesters[index]
      const progress = (trimesterDays ? trimesterDays / _length : 0) * length

      const pastTrimesters = trimesterLengths.slice(0, index)

      const pastlength = pastTrimesters.length ? pastTrimesters.reduce((a, b) => a + b) : 0

      const offset = (360 * pastlength) / expectedPregnancyLength

      return {
        length,
        progress,
        transform: `rotate(${offset}deg)`,
      }
    })

    return result
  }

  public canRedoOnboarding(entryMap: StringMap<DailyEntryBM>, account: AccountTM): boolean {
    const { completeDate, demoMode, goal } = account
    if (!completeDate || demoMode) return false
    if (goal === Goal.POSTPARTUM || account.pregnantNow) return false

    // Users with no algo-related data are always allowed to redo onboarding
    const hasAddedData = _stringMapValues(entryMap).some(entry =>
      dailyEntrySharedUtil.hasAlgoRelatedInput(entry),
    )
    return !hasAddedData || localDate.orToday(completeDate).isYoungerThan(61, 'day')
  }

  public getFertilityCircleLabelForDay(ufDay: UFDay): string | undefined {
    const { goal, cd, pregnancyData, isCycleAfterBirth } = ufDay

    if (goal === Goal.POSTPARTUM && isCycleAfterBirth) {
      return cd >= 7 && (cd - 1) % 7 === 0 ? `Week ${Math.ceil(cd / 7) - 1}` : undefined
    }
    if (goal === Goal.PREGNANT) {
      return pregnancyData?.day === 0 ? `Week ${pregnancyData.weekInclusive}` : undefined
    }

    return `${cd}`
  }
}
