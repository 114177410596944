import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { appVer } from '@app/cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { isIOSApp, isNativeApp } from '@app/cnst/userDevice.cnst'
import { decorate, ErrorHandlerType, initDecorators, LoaderType } from '@app/decorators/decorators'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { appInitGuard } from '@app/guards/appInit.guard'
import { MultipleEntriesService } from '@app/modals/multiple-entries/multiple-entries.service'
import { FERTILITY_DATA } from '@app/model/healthKit.model'
import { AddDataService } from '@app/pages/add-data/add-data.service'
import { GraphService } from '@app/pages/graph/srv/graph.service'
import { MessageService } from '@app/pages/home/messages/message.service'
import { RemindersService } from '@app/pages/settings/reminders/reminders.service'
import { runOnIdle } from '@app/perf/idle.util'
import { AppService } from '@app/srv/app.service'
import { BadgeService } from '@app/srv/badge.service'
import { BlockingLoaderService } from '@app/srv/blockingLoader.service'
import { DebugService } from '@app/srv/debug.service'
import { DeeplinkService } from '@app/srv/deeplink.service'
import { ErrorService } from '@app/srv/error.service'
import { EventService } from '@app/srv/event.service'
import { firebaseService } from '@app/srv/firebase.service'
import { HealthKitService } from '@app/srv/healthkit/healthkit.service'
import { IAPService } from '@app/srv/iap.service'
import { LangService } from '@app/srv/lang.service'
import { LhService } from '@app/srv/lh.service'
import { MeasureStreakService } from '@app/srv/measureStreak.service'
import {
  appInitDone,
  bootstrapDone,
  firstIdle,
  firstPageOpened,
  userDeviceDone,
} from '@app/srv/milestones'
import { NavService } from '@app/srv/nav.service'
import { NetworkService } from '@app/srv/network.service'
import { Network2Service } from '@app/srv/network2.service'
import { PushNotificationService } from '@app/srv/notification.push.service'
import { NotificationService } from '@app/srv/notification.service'
import { OrientationService } from '@app/srv/orientation.service'
import { PartnerService } from '@app/srv/partner.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { PreloadService } from '@app/srv/preload.service'
import { sentryService } from '@app/srv/sentry.service'
import { SessionService } from '@app/srv/session.service'
import { StatusBarService } from '@app/srv/statusbar.service'
import { Storage3LocalStorageAdapter } from '@app/srv/storage3/storage3.localStorage.adapter'
import { storage3Service } from '@app/srv/storage3/storage3.service'
import { Storage3CordovaFileAdapter } from '@app/srv/storage3/storage3CordovaFileAdapter'
import { dispatch, getState, StoreService } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { UFService } from '@app/srv/uf.service'
import { UserDeviceService } from '@app/srv/userDevice.service'
import { WidgetService } from '@app/srv/widget.service'
import { buildInfo } from '@app/util/buildInfo.util'
import { reloadPageOnErrorFn } from '@app/util/error.util'
import { logUtil } from '@app/util/log.util'
import { prf } from '@app/util/perf.util'
import { urlUtil } from '@app/util/url.util'
import { promiseNoZone, setTimeoutNoZone } from '@app/util/zone.util'
import { App } from '@capacitor/app'
import { Preferences } from '@capacitor/preferences'
import { SplashScreen } from '@capacitor/splash-screen'
import { Platform } from '@ionic/angular/standalone'
import { _isNotEmptyObject, _Memo, HttpRequestError, localDate, pHang } from '@naturalcycles/js-lib'
import { BackendResponseFM, HardwareId, LANG_DEFAULT } from '@naturalcycles/shared'
import { TranslateService } from '@ngx-translate/core'
import { env } from '@src/environments/environment'
import { concatMap, Subject } from 'rxjs'
import { AppearanceService } from './appearance.service'
import { AppleWatchService } from './appleWatch.service'
import { AppTrackingTransparencyService } from './appTrackingTransparency.service'
import { BiometricAuthService } from './biometricAuth.service'
import { BluetoothConnectService } from './bluetooth.connect.service'
import { BluetoothService } from './bluetooth.service'
import { CompanionWatchService } from './companionWatch.service'
import { QAService } from './qa.service'
import { QuizService } from './quiz/quiz.service'

interface AppInitInput {
  runAlgo: boolean
  start: boolean
  runPostInit: boolean
}

@Injectable({ providedIn: 'root' })
export class BootstrapService {
  private platform = inject(Platform)
  private storeService = inject(StoreService)
  private langService = inject(LangService)
  private statusBarService = inject(StatusBarService)
  private orientationService = inject(OrientationService)
  private userDeviceService = inject(UserDeviceService)
  private appService = inject(AppService)
  private networkService = inject(NetworkService)
  private network2Service = inject(Network2Service)
  private debugService = inject(DebugService)
  private blockingLoaderService = inject(BlockingLoaderService)
  private navService = inject(NavService)
  private translateService = inject(TranslateService)
  private analyticsService = inject(AnalyticsService)
  private notificationService = inject(NotificationService)
  private pushNotificationService = inject(PushNotificationService)
  private remindersService = inject(RemindersService)
  private addDataService = inject(AddDataService)
  private multipleEntriesService = inject(MultipleEntriesService)
  private sessionService = inject(SessionService)
  private deeplinkService = inject(DeeplinkService)
  private preloadService = inject(PreloadService)
  private errorService = inject(ErrorService)
  private messageService = inject(MessageService)
  private measureStreakService = inject(MeasureStreakService)
  private eventService = inject(EventService)
  private badgeService = inject(BadgeService)
  private popupController = inject(PopupController)
  private widgetService = inject(WidgetService)
  private inAppPurchaseService = inject(IAPService)
  private graphService = inject(GraphService)
  private ufService = inject(UFService)
  private partnerService = inject(PartnerService)
  private lhService = inject(LhService)
  private qaService = inject(QAService)
  private appTrackingTransparencyService = inject(AppTrackingTransparencyService)
  private biometricAuthService = inject(BiometricAuthService)
  private appearanceService = inject(AppearanceService)
  private companionWatchService = inject(CompanionWatchService)
  private quizService = inject(QuizService)
  private bluetoothService = inject(BluetoothService)
  private bluetoothConnectService = inject(BluetoothConnectService)
  private appleWatchService = inject(AppleWatchService)
  private healthKitService = inject(HealthKitService)

  constructor() {
    ;(window as any).bootstrapService = this // for debugging

    // Init decorators.ts
    // It's here, to avoid circular dependency
    initDecorators((err, errorHandlerType) => {
      if (!errorHandlerType) return

      if (errorHandlerType === ErrorHandlerType.DIALOG) {
        void this.errorService.showErrorDialog(err)
      } else {
        this.errorService.logError(err)
      }

      // suppress error, don't propagate further, resolve(undefined)
    })
  }

  private appInitSubject = new Subject<AppInitInput>()

  @_Memo()
  @tryCatch({
    onError: () => reloadPageOnErrorFn(),
  })
  public async bootstrap(): Promise<void> {
    if (env.alertOnStart) alert('start')

    if (!env.dev) {
      console.log(
        `%c${buildInfo.ver}`,
        'color: #fff; background: #51224c; font-size: 16px; padding: 4px 8px;',
      )
    } else {
      void this.hideLoader()
    }

    prf('bootstrapStarted')

    // concatMap makes sure to only run only one appinit simultaneously
    this.appInitSubject.pipe(concatMap(input => this.runAppInit(input))).subscribe()

    // Initialize Firebase Perf Monitoring
    firebaseService.init()

    // Does not depend on platformReady (no native plugins needed)
    this.translateService.setDefaultLang(LANG_DEFAULT)

    // All Cordova plugins should be used/initialized AFTER platform.ready()
    await this.platform.ready()
    prf('platformReady')

    if (isNativeApp) {
      // hide html loader quickly, to prevent "double loaders on slow Android"
      void this.hideLoader()

      this.deeplinkService.init()
    }

    this.orientationService.init()

    const storage3Adapter = this.platform.is('hybrid')
      ? new Storage3CordovaFileAdapter()
      : new Storage3LocalStorageAdapter()
    storage3Service.init(storage3Adapter)

    await this.storeService.init()

    // trigger loading locale file ASAP (as soon as we know which language we need)
    const langServiceInitPromise = this.langService.init()

    // This line causes navigation to HomePage on refresh; keeping as a comment for now in case this breaks something
    // void this.navController.navigateRoot(ROUTES.HomePage)

    if (this.handleRedirects()) return // user will remain in SplashScreen while redirect is happening

    this.networkService.init()

    void this.analyticsService.init()

    this.blockingLoaderService.init()

    void this.statusBarService.init()

    this.debugService.init()

    this.navService.init()

    void App.addListener('pause', async () => {
      this.eventService.onPause$.next()

      const blocked = this.biometricAuthService.isScreenBlocked()
      if (blocked) return

      // 'lastActive' should not be updated when bio auth needs to be done
      // so that the screen remains blocked after resume
      this.storeService.dispatch('setLastActiveNow')

      void this.analyticsService.trackEvent(EVENT.APP_PAUSE)
    })

    void App.addListener('resume', async () => {
      const authenticated = await this.biometricAuthService.authenticateIfNeeded(false)
      if (!authenticated) return

      this.eventService.onResume$.next()

      void this.analyticsService.trackEvent(EVENT.APP_RESUME)
    })

    this.eventService.onResume$.subscribe(() => {
      // We want to emulate the resume event in e2e,
      // so we need to put onResume() under this subscription.
      void this.onResume()
    })

    this.eventService.inAppBrowserClosed$.subscribe(() => {
      // In iOS closing and opening in app browser does not trigger willEnterForegroundNotification event
      // so we need to listen to inAppBrowserClosed$ event to trigger onResume
      if (isIOSApp) void this.onResume()
    })

    void this.userDeviceService.init().then(ud => userDeviceDone.resolve(ud))

    // we check for connection availability before doing app init
    // to prevent authenticated offline users from getting an error popup
    // when cold starting the app.
    // in that case we just update the state to reflect being offline.
    // we have to do it specifically because the network listeners wont trigger
    // and our ui.online property boots as 'true'
    const online = await this.networkService.isOnline()

    if (!online) {
      this.setOffline()
    } else {
      logUtil.log('[bootstrap] App started and is online')

      // save modifiedDailyEntries and/or unsaved wrist temperatures
      // For non-AW users: this is rare edge case when user might end up with modified daily entries after the app was closed.
      // It happens if user kills the app while on Add data page with unsaved data, check DEV-13313 for details.
      if (this.isAccountCompleted()) {
        await this.saveDailyEntriesOnHold()
      }

      // postInit will be run later inside initCompletedUser()
      this.appInit({ runAlgo: true, start: true, runPostInit: false })

      // We need to init this service as soon as possible to we could start tracking previous lastActiveDate to know when was the previous session
      this.multipleEntriesService.init()

      void appInitDone.then(() => this.initCompletedUser())
    }

    if (!this.isAccountCompleted()) {
      // we check connectivity for incomplete offline users because
      // if there is no appInit we cant let them register or pay because pricing and
      // other sensible data wont be available
      if (!(await this.networkService.isOnline())) {
        logUtil.log('App started and unregistered user is offline, reloading window')
        await this.hideSplashScreen()

        const alert = await this.popupController.presentAlert(
          {
            header: tr('msg-offline-title'),
            message: tr('msg-offline-txt'),
            buttons: ['OK'],
          },
          'alert-offline',
          Priority.LOW,
          0,
        )
        await alert.onDidDismiss()

        void this.blockingLoaderService.show()
        window.location.reload()
        return await pHang()
      }

      // If Account is not completed yet - we need to wait for AppInit to finish before allowing user to see the page
      // This ensures we have all data that we need and fully synced with the server
      // If Account IS completed - we are ok to show Home page, render Ghost until AppInit is done
      logUtil.log(
        'Bootstrap is waiting for AppInit to complete (cause Account is not loaded or not completed yet)',
      )
      await appInitDone
    } else {
      logUtil.log(
        'Bootstrap is NOT waiting for AppInit to complete (cause Account.complete is true)',
      )
    }

    if (this.hasAccountId()) {
      // account has id but maybe is not yet completed
      this.analyticsService.identify()
    }

    // Init langService when we do (or do not) have the account
    await langServiceInitPromise

    const { adminLoginToPersonalId } = urlUtil.getLocationQueryString()
    if (adminLoginToPersonalId) {
      return await this.qaService.loginToAccount(adminLoginToPersonalId)
    }
    void appInitDone.then(async () => {
      prf('appInitDone')

      if (isIOSApp) {
        await this.appTrackingTransparencyService.init()
      }
    })

    if (isIOSApp) {
      void this.inAppPurchaseService.init()
    }

    if (location.hash) {
      // If there is something in the url
      void this.navService.processInternalLink(location.hash)
    }

    bootstrapDone.resolve()
    prf('bootstrapDone')

    this.checkAppVer()

    void this.biometricAuthService.authenticateIfNeeded(true)

    this.preloadService.init()

    runOnIdle(lag => {
      prf('firstIdle')
      window.prf.push([lag, 'firstIdleLag']) // hack to store only "lag", not the "time" to idle
      logUtil.log(`[prf] firstIdleLag ${lag}`)
      firstIdle.resolve()
    }, 5000)

    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => prf('firstIdleNative'))
    }

    this.appearanceService.init()

    void this.companionWatchService.init()

    const so = this.quizService.getSignatureObject()
    void this.quizService.init(so)

    // Wait for the first page to be entered before removing splash screen
    await firstPageOpened
    prf('firstPageOpened')

    await this.hideSplashScreen()
    prf('splashScreenRemoved')

    void this.analyticsService.trackEvent(EVENT.APP_COLD_START)
    void this.trackNotificationPermission()

    // await appinit to make sure services who subscribes to last active are initialized
    await appInitDone

    const { biometricAuthEnabled } = getState().userSettings
    if (!biometricAuthEnabled) this.storeService.dispatch('setLastActiveNow')
  }

  @tryCatch({
    onError: () => reloadPageOnErrorFn(),
  })
  public async initCompletedUser(): Promise<void> {
    // Only proceed if account is completed
    const { account, partnerAccount, userSettings } = getState()

    // init things required for partner
    if (partnerAccount) {
      this.ufService.init()
      this.graphService.init()

      void this.pushNotificationService.addListeners()
      await this.remindersService.init()

      return
    }

    void this.notificationService.init()

    this.widgetService.init()

    if (!account.completeDate) return

    this.ufService.init()

    this.bluetoothService.init()

    this.bluetoothConnectService.init()

    this.graphService.init()

    if (isIOSApp) {
      await this.healthKitService.init()

      this.appleWatchService.init()

      if (userSettings.hkImportEnabled) {
        // Fix for users with hkImportEnabled = true getting 'Authorization not determined' error
        void this.healthKitService
          .canRequestPermissionForIdentifier(FERTILITY_DATA, FERTILITY_DATA)
          .then(result => {
            if (!result) return
            void this.healthKitService.requestPermissions(FERTILITY_DATA, FERTILITY_DATA)
          })
        void this.healthKitService.importFertilityData()
      }
    }

    this.messageService.init()

    this.badgeService.init()

    this.measureStreakService.init()

    await this.remindersService.init()

    void this.lhService.createFolders()

    // UserDevice should be awaited (adjust should finish getting idfa from the native plugin)
    // AppInit should be awaited, cause AppInit should have priority and come BEFORE we ask for PostInit (UF should come first)
    await appInitDone

    void this.appService.postInit()
  }

  private async onResume(): Promise<void> {
    logUtil.log('BootstrapService.onResume()')

    if (this.isAccountCompleted()) {
      await this.checkUfAfterResume()

      this.network2Service.getHoursSinceLastSync()

      this.analyticsService.sendOfflineEvents()
    }

    void this.trackNotificationPermission()
    void this.notificationService.clearAllNotifications()

    // run partner init again on resume, swallow errors and send to sentry
    if (this.isPartner()) {
      this.appInit({ runAlgo: false, start: false, runPostInit: false })
    }

    /**
     * After coming back from payment in the web
     * (for users in 'email verification on web' flow),
     * we need to run appInit again
     * to get the updated account and redirect to the correct page
     */
    if (this.navService.getCurrentRoute() === ROUTES.VerifyEmailPage) {
      this.appInit({ runAlgo: false, start: false, runPostInit: false })
    }
  }

  private async checkUfAfterResume(): Promise<void> {
    if (!this.isAccountCompleted()) return

    const { account, ui, userFertility, userSettings } = getState()

    if (!ui.online) return

    const ouraMode = account.hwId === HardwareId.OURA
    const todayStr = localDate.todayString()

    const didSaveEntries = await this.saveDailyEntriesOnHold()

    if (didSaveEntries) {
      await this.appService.postInit()

      // run app init if we didnt save any data
    } else {
      const hasTempToday = userFertility.entryMap[todayStr]?.temperature
      // Run algo if today is different from UF todayDate or if in ouraMode and there's no temp
      const runAlgo = userFertility.todayDate !== todayStr || (ouraMode && !hasTempToday)

      this.appInit({ runAlgo, start: false, runPostInit: true })
    }

    if (userSettings.hkImportEnabled) void this.healthKitService.importFertilityData()
  }

  /**
   * Save modifiedDailyEntries and/or wrist temperatures
   */
  @decorate({
    loaderType: LoaderType.GHOST,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async saveDailyEntriesOnHold(): Promise<boolean> {
    const { account, addData, userSettings } = getState()

    if (isIOSApp) {
      const { value } = await Preferences.get({ key: 'StateUpdatedInBackground' })

      if (value === 'true') {
        await this.storeService.updateStateFromStorage()
        await Preferences.remove({ key: 'StateUpdatedInBackground' })
        void this.notificationService.showFertilityNotificationFeedback(
          account.reminders,
          userSettings,
        )
      }
    }

    let saved = false
    const hasMeasurementsOnHold = _isNotEmptyObject(addData.modifiedDailyEntries)

    if (hasMeasurementsOnHold) {
      const savedEntries = await this.addDataService.saveModifiedDailyEntries()
      saved = !!savedEntries.length
    }

    if (account.hwId === HardwareId.APPLE_WATCH && isIOSApp) {
      this.appleWatchService.init()
      const savedEntries = await this.appleWatchService.saveNewWristTemperatures()
      saved ||= !!savedEntries.length
    }

    return saved
  }

  private checkAppVer(): void {
    const { appVer: stateAppVer } = this.storeService.getState()

    if (stateAppVer === appVer) return

    this.storeService.dispatch('setAppVer', appVer)

    // we dont know which version the user was on
    if (stateAppVer === '') return

    this.analyticsService.trackEvent(EVENT.APP_UPDATE, {
      'from appVer': stateAppVer,
      'to appVer': appVer,
    })
  }

  private isAccountCompleted(): boolean {
    const { completeDate } = getState().account
    return !!completeDate
  }

  private hasAccountId(): boolean {
    const { id } = getState().account
    return !!id
  }

  private isPartner(): boolean {
    return !!getState().partnerAccount
  }

  private appInit(input: AppInitInput): void {
    this.appInitSubject.next(input)
  }

  private async runAppInit(input: AppInitInput): Promise<void> {
    const { runAlgo, start, runPostInit } = input

    if (runAlgo) dispatch('setGhostLoader', true)
    let appInit: Promise<BackendResponseFM>

    if (this.isPartner()) {
      appInit = this.partnerService.partnerInit()
    } else {
      appInit = this.appService.appInit(runAlgo, start)
    }

    const br = await appInit.catch(async err => {
      if (!start) {
        sentryService.captureException(err)
        return
      }

      const accountComplete = this.isAccountCompleted()

      // Got a transport error, set user offline and continue
      // "transport error" equals to statusCode being falsy
      if (accountComplete && err instanceof HttpRequestError && !err.data.responseStatusCode) {
        this.setOffline()
        void this.errorService.showErrorDialog(err)
      } else {
        // Error in AppInit is critical, will show Error Alert and then reload the page (if error repeats - it's an infinite loop)
        await this.hideSplashScreen()
        await this.errorService.showErrorDialog(err)
        void this.blockingLoaderService.show()
        window.location.reload()
      }
    })

    if (br?.sessionInvalid) {
      void this.hideSplashScreen()
      await this.sessionService.handleInvalidSession()
    }

    appInitDone.resolve()

    appInitGuard()

    if (runAlgo) dispatch('setGhostLoader', false)
    if (runPostInit) await this.appService.postInit()
  }

  private getHtmlLoader(): HTMLElement | null {
    return document.getElementById('loading0')
  }

  private async hideSplashScreen(): Promise<void> {
    if (this.platform.is('capacitor')) {
      void this.hideLoader() // hide quickly
      // timeout is needed for the DOM to be rendered and prevent flickering. Something to be tuned on real devices.
      // await pDelay(1000)
      await SplashScreen.hide()
    } else {
      // hide with opacity fade
      // if (!env.dev) await pDelay(1000)
      await this.hideLoader(true)
    }
  }

  private async hideLoader(fade = false): Promise<void> {
    const loader = this.getHtmlLoader()
    if (loader) {
      if (fade) {
        return await new promiseNoZone(async resolve => {
          loader.classList.add('opacity0')

          setTimeoutNoZone(() => {
            loader.remove()
            resolve()
          }, 1000)
        })
      }
      loader.remove()
    }
  }

  // Returns `true` if redirected
  private handleRedirects(): boolean {
    const qs = urlUtil.getLocationQueryString()

    if (this.navService.redirectToWebSignup(qs)) return true

    return false
  }

  private async trackNotificationPermission(): Promise<void> {
    const { settings } = getState().notifications
    const notificationPermission = await this.notificationService.hasPermission()
    const badgePermission = await this.badgeService.hasPermission()

    if (
      settings?.notificationPermission === notificationPermission &&
      settings?.badgePermission === badgePermission
    ) {
      return
    }

    this.storeService.dispatch('extendNotificationSettings', {
      notificationPermission,
      badgePermission,
    })
    void this.analyticsService.trackEvent(EVENT.NOTIFICATION_PERMISSION, {
      notificationPermission,
      badgePermission,
    })
  }

  private setOffline(): void {
    logUtil.log('[bootstrap] App started and is offline, skipped appInit')

    this.networkService.goOffline()
    void this.initCompletedUser()
  }
}
