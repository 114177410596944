import { inject, Injectable } from '@angular/core'
import { _assert, _deepEquals, _numberEnumValues } from '@naturalcycles/js-lib'
import {
  ConsideringPurchaseOption,
  EligibleAppleWatchBreakpointOption,
  Experiment,
  ProductKey,
  PurchaseNewWatchOption,
  QuizDataInput,
  QuizDataKey,
  shortQuizGoalOptions,
  SleepWithTrackerOption,
  WearWatchToBedOption,
  WhichWearableBreakpointOption,
} from '@naturalcycles/shared'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import {
  allQuizSections,
  breakpointActions,
  commonQuizFlow,
  cycleChangesStoreToOptions,
  guardQuizData,
} from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { QuizModifier } from '@src/app/util/quiz.util'
import { QuizSections } from '@src/typings/quiz.enum'
import {
  AppQuizEngine,
  AppQuizEngineConfig,
  AppQuizFlow,
  QuizSelectOption,
  QuizSignatureObject,
  QuizState,
} from '@src/typings/quiz.types'
import { ProductService } from '../product.service'
import { dispatch, getState } from '../store.service'

@Injectable({ providedIn: 'root' })
export class QuizService {
  private engine?: AppQuizEngine
  private modifiers: QuizModifier<AppQuizEngineConfig>[] = []

  private productService = inject(ProductService)

  private baseQuiz = {
    getEngineConfig(): AppQuizEngineConfig {
      return {
        breakpointActions,
        guardData: guardQuizData,
      }
    },
    getSections(): QuizSections {
      return allQuizSections
    },
    getInitialFlow(): AppQuizFlow {
      return commonQuizFlow
    },
    getEntry(): string | null {
      return ROUTES.IntroPage
    },
    getExit(): string | null {
      return ROUTES.AuthPage
    },
  }

  public init(so: QuizSignatureObject): void {
    console.log('[QuizService] Init started')
    if (this.isQuizSignatureValid(so)) {
      console.log('[QuizService] Valid quiz signature')
      const cfg = this.getEngineConfig(so)
      this.engine = new AppQuizEngine(cfg)
      return
    }

    console.log('[QuizService] Invalid quiz signature. Rebuilding engine with new signature')
    this.bootstrapEngine(so)

    console.log('[QuizService] Init done')
  }

  private bootstrapEngine(so: QuizSignatureObject): void {
    const cfg = this.getEngineConfig(so)
    console.log('[QuizService] Bootstrapping quiz engine with new signature', cfg)
    this.engine = new AppQuizEngine(cfg)
    dispatch('clearQuizState')
    dispatch('replaceQuizState', {
      data: null,
      flow: this.getInitialFlow(so),
      sections: this.getQuizSections(so),
      exit: this.getQuizExit(so),
      entry: this.getQuizEntry(so),
      signature: so,
    })
  }

  public validateQuizSignature(so: QuizSignatureObject): void {
    console.log('[QuizService] Validate quiz signature')
    if (this.isQuizSignatureValid(so)) {
      console.log('[QuizService] Valid quiz signature')
      return
    }

    console.log('[QuizService] Invalid quiz signature. Rebuilding engine with new signature')
    this.bootstrapEngine(so)
  }

  public getEngine(): AppQuizEngine {
    _assert(this.engine, '[QuizService] Must initialize quiz engine before accessing it')
    return this.engine
  }

  private getEngineConfig(obj: QuizSignatureObject): AppQuizEngineConfig {
    let config = this.baseQuiz.getEngineConfig()
    this.modifiers.forEach(modifier => {
      config = modifier.enhanceEngineConfig(obj, config)
    })
    return config
  }

  private getInitialFlow(obj: QuizSignatureObject): AppQuizFlow {
    let initialFlow = this.baseQuiz.getInitialFlow()
    this.modifiers.forEach(modifier => {
      initialFlow = modifier.enhanceInitialFlow(obj, initialFlow)
    })
    return initialFlow
  }

  private getQuizSections(obj: QuizSignatureObject): QuizSections {
    let sections = this.baseQuiz.getSections()
    this.modifiers.forEach(modifier => {
      sections = modifier.enhanceSections(obj, sections)
    })
    return sections
  }

  private getQuizEntry(obj: QuizSignatureObject): string | null {
    let entry = this.baseQuiz.getEntry()
    this.modifiers.forEach(modifier => {
      entry = modifier.enhanceEntry(obj, entry)
    })
    return entry
  }

  private getQuizExit(obj: QuizSignatureObject): string | null {
    let exit = this.baseQuiz.getExit()
    this.modifiers.forEach(modifier => {
      exit = modifier.enhanceExit(obj, exit)
    })
    return exit
  }

  private isQuizSignatureValid(so: QuizSignatureObject): boolean {
    const storedSignature = getState().quiz.signature
    return _deepEquals(so, storedSignature)
  }

  public getOptionsForQuestion(storeKey: QuizDataKey): QuizSelectOption[] {
    if (!cycleChangesStoreToOptions[storeKey]) {
      return []
    }

    const { name, en } = cycleChangesStoreToOptions[storeKey]

    return _numberEnumValues(en).map((value: number | string) => {
      return {
        key: value as number,
        textKey: `txt-quiz-${name}--${en[value]}`,
      }
    })
  }

  public getSignatureObject(): QuizSignatureObject {
    const { assignments } = getState().experiment
    return {
      experiment: {
        [Experiment.LEAD_CAPTURE_APP]: assignments[Experiment.LEAD_CAPTURE_APP]?.bucket,
      },
    }
  }

  public hasCompletedQuiz(data: QuizDataInput | null): boolean {
    return (
      !!data?.whichWearable ||
      !!(data?.fertilityGoal && shortQuizGoalOptions.includes(data.fertilityGoal))
    )
  }

  private hasPageInFlow(page: QuizPage, flow: AppQuizFlow): boolean {
    return this.getEngine().getSectionForPage(page, flow) !== undefined
  }

  /**
   * Checks if the user has answered the last question of the quiz and has the device
   * result page in the flow which means that the user has received a device recommendation.
   */
  public hasDeviceRecommendation({ data, flow }: QuizState): boolean {
    if (!data) {
      return false
    }

    if (!data.statementDescription) {
      return false
    }

    return !!this.hasPageInFlow(QuizPage.deviceResult, flow)
  }

  public getRecommendedDevice(data: QuizDataInput | null, flow: AppQuizFlow): ProductKey {
    if (!data) {
      return this.productService.getDefaultThermometer()
    }
    const {
      eligibleAppleWatch,
      purchaseNewWatch,
      consideringPurchase,
      whichWearable,
      sleepWithTracker,
      wearWatchToBed,
    } = data

    const canWearWatchToBed =
      [WearWatchToBedOption.dontKnow, WearWatchToBedOption.yes].includes(wearWatchToBed!) ||
      [SleepWithTrackerOption.yesWithBoth, SleepWithTrackerOption.yesWithWatch].includes(
        sleepWithTracker!,
      )

    const hasEligebleAppleWatch =
      eligibleAppleWatch === EligibleAppleWatchBreakpointOption.yes &&
      this.hasPageInFlow(QuizPage.eligibleAppleWatch, flow || [])

    const considersPurchasingAppleWatch =
      (consideringPurchase === ConsideringPurchaseOption.yesAppleWatch &&
        this.hasPageInFlow(QuizPage.consideringPurchase, flow || [])) ||
      (purchaseNewWatch === PurchaseNewWatchOption.yes &&
        this.hasPageInFlow(QuizPage.purchaseNewWatch, flow || []))

    if (canWearWatchToBed && (hasEligebleAppleWatch || considersPurchasingAppleWatch)) {
      return ProductKey.APPLE_WATCH
    }

    if (
      whichWearable?.includes(WhichWearableBreakpointOption.ouraRing) ||
      consideringPurchase === ConsideringPurchaseOption.yesOuraRing
    ) {
      return ProductKey.OURA_RING_DISCOUNT
    }

    return this.productService.getDefaultThermometer()
  }

  public ownsRecommendedDevice(quiz: QuizState): boolean {
    if (!this.hasDeviceRecommendation(quiz)) {
      return false
    }

    const recommendedDevice = this.getRecommendedDevice(quiz.data, quiz.flow)
    const ownsOura =
      recommendedDevice === ProductKey.OURA_RING_DISCOUNT &&
      !!quiz.data?.whichWearable?.includes(WhichWearableBreakpointOption.ouraRing)

    const ownsAw =
      recommendedDevice === ProductKey.APPLE_WATCH &&
      !!quiz.data?.whichWearable?.includes(WhichWearableBreakpointOption.appleWatch)

    return ownsOura || ownsAw
  }
}

/*
Q: Why do some pages have URLs unique for both "prevent" and "plan" flows, while others have separate
/xxx-prevent" and "/xxx-plan" URLs even if they "look" the same?
A: Different URLs means that when the user lands on the page (Eg. by direct link) we know
   immediately his position in the flow, without the necessity to look into the state data to
   discern if user is in "prevent" or "plan".


Q: Why do we have "info-pages" which are shared between "prevent" and "plan" flows?
A: It seemed overkill to have many duplicates of the same page in the case of "info-pages". Since their
role is strictly presentational (no BreakpointAction needs to be processed), we allow them to be
shared between "prevent" and "plan" flows when needed.


Q: Can I expand the functionality?
A: Sure! As long as the service stays:
- free from side effects
- free from interaction with Angular features (store, route, etc)

This helps isolating the service so that it is more easily unit testable.
 */
