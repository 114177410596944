import { inject, Injectable } from '@angular/core'
import { usesBluetoothDevice } from '@app/cnst/hardware.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { isAndroidApp, isIOSApp, isWebApp } from '@app/cnst/userDevice.cnst'
import {
  YEAR_IN_REVIEW_END_DATE,
  YEAR_IN_REVIEW_START_DATE_MASTER,
  YEAR_IN_REVIEW_START_DATE_PROD,
} from '@app/pages/year-in-review/year-in-review.page'
import { PendingHardwareDeviceSettings } from '@app/reducers/hardwareDevice.reducer'
import { NotificationService } from '@app/srv/notification.service'
import { getState, select2 } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { Capacitor } from '@capacitor/core'
import { _findKeyByValue, IsoDate, localDate, StringMap } from '@naturalcycles/js-lib'
import { BatteryStatus, FWVersion, Goal, HardwareId, OuraDevice } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { Badge } from '@src/typings/capacitor'
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs/operators'
import { AnnouncementService } from './announcement.service'
import { GuideService } from './guide.service'
import { HardwareDeviceService } from './hardwareDevice.service'
import { ChannelId, LocalNotificationService } from './notification.local.service'

export interface ShowHardwareDeviceBadgeInput {
  hwId: HardwareId
  deviceId?: string
  isTestMeasurementDone?: boolean
  pendingBluetoothSettings: PendingHardwareDeviceSettings
  batteryStatus?: BatteryStatus
  latestFWVersion: FWVersion | null
  currentFWVersion?: string
  t2SentDate?: IsoDate
  t3SentDate?: IsoDate
  hwChanges: StringMap<HardwareId>
  ouraDevices?: OuraDevice[]
  ouraRenewPermissionsBannerShown?: boolean
  regHWId?: HardwareId
}

@Injectable({ providedIn: 'root' })
export class BadgeService {
  private localNotificationService = inject(LocalNotificationService)
  private notificationService = inject(NotificationService)
  private guideService = inject(GuideService)
  private announcementService = inject(AnnouncementService)
  private hardwareDeviceService = inject(HardwareDeviceService)

  private educationalContent$ = select2(s => s.notifications.settings?.educationalContent)
  private demoMode$ = select2(s => s.account.demoMode)
  private hwId$ = select2(s => s.account.hwId)
  private goal$ = select2(s => s.account.goal)
  private t2SentDate$ = select2(s => s.account.t2SentDate)
  private t3SentDate$ = select2(s => s.account.t3SentDate)
  private regHWId$ = select2(s => s.account.onboardingData?.regHWId)
  private ouraDevices$ = select2(s => s.account.ouraDevices)
  private pregnantNow$ = select2(s => s.account.pregnantNow)
  private blog$ = select2(s => s.blog)
  private hwChanges$ = select2(s => s.hwChanges)
  private hardwareDeviceId$ = select2(s => s.hwDevice?.mac)
  private testMeasurementDone$ = select2(s => s.hwDevice?.testMeasurementDone)
  private latestFWVersion$ = select2(s => s.latestHWDeviceFWVersion)
  private unreadMessages$ = select2(s => s.messages.unreadMessages)
  private quizzes$ = select2(s => s.quizzes.items)
  private lastSeenBlogPost$ = select2(s => s.userSettings.lastSeenBlogPost)
  private pendingBluetoothSettings$ = select2(s => s.userSettings.pendingBluetoothSettings)
  private yearInReviewOpenDate$ = select2(s => s.userSettings.yearInReviewOpenDate)
  private ouraRenewPermissionsBannerShown$ = select2(
    s => s.userSettings.ouraRenewPermissionsBannerShown,
  )

  private hardwareDevice$ = this.hardwareDeviceService.hardwareDevice$
  public fwVersion$ = this.hardwareDevice$.pipe(map(({ fwVersion }) => fwVersion))

  public showYearInReview$ = this.goal$.pipe(
    combineLatestWith(this.demoMode$, this.pregnantNow$),
    map(([goal, demo, pregnantNow]) => {
      const isPregnantOnPrevent = goal === Goal.PREVENT && pregnantNow
      const isOnRecovery = goal === Goal.RECOVERY

      if (isPregnantOnPrevent || isOnRecovery || demo) return false

      return localDate
        .today()
        .isBetween(YEAR_IN_REVIEW_START_DATE_PROD, YEAR_IN_REVIEW_END_DATE, '[]')
    }),
  )

  public badgeMessages$ = this.unreadMessages$.pipe(
    combineLatestWith(
      this.announcementService.announcement$,
      this.showYearInReview$,
      this.yearInReviewOpenDate$,
    ),
    map(([unreadMessages, announcement, showYearInReview, yearInReviewOpenDate]) => {
      const hasYearInReviewAlreadyBeenOpened = yearInReviewOpenDate
        ? env.prod
          ? localDate(yearInReviewOpenDate).isBetween(
              YEAR_IN_REVIEW_START_DATE_PROD,
              YEAR_IN_REVIEW_END_DATE,
              '[]',
            )
          : localDate(yearInReviewOpenDate).isBetween(
              YEAR_IN_REVIEW_START_DATE_MASTER,
              YEAR_IN_REVIEW_END_DATE,
              '[]',
            )
        : false

      const showBadgeForYearInReview = showYearInReview && !hasYearInReviewAlreadyBeenOpened

      return announcement ? 1 : 0 + unreadMessages + (showBadgeForYearInReview ? 1 : 0)
    }),
  )

  public badgeLearn$ = this.guideService.guideThumbnails$.pipe(
    combineLatestWith(
      this.quizzes$,
      this.blog$,
      this.lastSeenBlogPost$,
      this.educationalContent$,
      this.demoMode$,
    ),
    map(([guides, quizzes, blog, lastSeen, educationalContent, demoMode]) => {
      if (!educationalContent || demoMode) return 0

      const newGuides = guides.filter(a => a.badge)
      const newQuizzes = quizzes.filter(a => a.badge)

      const blogItem = blog[0]
      const unseenBlog = !!blogItem && (!lastSeen || lastSeen < blogItem.date)

      return newGuides.length + newQuizzes.length + (unseenBlog ? 1 : 0)
    }),
  )

  public hardwareDeviceBadge$ = this.hwId$.pipe(
    combineLatestWith(
      this.hardwareDeviceId$,
      this.testMeasurementDone$,
      this.pendingBluetoothSettings$,
      this.hardwareDeviceService.hardwareDevice$.pipe(map(hwDevice => hwDevice.batteryStatus)),
      this.latestFWVersion$,
      this.fwVersion$,
      this.t2SentDate$,
      this.t3SentDate$,
      this.hwChanges$,
      this.ouraDevices$,
      this.ouraRenewPermissionsBannerShown$,
      this.regHWId$,
    ),
    map(
      ([
        hwId,
        deviceId,
        isTestMeasurementDone,
        pendingBluetoothSettings,
        batteryStatus,
        latestFWVersion,
        currentFWVersion,
        t2SentDate,
        t3SentDate,
        hwChanges,
        ouraDevices,
        ouraRenewPermissionsBannerShown,
        regHWId,
      ]) => {
        return this._showHardwareDeviceBadge({
          hwId,
          deviceId,
          isTestMeasurementDone,
          pendingBluetoothSettings,
          batteryStatus,
          latestFWVersion,
          currentFWVersion,
          t2SentDate,
          t3SentDate,
          hwChanges,
          ouraDevices,
          ouraRenewPermissionsBannerShown,
          regHWId,
        })
      },
    ),
  )

  public init(): void {
    if (isWebApp) return

    this.badgeMessages$
      .pipe(combineLatestWith(this.badgeLearn$), distinctUntilChanged())
      .subscribe(([messages, learn]) => {
        let title = ''
        let text = ''

        if (messages) {
          const messages = getState().messages
          if (!messages.unreadMessages) return

          title = tr('txt-new-message')
          text = messages.messages[0]!.msg.replace('# ', '').split(/\r?\n/g)[0]!
        } else if (learn) {
          title = tr('txt-android-notification-title')
          text = tr('txt-android-notification-body')
        }

        const value = messages + (learn ? 1 : 0) // group all badges in learn to one

        if (value) {
          void this.updateBadge(value, title, text, !!messages)
        } else {
          this.clearBadge()
        }
      })
  }

  /**
   * 0 = denied
   * 1 = granted
   * -1 = not available
   */
  public async hasPermission(): Promise<boolean> {
    if (isAndroidApp) {
      const channels = await this.localNotificationService.listAllChannels()

      const enabled = channels.some(({ badgeEnabled }) => badgeEnabled)
      const hasNotificationsPermission = await this.notificationService.hasPermission()

      return enabled && hasNotificationsPermission
    }
    if (isIOSApp) {
      const { state } = await Badge.checkPermission()

      return state === 1
    }

    return false
  }

  public clearBadge(): void {
    if (isAndroidApp) {
      this.localNotificationService.androidBadge$.next(undefined)
    }

    if (isIOSApp) {
      Badge.clear()
    }
  }

  private async updateBadge(
    value: number,
    title: string,
    body: string,
    unreadMessages = false,
  ): Promise<void> {
    if (isAndroidApp) {
      this.localNotificationService.androidBadge$.next({
        title,
        body,
        channelId: unreadMessages ? ChannelId.NCMessages : ChannelId.NCEducational,
        extra: {
          link: unreadMessages ? ROUTES.MessagesPage : ROUTES.LearnPage,
        },
      })
    }

    if (isIOSApp) {
      Badge.set({ value })
    }
  }

  public _showHardwareDeviceBadge({
    hwId,
    deviceId,
    isTestMeasurementDone,
    pendingBluetoothSettings,
    batteryStatus,
    latestFWVersion,
    currentFWVersion,
    t2SentDate,
    t3SentDate,
    hwChanges,
    ouraDevices,
    ouraRenewPermissionsBannerShown,
    regHWId,
  }: ShowHardwareDeviceBadgeInput): boolean {
    if (hwId === HardwareId.OURA && !ouraDevices?.length && ouraRenewPermissionsBannerShown) {
      return true
    }

    if (Capacitor.getPlatform() === 'web') return false

    // We check if NC has shipped the thermometer to the user but they haven't paired it yet
    const t2SentLately = t2SentDate && localDate.today().diff(t2SentDate, 'day') < 15
    const t3SentLately = t3SentDate && localDate.today().diff(t3SentDate, 'day') < 15

    const t2ChangeDate = _findKeyByValue(hwChanges, HardwareId.UEBE_THERMOMETER)
    const t3ChangeDate = _findKeyByValue(hwChanges, HardwareId.T3_THERMOMETER)

    const t2NoChangeLately =
      (!t2ChangeDate && regHWId !== HardwareId.UEBE_THERMOMETER) ||
      (!!t2ChangeDate && !!t2SentDate && t2ChangeDate < t2SentDate)
    const t3NoChangeLately =
      (!t3ChangeDate && regHWId !== HardwareId.T3_THERMOMETER) ||
      (!!t3ChangeDate && !!t3SentDate && t3ChangeDate < t3SentDate)

    if (
      (t2SentLately && hwId !== HardwareId.UEBE_THERMOMETER && t2NoChangeLately) ||
      (t3SentLately && hwId !== HardwareId.T3_THERMOMETER && t3NoChangeLately)
    ) {
      return true
    }

    if (!usesBluetoothDevice(hwId)) return false

    if (
      batteryStatus &&
      [BatteryStatus.LOW, BatteryStatus.CRITICALLY_LOW].includes(batteryStatus)
    ) {
      return true
    }
    if (pendingBluetoothSettings && Object.keys(pendingBluetoothSettings).length) return true
    if (
      (hwId === HardwareId.T3_THERMOMETER || hwId === HardwareId.B1) &&
      !!deviceId &&
      !!currentFWVersion &&
      !!latestFWVersion &&
      latestFWVersion.version !== currentFWVersion
    ) {
      return true
    }

    return !deviceId || !isTestMeasurementDone
  }
}
