<div class="multiEntries__content">
  @if (title) {
    <h2 class="ion-padding-horizontal">{{ title | tr }}</h2>
  }

  @if (body) {
    <p class="ion-padding-horizontal" [innerHTML]="body | tmd"></p>
  }

  @for (item of entryItems; track item.date) {
    <ion-radio-group
      [attr.uid]="'multiEntries__dateRow--' + item.date"
      [allowEmptySelection]="!item.hasSavedTemps"
      [value]="0"
      [name]="item.date"
      [class.multi]="item.entries.length !== 1"
    >
      @if (wearableMode) {
        @for (entry of item.entries; track $index; let i = $index) {
          <div class="entryItem">
            <div class="entryItem__row">
              <ng-container *ngTemplateOutlet="entryTemperature; context: { entry }" />
              <ng-container *ngTemplateOutlet="entryDate; context: { entry }" />

              @let icon = entry.icon;
              <div class="entryItem__icon" [class.entryItem__icon--statusIcon]="icon">
                <ion-icon [color]="icon?.color || 'text'" [src]="icon?.icon || ICON.CHECK" />
              </div>

              @if (entry.today) {
                <div uid="multiEntries__todayBadge">{{ 'txt-today' | tr }}</div>
              }
            </div>
          </div>
        }
      } @else {
        @for (entry of item.entries; track $index; let i = $index) {
          <ion-radio
            class="entryItem entryItem--selectable"
            slot="start"
            mode="ios"
            labelPlacement="end"
            justify="start"
            [value]="i"
          >
            <ng-container *ngTemplateOutlet="entryTemperature; context: { entry }" />

            @if (entry.temperatureMeasuredTimestamp; as timestamp) {
              <div class="entryItem__date">
                {{ timestamp | nc_date: 'MEDIUM_WITH_TIME' }}
              </div>
            } @else {
              <ng-container *ngTemplateOutlet="entryDate; context: { entry }" />

              @if (entry.temperatureUpdatedTimestamp) {
                <div class="entryItem__tag">
                  <ion-label>{{ 'txt-manual' | tr }}</ion-label>
                </div>
              }
            }
          </ion-radio>
        }
      }
    </ion-radio-group>
  }
</div>

<ion-footer class="footer--shadow">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end">
      <ion-button uid="multiEntries__close" color="primary" (click)="dismiss()" tabindex="0">
        <ion-label>{{ dismissTitle | tr }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<ng-template #entryTemperature let-entry="entry">
  @if (entry) {
    <div class="entryItem__temperature" [class.entryItem__temperature--deviating]="entry.deviating">
      {{ entry.temperature | temperature }}
    </div>
  }
</ng-template>

<ng-template #entryDate let-entry="entry">
  @if (entry) {
    <div class="entryItem__date">
      {{ entry.date | nc_date: 'MEDIUM' }}
    </div>
  }
</ng-template>
