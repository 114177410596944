import { _objectEntries, StringMap } from '@naturalcycles/js-lib'
import { BackendResponseFM, HardwareId } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = StringMap<HardwareId>

const initialState: State = {}

const actions: any = {
  // For test purposes only
  _extendHwChanges(state: State, action: { type: string; payload: StringMap<HardwareId> }): State {
    return {
      ...state,
      ...action.payload,
    }
  },

  onBackendResponse(state: State, action: { type: string; payload: BackendResponseFM }): State {
    // replace!
    if (action.payload.hwChanges) {
      return {
        ...Object.fromEntries(_objectEntries(action.payload.hwChanges).sort()),
      }
    }

    return state
  },
}

// biome-ignore lint/style/useDefaultParameterLast: ok
export const hwChangesReducer: Reducer<State> = (state = initialState, action: Action) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
