import {
  BreakpointAction,
  ChoiceFunction,
  ConsideringPurchaseOption,
  EligibleAppleWatchBreakpointOption,
  PurchaseNewWatchOption,
  QuizDataInput,
  SleepWithTrackerOption,
  WhichWearableBreakpointOption,
} from '@naturalcycles/shared'
import { addPageToFlowAfterCurrent, removePageFromFlow } from '@src/app/util/quiz.util'
import { DefaultQuizSection } from '@src/typings/quiz.enum'
import { QuizPage } from '../quiz-pages.cnst'

const firstPathTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = (flow, data) => {
  const hasOnlyAppleWatchSelected =
    data?.whichWearable?.length === 1 &&
    data.whichWearable[0] === WhichWearableBreakpointOption.appleWatch

  const isAppleWatchEligible =
    data?.eligibleAppleWatch === EligibleAppleWatchBreakpointOption.yes && hasOnlyAppleWatchSelected

  const isWillingToPurchaseAW =
    data?.purchaseNewWatch === PurchaseNewWatchOption.yes ||
    data?.consideringPurchase === ConsideringPurchaseOption.yesAppleWatch

  if (isAppleWatchEligible || isWillingToPurchaseAW) {
    return addPageToFlowAfterCurrent(
      flow,
      DefaultQuizSection.routine,
      QuizPage.sleepWearable,
      QuizPage.wearYourWatchToBed,
    )
  }
  return removePageFromFlow(flow, DefaultQuizSection.routine, QuizPage.wearYourWatchToBed)
}

const secondPathTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return removePageFromFlow(flow, DefaultQuizSection.routine, QuizPage.wearYourWatchToBed)
}

export const sleepWearable: BreakpointAction<SleepWithTrackerOption, QuizPage, QuizDataInput> = {
  [SleepWithTrackerOption.yesWithRing]: firstPathTransformFunction,
  [SleepWithTrackerOption.yesWithWatch]: secondPathTransformFunction,
  [SleepWithTrackerOption.yesWithBoth]: secondPathTransformFunction,
  [SleepWithTrackerOption.neither]: firstPathTransformFunction,
}
