import { Injectable } from '@angular/core'
import { COLOR } from '@app/cnst/color.cnst'
import { EntryMap } from '@app/reducers/addData.reducer'
import { AppSettingsFM, TrackerSettings } from '@app/srv/appSettings.cnst'
import { _stringMapValues, StringMap } from '@naturalcycles/js-lib'
import {
  AppTracker,
  CervicalMucusConsistency,
  DataFlag,
  DataIntensity,
  DataQuantity,
  Libido,
  Mens,
  Sleep,
} from '@naturalcycles/shared'
import { AppearanceSettings } from '@src/app/srv/appearance.service'
import {
  GRAPH_ICON,
  GRAPH_ICON_BY_CM_CONSISTENCY,
  GRAPH_ICON_BY_CM_QUANTITY,
  GRAPH_ICON_BY_DATAFLAG,
  GRAPH_ICON_BY_LIBIDO,
  GRAPH_ICON_BY_MENS,
  GRAPH_ICON_BY_MENSFLOW,
  GRAPH_ICON_BY_MENSFLOW_PREDICTION,
  GRAPH_ICON_BY_MISCARRIAGEFLOW,
  GRAPH_ICON_BY_POSTPARTUMFLOW,
  GRAPH_ICON_BY_SLEEP,
  GRAPH_ICON_BY_WITHDRAWALFLOW,
  MOOD_ORDER,
  PAIN_ORDER,
  SKIN_ORDER,
} from './graph.cnst'
import {
  GraphEntry,
  GraphEntryTracker,
  GraphIcon,
  HolisticGraphEntry,
  MensStatus,
} from './graph.model'

const TRACKERS_ORDER: AppTracker[] = [
  AppTracker.MENS_QUANTITY,
  AppTracker.CM_QUANTITY,
  AppTracker.LIBIDO,
  AppTracker.SKIN,
  AppTracker.SLEEP,
  AppTracker.PAIN,
  AppTracker.MOOD,
]

/**
 * @description Everything for creating data for the holistic graph
 */
@Injectable({ providedIn: 'root' })
export class GraphDataHolisticService {
  public getTrackersForEntry(
    entry: GraphEntry,
    settings: AppSettingsFM,
    appearance: AppearanceSettings = AppearanceSettings.LIGHT,
  ): Partial<HolisticGraphEntry> {
    const graphEntry: Partial<HolisticGraphEntry> = {}
    const { trackers } = settings

    const {
      mensQuantity,
      notes,
      pains,
      skinFlags,
      moods,
      libido,
      sleep,
      cervicalMucusConsistency,
      cervicalMucusQuantity,
      prediction,
      today,
      dataFlagIntensity,
    } = entry

    if (prediction) return this._getTrackersForPrediction(entry, trackers)

    // mood
    if (trackers[AppTracker.MOOD]) {
      graphEntry.mood = moods?.length || notes ? this.getMood(moods || [], !!notes) : undefined

      if (today && !graphEntry.mood) {
        graphEntry.mood = entry.dataFlags
          ? this.getPMSPrediction(entry.dataFlags, appearance)
          : undefined
      }
    }

    // pain
    if (trackers[AppTracker.PAIN]) {
      graphEntry.pain = pains?.length
        ? this.getPain(pains, dataFlagIntensity ?? undefined)
        : undefined
    }

    // skin
    if (trackers[AppTracker.SKIN]) {
      graphEntry.skin = skinFlags?.length
        ? this.getSkin(skinFlags, dataFlagIntensity ?? undefined)
        : undefined
    }

    // libido
    if (libido && trackers[AppTracker.LIBIDO]) {
      graphEntry.libido = this.getLibido(libido)
    }

    // sleep
    if (sleep && trackers[AppTracker.SLEEP]) {
      graphEntry.sleep = this.getSleep(sleep)
    }

    // cervical mucus
    if ((cervicalMucusConsistency || cervicalMucusQuantity) && trackers[AppTracker.CM_QUANTITY]) {
      graphEntry.cervicalMucus = this.getCervicalMucus(
        cervicalMucusQuantity,
        cervicalMucusConsistency,
      )
    }

    // mens flow
    if (entry.mens) {
      graphEntry.mensQuantity = this.getMensTracker(
        entry.mens,
        mensQuantity,
        trackers[AppTracker.MENS_QUANTITY],
      )
    }

    return graphEntry
  }

  public _getTrackersForPrediction(
    entry: GraphEntry,
    trackers: TrackerSettings,
  ): Partial<HolisticGraphEntry> {
    const graphEntry: Partial<HolisticGraphEntry> = {}

    const {
      code: { mens },
      mensQuantity,
      dataFlags,
    } = entry

    // mens flow
    if (mens) {
      graphEntry.mensQuantity = this.getMensTracker(
        MensStatus.PREDICTION,
        mensQuantity,
        !!trackers[AppTracker.MENS_QUANTITY],
      )
    }

    if (!!trackers[AppTracker.MOOD] && dataFlags) {
      graphEntry.mood = this.getPMSPrediction(dataFlags)
    }

    return graphEntry
  }

  public getEnabledTrackers(settings: AppSettingsFM, partner: boolean): AppTracker[] {
    return TRACKERS_ORDER.filter(tracker => {
      if (tracker === AppTracker.MENS_QUANTITY) return true // mens row should draw period if mens flow is disabled

      const trackerSettings: Partial<Record<AppTracker, boolean>> = {
        ...(partner ? settings.partnerTrackers : settings.trackers),
      }

      return !!trackerSettings[tracker]
    })
  }

  public getTrackersAddedCount(
    entryMap: EntryMap = {},
    modifiedEntryMap: EntryMap = {},
    includeMens = true,
  ): number {
    let count = 0
    _stringMapValues({ ...entryMap, ...modifiedEntryMap }).forEach(e => {
      const dataFlags = e.dataFlags.filter(
        dataFlag => !DataFlag[dataFlag]?.startsWith('DEVIATION_'),
      )

      if (includeMens && (e.mens === Mens.SPOTTING || e.mensQuantity)) count++
      if (e.cervicalMucusConsistency) count++
      if (e.cervicalMucusQuantity) count++
      if (e.libido) count++
      if (e.notes) count++
      count += dataFlags.length
    })

    return count
  }

  private getMensTracker(
    mens: MensStatus,
    mensQuantity?: DataQuantity,
    mensFlowEnabled?: boolean,
  ): GraphEntryTracker {
    if (!mensFlowEnabled) {
      mensQuantity = undefined
    }

    const color = mens === MensStatus.PREDICTION ? COLOR.MEDIUM : COLOR.BLEEDING

    let backgroundOpacity = '80'

    if (mens === MensStatus.PREDICTION && mensQuantity) backgroundOpacity = '00'
    else if (mens === MensStatus.SPOTTING || mensQuantity) backgroundOpacity = '40'

    let quantity = 0

    if (mens === MensStatus.SPOTTING) quantity = 0.25
    else if (mensQuantity === DataQuantity.LIGHT) quantity = 0.5
    else if (mensQuantity === DataQuantity.MEDIUM) quantity = 0.75
    else if (mensQuantity === DataQuantity.HEAVY) quantity = 1
    else if (mens === MensStatus.WITHDRAWAL) quantity = 0.75
    else if (mens === MensStatus.MISCARRIAGE) quantity = 0.75

    let icon: GraphIcon

    // if no quantity, use medium for the icons
    mensQuantity ||= DataQuantity.MEDIUM

    if (mens === MensStatus.PREDICTION) {
      icon = GRAPH_ICON_BY_MENSFLOW_PREDICTION[mensQuantity]!
    } else if (mens === MensStatus.CONFIRMED) {
      icon = GRAPH_ICON_BY_MENSFLOW[mensQuantity]!
    } else if (mens === MensStatus.MISCARRIAGE) {
      icon = GRAPH_ICON_BY_MISCARRIAGEFLOW[mensQuantity]!
    } else if (mens === MensStatus.WITHDRAWAL) {
      icon = GRAPH_ICON_BY_WITHDRAWALFLOW[mensQuantity]!
    } else if (mens === MensStatus.POSTPARTUM) {
      icon = GRAPH_ICON_BY_POSTPARTUMFLOW[mensQuantity]!
    } else {
      icon = GRAPH_ICON_BY_MENS[mens]
    }

    return {
      color,
      quantity,
      backgroundOpacity,
      icons: [icon!],
    }
  }

  private getMood(_moods: DataFlag[], hasNotes?: boolean): GraphEntryTracker {
    const color = COLOR.MOOD
    const backgroundOpacity = '40'
    let quantity = 0

    const moods = this._sortDataFlags(_moods, MOOD_ORDER)

    const moodsCount = moods.length + (hasNotes ? 1 : 0)
    if (moodsCount > 3) quantity = 1
    else if (moodsCount > 2) quantity = 0.75
    else if (moodsCount > 1) quantity = 0.5
    else if (moodsCount > 0) quantity = 0.25

    const icons = this.getIconsFromDataFlag(moods, hasNotes ? 3 : 4)

    if (hasNotes) {
      const hasMore = icons.includes(GRAPH_ICON['MORE']!)

      if (hasMore) {
        icons.splice(icons.length - 1, 0, GRAPH_ICON['NOTES']!)
      } else {
        icons.push(GRAPH_ICON['NOTES']!)
      }
    }

    return {
      color,
      quantity,
      backgroundOpacity,
      icons,
    }
  }

  private getLibido(libido: Libido): GraphEntryTracker {
    const color = COLOR.SEX
    const backgroundOpacity = '40'
    let quantity = 0

    if (libido === Libido.LOW) quantity = 0.33
    else if (libido === Libido.MEDIUM) quantity = 0.67
    else if (libido === Libido.HIGH) quantity = 1

    return {
      color,
      quantity,
      backgroundOpacity,
      icons: [GRAPH_ICON_BY_LIBIDO[libido]],
    }
  }

  private getSleep(sleep: Sleep): GraphEntryTracker {
    const color = COLOR.SLEEP
    const backgroundOpacity = '40'
    let quantity = 0

    if (sleep === Sleep.POOR) quantity = 0.33
    else if (sleep === Sleep.OKAY) quantity = 0.67
    else if (sleep === Sleep.RESTFUL) quantity = 1

    return {
      color,
      quantity,
      backgroundOpacity,
      icons: [GRAPH_ICON_BY_SLEEP[sleep]],
    }
  }

  private getPain(_pains: DataFlag[], intensity?: StringMap<DataIntensity>): GraphEntryTracker {
    const pains = this._sortDataFlags(_pains, PAIN_ORDER)
    const icons = this.getIconsFromDataFlag(pains)
    const painIntensity = intensity?.[pains[0]!] ?? undefined

    return {
      color: COLOR.PAIN,
      backgroundOpacity: '00',
      quantity: 1,
      icons,
      intensity: painIntensity,
    }
  }

  private getSkin(_skins: DataFlag[], intensity?: StringMap<DataIntensity>): GraphEntryTracker {
    const skins = this._sortDataFlags(_skins, SKIN_ORDER)
    const icons = this.getIconsFromDataFlag(skins)
    const skinIntensity = intensity?.[skins[0]!] ?? undefined

    return {
      color: COLOR.SKIN,
      backgroundOpacity: '00',
      quantity: 1,
      icons,
      intensity: skinIntensity,
    }
  }

  private getCervicalMucus(
    cmQuantity?: DataQuantity,
    cmConsistency?: CervicalMucusConsistency,
  ): GraphEntryTracker {
    const color = COLOR.CERVICAL_MUCUS
    // 50% opacity if only consistency
    const backgroundOpacity = !cmQuantity && cmConsistency ? '80' : '40'
    let quantity = 0

    if (cmQuantity === DataQuantity.LIGHT) quantity = 0.33
    else if (cmQuantity === DataQuantity.MEDIUM) quantity = 0.67
    else if (cmQuantity === DataQuantity.HEAVY) quantity = 1

    const icons: GraphIcon[] = []

    if (cmQuantity) icons.push(GRAPH_ICON_BY_CM_QUANTITY[cmQuantity])
    if (cmConsistency) icons.push(GRAPH_ICON_BY_CM_CONSISTENCY[cmConsistency])

    return {
      color,
      quantity,
      backgroundOpacity,
      icons,
    }
  }

  private getPMSPrediction(
    flags: DataFlag[],
    appearance: AppearanceSettings = AppearanceSettings.LIGHT,
  ): GraphEntryTracker | undefined {
    if (!flags?.includes(DataFlag.MOOD_PMS)) return

    const color = appearance === AppearanceSettings.DARK ? COLOR.SAPHIRE_PINK : COLOR.MEDIUM
    const backgroundOpacity = 'FF'
    const quantity = 1

    return {
      color,
      quantity,
      backgroundOpacity,
      icons: [GRAPH_ICON['PMS_PREDICTION']!],
    }
  }

  public _sortDataFlags(flags: DataFlag[], order: DataFlag[]): DataFlag[] {
    return flags.sort((a, b) => order.indexOf(a) - order.indexOf(b))
  }

  private getIconsFromDataFlag(flags: DataFlag[], maxLength = 4): GraphIcon[] {
    let icons = flags.map(i => {
      return GRAPH_ICON_BY_DATAFLAG[i]!
    })

    if (icons.length > maxLength) {
      icons = icons.slice(0, maxLength - 1)

      icons.push(GRAPH_ICON['MORE']!)
    }

    return icons
  }
}
