<ion-content class="ion-padding-horizontal">
  <h2 class="ion-text-center">{{ 'science-consent-modal-title' | tr }}</h2>

  @if (scienceConsentTerm) {
    <div uid="science__consent__modal" class="scienceConsentModal__container">
      @if (scienceConsentTerm.info) {
        <div [innerHTML]="scienceConsentTerm.info | md"></div>
      }

      <ion-item lines="none" class="ion-margin-vertical">
        <div class="scienceConsentModal__termsDot">•</div>

        <ion-label class="ion-text-wrap" [innerHTML]="scienceConsentTerm.text | md" />
      </ion-item>
    </div>
  }
</ion-content>

<app-scroll-footer [content]="content" color="primary">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <ion-button
          uid="science__consent__accept__button"
          class="ion-no-margin scienceConsentModal__acceptButton"
          shape="round"
          expand="block"
          (click)="closeModal(true)"
        >
          <ion-label>{{ 'txt-accept' | tr }}</ion-label>
        </ion-button>
      </ion-col>

      <ion-col size="12">
        <ion-button
          uid="science__consent__decline__button"
          class="ion-no-margin"
          shape="round"
          expand="block"
          fill="outline"
          color="text"
          (click)="closeModal()"
        >
          <ion-label>{{ 'txt-decline' | tr }}</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</app-scroll-footer>
