import { inject, Injectable } from '@angular/core'
import {
  CartItemInput,
  CartState,
  HardwareId,
  isThermometerKey,
  Product,
  ProductAddOn,
  ProductKey,
  ProductType,
  ProductVariant,
  ProductWithAddons,
} from '@naturalcycles/shared'
import { EVENT } from '../analytics/analytics.cnst'
import { AnalyticsService } from '../analytics/analytics.service'
import { ProductTileOutput } from '../cmp/plan-product-card/plan-product-card.component'
import { ROUTES } from '../cnst/nav.cnst'
import { api } from './api.service'
import { CartService } from './cart.service'
import { getState } from './store.service'
import { tr } from './translation.util'

/**
 * EnhancedAddOn interface extends ProductAddOn by adding some extra properties
 * that are relevant when displaying cart items to the user.
 */
export interface EnhancedAddon extends ProductAddOn {
  ouraRingPriceTextKey?: string
  selected: boolean
}

@Injectable({ providedIn: 'root' })
export class ProductService {
  private analyticsService = inject(AnalyticsService)
  private cartService = inject(CartService)

  public get(keys: ProductKey[] = [], types: ProductType[] = []): Product[] {
    return getState()
      .product.items.filter(product => !keys.length || keys.includes(product.key))
      .filter(product => !types.length || types.includes(product.type))
      .sort((a, b) => {
        if (types.indexOf(a.type) < types.indexOf(b.type)) return -1
        if (types.indexOf(a.type) > types.indexOf(b.type)) return 1
        if (keys.indexOf(a.key) < keys.indexOf(b.key)) return -1
        if (keys.indexOf(a.key) > keys.indexOf(b.key)) return 1
        return 0
      })
  }

  public getByKey(key: ProductKey): Product | undefined {
    return getState().product.items.find(i => i.key === key)
  }

  public getByType(type: ProductType): Product[] {
    return getState().product.items.filter(i => i.type === type)
  }

  public planIncludesTherm(product: Product): boolean {
    return product.variants.includes(ProductVariant.thermometer)
  }

  public planIncludesBluetoothTherm(product: Product): boolean {
    return product.variants.includes(ProductVariant.includesBluetoothThermometer)
  }

  public getEnhancedAddon(plan: ProductWithAddons): EnhancedAddon | undefined {
    const { addOns } = plan
    const addOn = addOns[0]
    if (!addOn) return
    const isOura = addOn.key === ProductKey.OURA_RING_DISCOUNT
    return {
      ...addOn,
      title: isOura ? addOn.title : tr(`cart-${addOn.key}`),
      ouraRingPriceTextKey: isOura ? 'oura-ring-price-text' : undefined,
      selected: true,
    }
  }

  public getDefaultThermometer(): ProductKey {
    const { items } = getState().product
    if (items.some(i => i.key === ProductKey.T3_THERMOMETER)) {
      return ProductKey.T3_THERMOMETER
    }
    if (items.some(i => i.key === ProductKey.BT_THERMOMETER)) {
      return ProductKey.BT_THERMOMETER
    }
    return ProductKey.THERMOMETER
  }

  public async getPlans(
    hwId: HardwareId,
    cartWearableVariant: ProductVariant | undefined,
  ): Promise<ProductWithAddons[]> {
    return await api.get<ProductWithAddons[]>('product/plans', {
      searchParams: { hwId, savingsAsAmount: true, cartWearableVariant },
    })
  }

  /**
   * Updates the cart with the selected plan and handles analytics
   * @param output The selected plan and optional add-on
   */
  public async selectPlan(output: ProductTileOutput): Promise<CartState> {
    const { cart, ui } = getState()

    const { plan, addOn } = output
    const { key } = plan

    this.trackPlanSelected(key)
    void this.onChoosePlanAnalytics(key)

    const nonSubscriptionItems = cart.items.filter(i => i.type !== ProductType.SUBSCRIPTION)

    const items: CartItemInput[] = [
      {
        key,
        quantity: 1,
      },
      // We already have the selected subscription above,
      // so we don't want any other subscriptions lurking around
      ...nonSubscriptionItems,
    ]

    const hasAddOnInCart = addOn && nonSubscriptionItems.some(i => i.key === addOn.key)

    const shouldApplyAddOn = addOn && !hasAddOnInCart && addOn.selected
    const shouldRemoveAddOn = addOn && hasAddOnInCart && !addOn.selected

    //  Add addOn to cart if user opted to get one and it's not already in the cart
    if (shouldApplyAddOn) {
      const addOnItem: CartItemInput = {
        key: addOn.key,
        quantity: 1,
      }
      if (!isThermometerKey(addOn.key)) {
        addOnItem.variant = ui.ownsHw ? ProductVariant.ownsDevice : ProductVariant.wantsDevice
      }
      items.push(addOnItem)
      // If the user first wanted an addon but changed their mind, remove it from the cart
    } else if (shouldRemoveAddOn) {
      items.splice(
        items.findIndex(i => i.key === addOn.key),
        1,
      )
    }

    const updatedCart = await this.cartService.update({
      items,
      discount: cart.discount,
      hwId: cart.hwId,
      paidWithIAP: !!ui.pendingIapTransaction,
    })

    return updatedCart
  }

  public getNextPage(cart: CartState): string {
    const { pendingIapTransaction } = getState().ui
    const skipPaymentPage = !!pendingIapTransaction
    /**
     * Non shippables on iOS are handled by the IAP flow, meaning we don't have to collect a billing address.
     */

    if (
      !cart.needsBillingAddress &&
      !cart.needsShippingAddress &&
      /**
       * It is possible that the user has provided addresses but not selected a thermometer variant
       * Since variant is handled on AddressPage, we redirect there if thermometer selection is not valid
       */
      this.cartService.hasValidThermometerSelection()
    ) {
      return skipPaymentPage ? ROUTES.OrderPage : ROUTES.PaymentPage
    }

    return ROUTES.AddressPage
  }

  private trackPlanSelected(key: ProductKey): void {
    const planSelectedEvent = {
      [ProductKey.YEAR]: EVENT.PLANS_PAGE_YEAR,
      [ProductKey.MONTH]: EVENT.PLANS_PAGE_MONTH,
      [ProductKey.ROLLING_TRIAL]: EVENT.PLANS_PAGE_ROLLING_TRIAL,
    }
    void this.analyticsService.trackEvent(planSelectedEvent[key as keyof typeof planSelectedEvent])
  }

  private async onChoosePlanAnalytics(key: ProductKey): Promise<void> {
    // no adjust event for trial plan yet
    if (key === ProductKey.TRIAL) return

    const adjustEventByProductKey = {
      [ProductKey.MONTH]: EVENT.CHOOSE_SUB_MONTH,
      [ProductKey.YEAR]: EVENT.CHOOSE_SUB_YEAR,
      [ProductKey.ROLLING_TRIAL]: EVENT.CHOOSE_SUB_ROLLING_TRIAL,
    }

    this.trackAdjustChooseSub(adjustEventByProductKey[key as keyof typeof adjustEventByProductKey])
    this.trackAdjustChooseSub(EVENT.CHOOSE_SUB_PAID)
  }

  private trackAdjustChooseSub(event: EVENT): void {
    const { account } = getState()
    // track choose sub only if the user have not paid = no plan
    if (account && !account.plan) {
      void this.analyticsService.trackEvent(event)
    }
  }
}
