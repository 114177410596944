import {
  GoalBreakpointOption,
  MedicalConditionsBreakpointOption,
  QuizDataInput,
  QuizFlow,
} from '@naturalcycles/shared'
import { DefaultQuizSection, QuizSections } from '@src/typings/quiz.enum'
import {
  AppQuizFlow,
  QuizColumnContent,
  QuizSignatureObject,
  TranslatedInfoPageContent,
} from '@src/typings/quiz.types'
import { allQuizSections } from '../cnst/quiz.cnst'
import { QuizPage } from '../cnst/quiz-pages.cnst'
import { tr, trIfExists } from '../srv/translation.util'

const trWithFallback = (key: string, fallback: string): string => {
  return trIfExists(key) || tr(fallback)
}

export const getMedicalConditionsInfoContent = (
  quizData: QuizDataInput | null,
): TranslatedInfoPageContent => {
  const medicalConditions = quizData?.medicalConditions || []
  let medicalCondition: keyof typeof MedicalConditionsBreakpointOption | 'multiple' = 'multiple'
  if (medicalConditions.length === 1) {
    medicalCondition = MedicalConditionsBreakpointOption[
      medicalConditions[0]!
    ] as keyof typeof MedicalConditionsBreakpointOption
  }
  const content: TranslatedInfoPageContent = {}
  const isPlan = quizData?.fertilityGoal === GoalBreakpointOption.plan

  const keys: (keyof TranslatedInfoPageContent)[] = ['title', 'subtitle', 'markdown']
  keys.forEach(key => {
    const baseKey = `txt-quiz-medical-conditions-${key}--${medicalCondition}`
    if (isPlan) content[key] = trWithFallback(`${baseKey}-plan`, baseKey)
    else content[key] = tr(baseKey)
  })

  return content
}

export const addPageToFlowAfterCurrent = (
  flow: QuizFlow<QuizPage>,
  section: DefaultQuizSection,
  currentPage: QuizPage,
  pageToAdd: QuizPage,
): QuizFlow<QuizPage> => {
  const updatedSection = [...(flow[allQuizSections[section]] || [])]
  const currentIndex = updatedSection.indexOf(currentPage)
  if (currentIndex !== -1 && !updatedSection.includes(pageToAdd)) {
    updatedSection.splice(currentIndex + 1, 0, pageToAdd)
  }
  flow[allQuizSections[section]] = updatedSection
  return flow
}

export const removePagesFromFlow = (
  flow: QuizFlow<QuizPage>,
  section: DefaultQuizSection,
  pagesToRemove: QuizPage[],
): QuizFlow<QuizPage> => {
  let updatedFlow = flow
  pagesToRemove.forEach(pageToRemove => {
    updatedFlow = removePageFromFlow(updatedFlow, section, pageToRemove)
  })
  return flow
}

export const removePageFromFlow = (
  flow: QuizFlow<QuizPage>,
  section: DefaultQuizSection,
  pageToRemove: QuizPage,
): QuizFlow<QuizPage> => {
  const updatedSection = [...(flow[allQuizSections[section]] || [])]
  const currentIndex = updatedSection.indexOf(pageToRemove)
  if (currentIndex === -1) return flow

  updatedSection.splice(currentIndex, 1)
  flow[allQuizSections[section]] = updatedSection
  return flow
}

export const clearFlowAfterCurrentSection = (
  flow: QuizFlow<QuizPage>,
  currentSection: DefaultQuizSection,
): QuizFlow<QuizPage> => {
  const updated = [...flow].slice(0, allQuizSections[currentSection] + 1)
  return updated
}
export const getCompareBirthControlColumnsContent = (
  type: 'nc' | 'alt',
  goal: 'plan' | 'prevent',
): QuizColumnContent => {
  const baseKey = 'txt-quiz-compare-birthcontrol'
  return {
    img: `assets/img/quiz/compare-birthcontrol-${type}.svg`,
    title: type === 'alt' ? tr(`${baseKey}-title--${type}`) : 'Natural Cycles°',
    comparisonPoints: [
      tr(`${baseKey}--${type}--${goal}`),
      tr(`${baseKey}--${type}--common_1`),
      tr(`${baseKey}--${type}--common_2`),
    ],
  }
}

export abstract class QuizModifier<QUIZ_ENGINE_CONFIG> {
  abstract _shouldEnhance(obj: QuizSignatureObject): boolean
  abstract enhanceEngineConfig(
    obj: QuizSignatureObject,
    config?: QUIZ_ENGINE_CONFIG,
  ): QUIZ_ENGINE_CONFIG
  abstract enhanceSections(obj: QuizSignatureObject, sections?: QuizSections): QuizSections
  abstract enhanceInitialFlow(obj: QuizSignatureObject, flow?: AppQuizFlow): AppQuizFlow
  abstract enhanceEntry(obj: QuizSignatureObject, entry?: string | null): string | null
  abstract enhanceExit(obj: QuizSignatureObject, exit?: string | null): string | null
}
