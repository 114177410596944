import { inject, Injectable } from '@angular/core'
import { AWSetupState } from '@app/cnst/appleWatch.cnst'
import { ICON, ICON_BY_DATAFLAG } from '@app/cnst/icons.cnst'
import { AppleWatchService } from '@app/srv/appleWatch.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { getState, select2 } from '@app/srv/store.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import {
  _groupBy,
  _Memo,
  _objectEntries,
  _objectKeys,
  _sortBy,
  _sortDescBy,
  _stringMapValues,
  IsoDate,
  localDate,
  localTime,
  StringMap,
} from '@naturalcycles/js-lib'
import { DailyEntryBM, DataFlag, DEVIATION_REASON_FLAGS, HardwareId } from '@naturalcycles/shared'
import {
  combineLatestWith,
  filter,
  first,
  map,
  merge,
  Observable,
  pairwise,
  switchMap,
  withLatestFrom,
} from 'rxjs'
import { MultipleEntriesModal } from './multiple-entries.modal'

export interface MultipleEntriesModalInput {
  title?: string
  body?: string
  dismissTitle?: string
  entries: DailyEntryBM[]
}

export interface MultipleEntryItems {
  entries: MultipleEntryItem[]
  date: IsoDate
  hasSavedTemps: boolean
}

export interface MultipleEntryItem extends DailyEntryBM {
  deviating?: boolean
  icon?: EntryIcon
  today: boolean
}

interface EntryIcon {
  icon: ICON
  color: string
}

@Injectable({ providedIn: 'root' })
export class MultipleEntriesService {
  private appleWatchService = inject(AppleWatchService)
  private popupController = inject(PopupController)

  private hwId$ = select2(s => s.account?.hwId)
  private lastTempEntryDate$ = select2(s => s.account?.medicalStats?.lastTempEntryDate)
  private hwChanges$ = select2(s => s.hwChanges)
  private entryMap$ = select2(s => s.userFertility.entryMap)
  private startDate$ = select2(s => s.userFertility.startDate)
  private lastActive$ = select2(s => s.userSettings.lastActive)

  /** To init pipes only when the user is logged in */
  private activeUserHwId$ = this.hwId$.pipe(filter(Boolean))
  private activeUserStartDate$ = this.startDate$.pipe(filter(Boolean))

  /**
   * We don't want to show entries being synced for dates before users started to use their wearable.
   * Hence we need to define the earliest date we could use for filtering.
   */
  public hwStartDate$ = this.activeUserHwId$.pipe(
    combineLatestWith(this.activeUserStartDate$, this.hwChanges$),
    map(([hwId, startDate, hwChanges]) => {
      const currentHwChangeDates = _objectEntries(hwChanges)
        .filter(([_date, value]) => value === hwId)
        .map(([date]) => localTime(date as IsoDate))

      return localTime.max([localTime(startDate), ...currentHwChangeDates])
    }),
  )

  /** We only want to show recent changes, so we need to filter out all entries before previous lastTempEntryDate */
  public entryMapFilterDate$ = this.pairwisedPipe(this.lastTempEntryDate$).pipe(
    combineLatestWith(this.hwStartDate$),
    map(([[previousLastTempEntryDate, _lastTempEntryDate], initialDate]) =>
      localTime
        .max([previousLastTempEntryDate && localTime(previousLastTempEntryDate), initialDate])
        .toISODate(),
    ),
  )

  private activeAppEntryMap$ = this.activeUserHwId$.pipe(
    first(),
    switchMap(() =>
      merge(
        // Persisted entryMap
        this.entryMap$.pipe(first()),
        this.lastActive$.pipe(
          // Combination of pairwise and first is meant to wait for the first time we update lastActive after FaceID check.
          // This would allow to throttle all entryMap$ emits until the app becomes active.
          pairwise(),
          first(),
          switchMap(() => this.entryMap$),
        ),
      ),
    ),
  )

  private canShowAWEntries$ = this.appleWatchService.awSetupState$.pipe(
    map(state => state === AWSetupState.SETUP_COMPLETE),
  )

  /**
   * Observable comparing entryMaps and returning new entries for the MultipleEntriesModal.
   */
  private multipleEntriesData$ = this.activeAppEntryMap$.pipe(
    // Combine latest entryMap with previous value and filter date
    pairwise(),
    withLatestFrom(this.entryMapFilterDate$),
    distinctUntilDeeplyChanged(),

    // Check whether we can show the modal
    withLatestFrom(this.canShowAWEntries$),
    filter(([_, canShowAWEntries]) => {
      const {
        account: { hwId },
        partnerAccount,
      } = getState()

      if (partnerAccount) return false

      switch (hwId) {
        case HardwareId.OURA:
          return true

        case HardwareId.APPLE_WATCH:
          return canShowAWEntries

        default:
          return false
      }
    }),
    // And finally emit new entries
    map(([[pairedEntryMaps, entryMapFilterDate], ..._rest]) =>
      this.findNewEntries(pairedEntryMaps, entryMapFilterDate),
    ),
  )

  @_Memo()
  public init(): void {
    this.multipleEntriesData$.subscribe(entries => {
      this.showMultipleEntriesIfNeeded(entries)
    })
  }

  /**
   * Helper method to make pairwise pipe work for the same values.
   * Currently we distinctUntilChanged all values in select2 which prevents from saving duplicated values.
   * We use entryMap$ as base trigger for the pipe.
   */
  private pairwisedPipe<T>(observable: Observable<T>): Observable<[T, T]> {
    return this.entryMap$.pipe(
      switchMap(() => observable),
      pairwise(),
    )
  }

  /**
   * Compares all entries in 2 maps since entryMapFilterDate and returns those with new or changed temperature.
   * Returns empty array if all entries were updated on the same day they were measured.
   */
  public findNewEntries(
    [previousEntryMap, newEntryMap]: [StringMap<DailyEntryBM>, StringMap<DailyEntryBM>],
    entryMapFilterDate: IsoDate,
  ): DailyEntryBM[] {
    const newMapDates = _objectKeys(newEntryMap)
    const datesToCheck = newMapDates.slice(newMapDates.findIndex(date => date > entryMapFilterDate))

    const entries = datesToCheck
      .map(date => {
        const newEntry = newEntryMap[date]
        const previousEntry = previousEntryMap[date]

        if (!newEntry?.temperature) return
        if (!previousEntry) return newEntry
        if (newEntry.temperature === previousEntry.temperature) return

        return newEntry
      })
      .reverse()
      .filter(Boolean) as DailyEntryBM[]

    const showEntries = entries.some(
      entry =>
        entry.temperatureUpdatedTimestamp &&
        entry.date !== localTime(entry.temperatureUpdatedTimestamp).toISODate(),
    )

    return showEntries ? entries : []
  }

  public showMultipleEntriesIfNeeded(entries: DailyEntryBM[]): void {
    if (!entries.length) return

    const todayEntry = entries.find(e => e.date === localDate.today().toISODate())

    if (todayEntry && entries.length === 1) return

    const componentProps: MultipleEntriesModalInput = {
      entries,
      title: 'add-data-multiple-entries-modal-title',
      body: todayEntry
        ? 'add-data-multiple-entries-modal-multiple-body'
        : 'add-data-multiple-entries-modal-retrospective-body',
    }

    void this.showMultipleEntriesModal(componentProps)
  }

  public async showMultipleEntriesModal(input: MultipleEntriesModalInput): Promise<DailyEntryBM[]> {
    const entryItems = this.entriesToMultipleEntryItems(input.entries)

    const modal = await this.popupController.presentModal(
      {
        component: MultipleEntriesModal,
        componentProps: {
          ...input,
          entryItems,
        },
        cssClass: ['modal--transparent', 'modal--alert'],
        backdropDismiss: false,
      },
      'modal-multipleEntries',
      Priority.VERY_HIGH,
    )

    const { data } = await modal.onWillDismiss()

    return data || []
  }

  public entriesToMultipleEntryItems(entries: DailyEntryBM[]): MultipleEntryItems[] {
    const multipleEntries = entries.map(entry => this.entryToMultipleEntryItem(entry))
    // days should be sorted in descending order
    _sortDescBy(multipleEntries, de => de.date, true)
    const entriesByDate = _groupBy(multipleEntries, de => de.date)

    return _stringMapValues(entriesByDate).map(daysEntries => ({
      // show the earliest temperature for each day first; manual temp always on top
      entries: _sortBy(daysEntries, de => de.temperatureMeasuredTimestamp || 0),
      hasSavedTemps: daysEntries.some(entry => !!entry.updated),
      date: daysEntries[0]!.date,
    }))
  }

  private entryToMultipleEntryItem(entry: DailyEntryBM): MultipleEntryItem {
    const deviationReason = entry.dataFlags.find(f => DataFlag[f]?.startsWith('DEVIATION_REASON_'))
    const ouraFlag = entry.dataFlags.find(f => DataFlag[f]?.startsWith('OURA_'))

    const iconName = ICON_BY_DATAFLAG[deviationReason || ouraFlag!]
    const icon = iconName || (entry.temperature ? undefined : ICON.CLOSE)

    const color = iconName ? 'white' : 'black'

    const today = localDate.today().toISODate() === entry.date

    return {
      ...entry,
      deviating: entry.dataFlags.some(flag => DEVIATION_REASON_FLAGS.has(flag)),
      icon: icon && {
        icon,
        color,
      },
      today,
    }
  }
}
