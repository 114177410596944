import { _deepCopy } from '@naturalcycles/js-lib'
import {
  BirthControlMethodBreakpointOption,
  BreakpointAction,
  ChoiceFunction,
  QuizDataInput,
} from '@naturalcycles/shared'
import { allQuizSections } from '../quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const hormonalTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  if (!(flow[allQuizSections.goal] || []).includes(QuizPage.sideEffects)) {
    flow[allQuizSections.goal] = [...(flow[allQuizSections.goal] || []), QuizPage.sideEffects]
  }

  flow[allQuizSections.cycle] = [QuizPage.hormonalBirthControlHowLongPrevent]
  return flow
}

const copperIUDTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  if (!flow[allQuizSections.goal]!.includes(QuizPage.sideEffects)) {
    flow[allQuizSections.goal] = [...(flow[allQuizSections.goal] || []), QuizPage.sideEffects]
  }

  flow[allQuizSections.cycle] = [
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
    QuizPage.medicalConditionsPrevent,
  ]

  return flow
}

const nonHormonalTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  const f = _deepCopy(flow)

  /**
   * When nonHormonal is selected, sideEffects page is skipped.
   * QuizPage.sideEffects is in the array in case the user selected hormonal/copperUID earlier.
   */
  let position = (f[allQuizSections.goal] || []).indexOf(QuizPage.sideEffects)
  if (position !== -1) (f[allQuizSections.goal] || []).splice(position, 1)

  /**
   * When nonHormonal is selected, sideEffectsInfo page is skipped.
   * Page.sideEffectsInfo is in the array in case the user selected hormonal/copperUID earlier and selected a side effect.
   */
  position = (f[allQuizSections.goal] || []).indexOf(QuizPage.sideEffectsInfo)
  if (position !== -1) (f[allQuizSections.goal] || []).splice(position, 1)

  f[allQuizSections.cycle] = [
    QuizPage.cycleLength,
    QuizPage.periodRegularity,
    QuizPage.periodRegularityInfo,
    QuizPage.cycleStartDate,
    QuizPage.medicalConditionsPrevent,
  ]
  return f
}

export const birthControlMethod: BreakpointAction<
  BirthControlMethodBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  // Hormonal
  [BirthControlMethodBreakpointOption.pill]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.shot]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.implant]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.hormonalIUD]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.patch]: hormonalTransformFunction,
  [BirthControlMethodBreakpointOption.nuvaRing]: hormonalTransformFunction,

  // Copper IUD
  [BirthControlMethodBreakpointOption.copperIUD]: copperIUDTransformFunction,

  // Non-hormonal
  [BirthControlMethodBreakpointOption.condom]: nonHormonalTransformFunction,
  [BirthControlMethodBreakpointOption.pullOut]: nonHormonalTransformFunction,
  [BirthControlMethodBreakpointOption.other]: nonHormonalTransformFunction,
  [BirthControlMethodBreakpointOption.none]: nonHormonalTransformFunction,
}
