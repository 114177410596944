import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { TemperatureC, TemperatureF } from '@app/cmp/temperature-numpad/temperature-numpad.service'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { MockedTemperature } from '@app/model/test.model'
import { select2 } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { numberToUUID } from '@capacitor-community/bluetooth-le'
import { AppError, LocalDate, localDate, localTime } from '@naturalcycles/js-lib'
import { BatteryStatus, HardwareId } from '@naturalcycles/shared'
import { BluetoothClient } from '@src/typings/capacitor'
import { combineLatestWith, filter, skipWhile, startWith, Subject, Subscription, take } from 'rxjs'
import { BluetoothService, CONFIG_BY_HWID, SyncedTemperature } from './bluetooth.service'
import { DateFormat, DateService } from './date.service'
import { HardwareDeviceService } from './hardwareDevice.service'
import { PopupController } from './popup.controller'
import { getState } from './store.service'
import { TemperatureService } from './temperature.service'
import { tr } from './translation.util'

interface TemperatureDataResponse {
  temperatures: number[][]
  checksum: number
}

const CHARACTERISTIC_UUID = numberToUUID(0xfff1)
const CONFIG = CONFIG_BY_HWID[HardwareId.UEBE_THERMOMETER]!

/**
 * Min voltage = 2.0V
 * Max voltage = 3.5V
 * Formula: batteryLevel * 100 - 100
 */

const MIN_BATTERY_LEVEL = 100
const MAX_BATTERY_LEVEL = 250

@Injectable({ providedIn: 'root' })
export class UebeService {
  private analyticsService = inject(AnalyticsService)
  private bluetoothService = inject(BluetoothService)
  private dateService = inject(DateService)
  private hardwareDeviceService = inject(HardwareDeviceService)
  private popupController = inject(PopupController)
  private temperatureService = inject(TemperatureService)

  private fahrenheit$ = select2(s => s.account.fahrenheit)

  private temperatureData$ = new Subject<TemperatureDataResponse>()
  private temperatureData: number[] = []
  private temperatureSubscription?: Subscription

  public mockTemperatures(temperatures: MockedTemperature[]): void {
    const { fahrenheit } = getState().account
    const min = (fahrenheit ? TemperatureF.MIN : TemperatureC.MIN) * 100
    const max = (fahrenheit ? TemperatureF.MAX : TemperatureC.MAX) * 100
    const now = localTime.now()

    const syncedTemperatures: number[][] = temperatures.map(({ value, date }) => {
      if (value) {
        value *= 100
        value = Math.min(value, max)
        value = Math.max(value, min)
      }

      const temperature = (value ?? Math.floor(Math.random() * (max - min + 1) + min)) / 100

      const temp = temperature.toFixed(2).split('.').map(Number)

      const day = localTime.orNow(date).setHour(now.hour).setMinute(now.minute)

      return [day.year - 2000, day.month, day.day, day.hour, day.minute, temp[0]!, temp[1]!]
    })

    this._handleTemperatures(syncedTemperatures)
  }

  public async startNotifications(
    deviceId: string,
    acknowledgeTemperatures: boolean,
  ): Promise<boolean> {
    this.bluetoothService.log(`Start notifications`)

    const result = await BluetoothClient.startNotifications(
      deviceId,
      CONFIG.serviceUuid,
      CHARACTERISTIC_UUID,
      data => this.onDataReceived(deviceId, data, acknowledgeTemperatures),
    ).catch(err => {
      logUtil.error(`[BLE] startNotifications ${err?.message}`)
      return false
    })

    if (result === false) return false

    // subscribe to disconnect event, and check if there were temperatures synced
    this.bluetoothService.connected$
      .pipe(
        filter(connected => !connected),
        combineLatestWith(this.bluetoothService.temperatures$.pipe(startWith(false))),
        take(1),
      )
      .subscribe(([_connected, temperatures]) => {
        if (!temperatures) {
          // emit empty array to acknowledge that there was a sync without temperatures
          this.bluetoothService.temperatures$.next([])
        }
      })

    return true
  }

  private stopNotifications(deviceId: string): void {
    void BluetoothClient.stopNotifications(deviceId, CONFIG.serviceUuid, CHARACTERISTIC_UUID)
  }

  @tryCatch({
    alert: false,
    onError: error => {
      logUtil.error(error)
    },
  })
  private onDataReceived(deviceId: string, data: DataView, acknowledgeTemperatures: boolean): void {
    const dataArray = new Uint8Array(data.buffer)
    this.bluetoothService.log(`Data received from: ${deviceId}; data: ${dataArray}`)

    const command = dataArray[4]?.toString(16)
    this.bluetoothService.log(`Command: ${command}`)

    this.analyticsService.trackEvent(EVENT.BLUETOOTH_NOTIFICATION, {
      deviceId,
      command,
      data: dataArray,
      serviceUuid: CONFIG.serviceUuid,
      characteristic: CHARACTERISTIC_UUID,
      acknowledgeTemperatures,
    })

    switch (command) {
      // Thermometer
      case 'a1':
        this.readBatteryLevel(dataArray)
        this.respondToA1(deviceId, dataArray)
        break

      // Temperature incoming
      case 'a3': {
        if (!acknowledgeTemperatures) {
          void this.popupController.presentAlert(
            {
              header: tr('uebe-unsynced-temps-alert-title'),
              message: tr('uebe-unsynced-temps-alert-body'),
              buttons: [
                {
                  text: tr('txt-ok'),
                  role: 'cancel',
                },
              ],
            },
            'alert-uebeUnsyncedTemps',
          )

          this.stopNotifications(deviceId)
          break
        }

        const length = dataArray[dataArray.length - 1]!

        this.waitForTemps(length, deviceId, dataArray)

        break
      }

      // Temperature data
      default: {
        if (!acknowledgeTemperatures) break
        this.temperatureData.push(...dataArray)

        const chunks = this.sliceIntoChunks(this.temperatureData, 9).filter(
          chunk => chunk.length === 9,
        )

        this.temperatureData$.next({
          temperatures: chunks,
          checksum: dataArray[dataArray.length - 1]!,
        })

        break
      }
    }
  }

  private waitForTemps(length: number, deviceId: string, dataArray: Uint8Array): void {
    void this.analyticsService.trackEvent(EVENT.BLUETOOTH_SYNCED, { temperatureCount: length })
    // just in case
    this.resetTemps()

    this.temperatureSubscription = this.temperatureData$
      .pipe(
        skipWhile(({ temperatures }) => temperatures.length < length),
        combineLatestWith(this.fahrenheit$),
      )
      .subscribe(([{ temperatures, checksum }, fahrenheit]) => {
        // this.hasSyncedTemp = !!temperatures.length

        this._handleTemperatures(temperatures, fahrenheit)

        this.respondToA3(deviceId, dataArray, checksum)

        this.resetTemps()
      })
  }

  public _handleTemperatures(temps: number[][], fahrenheit = false): void {
    const temperatures: SyncedTemperature[] = []
    temps.forEach(data => {
      const [year, month, day, hour, minute, temp1, temp2] = data

      if (data.length < 7) {
        logUtil.error(
          new AppError(`Bluetooth data array too short`, {
            data,
            fahrenheit,
          }),
        )
        return
      }

      if (
        [year, month, day, hour, minute, temp1, temp2].some(
          value => value === undefined || isNaN(value),
        )
      ) {
        logUtil.error(
          new AppError(`Bluetooth data includes NaN`, {
            data,
            fahrenheit,
          }),
        )
        return
      }

      let temperature = temp1! * 100 + temp2!
      temperature = this.temperatureService.parseSyncedTemperature(temperature, fahrenheit)
      const timestamp = localTime.fromDateTimeObject({
        year: 2000 + year!,
        month: month!,
        day: day!,
        hour,
        minute,
      }).unix

      const btTemp: SyncedTemperature = {
        temperature,
        timestamp,
      }

      temperatures.push(btTemp)
      this.bluetoothService.log(`Got temperature: ${btTemp.temperature}`)
    })

    this.bluetoothService.temperatures$.next(temperatures)
  }

  private resetTemps(): void {
    this.temperatureData = []
    this.temperatureSubscription?.unsubscribe()
  }

  // todo: use _chunk instead?
  private sliceIntoChunks(arr: number[], chunkSize: number): number[][] {
    const res: number[][] = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize)
      res.push(chunk)
    }
    return res
  }

  private readBatteryLevel(data: Uint8Array): void {
    const batteryLevel = data[12]
    if (!batteryLevel) return

    const batteryPercentage = Math.round(
      ((batteryLevel - MIN_BATTERY_LEVEL) / (MAX_BATTERY_LEVEL - MIN_BATTERY_LEVEL)) * 100,
    )

    const batteryStatus = this.getBatteryStatusFromPercentage(batteryPercentage)

    this.hardwareDeviceService.saveBatteryStatus(batteryStatus, batteryPercentage)
  }

  private getBatteryStatusFromPercentage(batteryPercentage: number): BatteryStatus {
    if (batteryPercentage >= 50) return BatteryStatus.HIGH
    if (batteryPercentage >= 33) return BatteryStatus.MEDIUM
    return BatteryStatus.LOW
  }

  private respondToA1(deviceId: string, data: Uint8Array): void {
    const today = localDate.today()
    const now = localTime.now()

    const checksum = data.slice(0, data.length - 2).reduce((a, b) => a + b, 0)
    const settings = this.getThermometerSettings(today)

    /*
     * A1 Response format
     * {
     *   Header: 4D,
     *   Device: FC,
     *   Length_H: 00,
     *   Length_L: 08,
     *   CMD: A1,
     *   Year: XX,
     *   Month: XX,
     *   Day: XX,
     *   Hour: XX,
     *   Minute: XX,
     *   CtrlMode: XX,
     *   Checksum: XX,
     * }
     */
    const response = [
      0x4d,
      0xfc,
      0x00,
      0x08,
      0xa1,
      Number(`${now.year}`.substring(2)),
      now.month,
      now.day,
      now.hour,
      now.minute,
      settings,
      checksum,
    ]

    void this.bluetoothService.write(deviceId, CONFIG.serviceUuid, CHARACTERISTIC_UUID, response)

    this.hardwareDeviceService.clearPendingBluetoothSettings()
  }

  private respondToA3(deviceId: string, dataArray: Uint8Array, checksum: number): void {
    const DCntH = dataArray[6]! // eslint-disable-line @typescript-eslint/naming-convention
    const DCntL = dataArray[7]! // eslint-disable-line @typescript-eslint/naming-convention

    /*
     * A3 Response format
     * {
     *   Header: 4D,
     *   Device: FC,
     *   Length_H: 00,
     *   Length_L: 04,
     *   CMD: A3,
     *   DCnt_H: XX,
     *   DCnt_L: XX,
     *   Checksum: XX,
     * }
     */
    const response = [0x4d, 0xfc, 0x00, 0x04, 0xa3, DCntH, DCntL, checksum]

    void this.bluetoothService.write(deviceId, CONFIG.serviceUuid, CHARACTERISTIC_UUID, response)
  }

  private getThermometerSettings(today: LocalDate): number {
    const {
      account: { fahrenheit },
      hwDevice,
    } = getState()
    const buzzer = hwDevice?.buzzer
    const backlight = hwDevice?.backlight

    const ddMM = this.dateService
      .localizeDate(today, DateFormat.DAY_MONTH)
      .startsWith(today.day.toString())

    let settings = fahrenheit ? 0 : 1
    if (backlight) settings += 2
    if (ddMM) settings += 4
    if (buzzer) settings += 8

    return settings
  }
}
