import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { decorate, ErrorHandlerType } from '@app/decorators/decorators'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { di } from '@app/srv/di.service'
import { ErrorService } from '@app/srv/error.service'
import { dispatch, getState } from '@app/srv/store.service'
import { PluginListenerHandle } from '@capacitor/core'
import {
  _anyToError,
  _assert,
  _errorDataAppend,
  _findLast,
  _invert,
  _isTruthy,
  _objectKeys,
  _omit,
  _Retry,
  _sortBy,
  _stringify,
  _stringMapValues,
  UnixTimestampMillis,
} from '@naturalcycles/js-lib'
import {
  PaymentMethodInput,
  PaymentMethodType,
  Price,
  ProductKey,
  ProductType,
} from '@naturalcycles/shared'
import { isAb274Test } from '@src/ab274/ab274'
import { env } from '@src/environments/environment'
import { NCInAppPurchase } from '@src/typings/capacitor'
import { isIOSApp } from '../cnst/userDevice.cnst'
import { sentryService } from './sentry.service'

export const IAP_PRODUCT_KEY: Partial<Record<ProductKey, string>> = {
  [ProductKey.YEAR]: `${env.appleBundleIdentifier}.yearly3`,
  [ProductKey.MONTH]: `${env.appleBundleIdentifier}.monthly3`,
  [ProductKey.ROLLING_TRIAL_7DAYS]: `${env.appleBundleIdentifier}.trial2`,
}
const IAP_PRODUCT_BY_NAME = _invert(IAP_PRODUCT_KEY)

export const NC_FLO_YEARLY_PRICE_CENTS_USD = 9999

export interface IAPTransactionRaw {
  jwsRepresentation: string
  productId: string
  productType: string
  purchaseDate: UnixTimestampMillis
  signedDate: UnixTimestampMillis
  expirationDate?: UnixTimestampMillis
  price?: number
  currency?: string
  offerType?: string
  offerId?: string
  offerPaymentMode?: string
  reason?: string
}

export interface IAPTransaction extends Omit<IAPTransactionRaw, 'price'> {
  productKey: ProductKey
  price: Price
}

@Injectable({ providedIn: 'root' })
export class IAPService {
  private analyticsService = inject(AnalyticsService)

  private listenForTransactionsHandle?: PluginListenerHandle

  private async getProducts(productIds: string[]): Promise<void> {
    try {
      return await NCInAppPurchase.getProducts({ productIds })
    } catch (err) {
      sentryService.captureException(
        _errorDataAppend(err, { fingerprint: ['IAPService.getProducts'] }),
      )
    }
  }

  public async subscribe(productId: string): Promise<IAPTransaction | void> {
    const rawTransaction = await NCInAppPurchase.subscribe({ productId })
    return this.enrichTransaction(rawTransaction)
  }

  public async getTransactions(): Promise<IAPTransactionRaw[]> {
    const { transactions } = await NCInAppPurchase.getTransactions()
    return transactions
  }

  @tryCatch({
    alert: false,
  })
  public async init(): Promise<void> {
    if (isIOSApp) {
      await this.getProducts(_stringMapValues(IAP_PRODUCT_KEY))
    }
  }

  @tryCatch({
    alert: false,
    onError: error => {
      void di.get(ErrorService).showErrorDialog(error)
    },
  })
  public async purchase(): Promise<string | undefined> {
    const subscription = getState().cart.items.find(item => item.type === ProductType.SUBSCRIPTION)
    const productId = IAP_PRODUCT_KEY[subscription!.key]
    if (!productId) return
    try {
      const transaction = await this.subscribe(productId)
      return transaction?.jwsRepresentation
    } catch (err) {
      if (err instanceof Error && err.message.includes('User cancelled')) return

      console.log('IAP Purchase Error', _stringify(err))
      console.log({
        subscription,
        productId,
        IAP_PRODUCT_KEY,
      })
      sentryService.captureException(_anyToError(err), { fingerprint: ['IAPService.purchase'] })
      throw err
    }
  }

  @_Retry({
    maxAttempts: 2,
    delay: 500,
  })
  @decorate({
    errorHandlerType: ErrorHandlerType.LOG,
  })
  public async getExistingIAPSubscription(): Promise<IAPTransaction | undefined> {
    const transactions = await this.getTransactions()
    const enrichedTransactions = transactions.map(this.enrichTransaction).filter(_isTruthy)

    void this.analyticsService.trackEvent(EVENT.RESTORE_IAP, {
      enrichedTransactions,
    })

    const filteredTransactions = enrichedTransactions.filter(
      transaction => transaction?.expirationDate && transaction.expirationDate > Date.now(),
    )

    const sortedPurchases = _sortBy(filteredTransactions, t => t.purchaseDate) // ascending order
    return _findLast(sortedPurchases, p => !!IAP_PRODUCT_BY_NAME[p.productId])
  }

  public async getIAPReceipt(transaction: IAPTransaction): Promise<PaymentMethodInput> {
    return {
      type: PaymentMethodType.IAPAPPLE,
      jwsTransaction: transaction.jwsRepresentation,
      verified: true,
    }
  }

  public async finishTransaction(): Promise<void> {
    if (!isIOSApp) return
    const { subscriptions, ui } = getState()
    if (!ui.pendingIapTransaction) return
    const currentPlan = subscriptions.current?.plan
    try {
      _assert(currentPlan, "NCInAppPurchase: currentPlan doesn't exist")
      const productId = IAP_PRODUCT_KEY[currentPlan]
      _assert(productId, "NCInAppPurchase: productId doesn't exist")
      await NCInAppPurchase.finishTransaction({ productId })
      dispatch('setPendingIapTransaction', undefined)
    } catch (err) {
      sentryService.captureException(err)
    }
  }

  public enrichTransaction(transaction?: IAPTransactionRaw): IAPTransaction | undefined {
    if (!transaction) return
    const productKey = IAP_PRODUCT_BY_NAME[transaction.productId]
    if (!productKey) return
    const { userLocale } = getState()
    const currency = transaction.currency || userLocale.pricingCurrency
    const price = new Price(`${transaction.price}[${currency}]`)
    return { ...transaction, productKey, price }
  }

  public async listenForTransactions(): Promise<PluginListenerHandle | undefined> {
    if (!isIOSApp || !isAb274Test()) return
    this.listenForTransactionsHandle = await NCInAppPurchase.addListener(
      'transactionUpdate',
      this.handleTransactionUpdate.bind(this),
    )
    void NCInAppPurchase.listenForTransactions()
    return this.listenForTransactionsHandle
  }

  public handleTransactionUpdate(rawTransaction: IAPTransactionRaw): void {
    const { sessionId, ui, userLocale } = getState()
    const transaction = this.enrichTransaction(rawTransaction)
    if (!transaction || ui.pendingIapTransaction || sessionId) return

    const conditions = {
      isValid: transaction.expirationDate && transaction.expirationDate > Date.now(),
      isUSUser: userLocale.country === 'US',
      isYearly: transaction.productKey === ProductKey.YEAR,
      isNCFloPrice: transaction.price.cents === NC_FLO_YEARLY_PRICE_CENTS_USD,
    }

    const isEligible = _objectKeys(conditions).every(k => conditions[k])

    this.analyticsService.trackEvent(EVENT.IAP_TRANSACTION_UPDATE, {
      ..._omit(transaction, ['jwsRepresentation']),
      ...conditions,
      isEligible,
      price: transaction.price.toString(),
      jwsRepresentation: undefined,
    })

    if (!isEligible) return

    void this.listenForTransactionsHandle?.remove()
    dispatch('setPendingIapTransaction', transaction)
  }
}
