import {
  BreakpointAction,
  ChoiceFunction,
  EligibleAppleWatchBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { addPageToFlowAfterCurrent, removePageFromFlow } from '@src/app/util/quiz.util'
import { DefaultQuizSection } from '@src/typings/quiz.enum'
import { QuizPage } from '../quiz-pages.cnst'

const noTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return addPageToFlowAfterCurrent(
    flow,
    DefaultQuizSection.routine,
    QuizPage.eligibleAppleWatch,
    QuizPage.purchaseNewWatch,
  )
}

const yesTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  return removePageFromFlow(flow, DefaultQuizSection.routine, QuizPage.purchaseNewWatch)
}

export const eligibleAppleWatch: BreakpointAction<
  EligibleAppleWatchBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [EligibleAppleWatchBreakpointOption.yes]: yesTransformFunction,
  [EligibleAppleWatchBreakpointOption.no]: noTransformFunction,
}
